import { appConfig, assetDir, AppManager, PopupManager, logger, AnalyticsManager } from '@common';
import $ from 'jquery';

import MapUI from "../components/MapUI";

class HeaderNavi {
    constructor() { }

    show(confFl) {
        const header = AppManager.metaConfig.header;
        const lang = AppManager.currentLanguage;
        const label_lang = lang !== 'en' ? 'label_' + lang : 'label';
        let leftFl = "2F";
        let currentFl = "1F";
        let rightFl = "B1F";
        let leftFl_txt = header.label_2f[label_lang],
            currentFl_txt = header.label_1f[label_lang],
            rightFl_txt = header.label_b1f[label_lang];

        // B1F
        if (confFl == "b1f") {
            leftFl = "1F";
            currentFl = "B1F";
            rightFl = "2F";

            leftFl_txt = header.label_1f[label_lang];
            currentFl_txt = header.label_b1f[label_lang];
            rightFl_txt = header.label_2f[label_lang];

            // 2F
        } else if (confFl == "2f") {
            leftFl = "B1F";
            currentFl = "2F";
            rightFl = "1F";

            leftFl_txt = header.label_b1f[label_lang];
            currentFl_txt = header.label_2f[label_lang];
            rightFl_txt = header.label_1f[label_lang];
        }

        let tag;
        if (AppManager.isSmartPhone()) {
            tag = `
            <div class="header-wrap">
                <div class="header-top">
                    <div class="cart">
                        <p><img src="${assetDir}/images/buttons/cart.svg" alt="Cart"></p>
                    </div>
                    <div class="logo">
                        <h1><img src="${assetDir}/images/logo_w.svg" alt="SHISEIDO VIRTUAL FLAGSHIP STORE"></h1>
                    </div>
                    <div class="hamburger">
                        <p><img src="${assetDir}/images/buttons/menu.svg" alt="Menu"></p>
                    </div>
                </div>
                <div class="header-navi">
                    <nav>
                        <ul>
                            <li data-floor="${leftFl}"><button><img src="${assetDir}/images/buttons/arrow_l.svg"> <span>${leftFl}</span></button></li>
                            <li class="current-fl">${currentFl}</li>
                            <li data-floor="${rightFl}"><button><span>${rightFl}</span> <img src="${assetDir}/images/buttons/arrow_r.svg"></button></li>
                        </ul>
                    </nav>
                </div>
            </div>
            `;
        } else {
            tag = `
            <div class="header-wrap">
                <div class="header-top-pc">
                    <div class="logo">
                        <h1><img src="${assetDir}/images/logo_w.svg" alt="SHISEIDO VIRTUAL FLAGSHIP STORE - 資生堂"></h1>
                    </div>
                    <div class="header-navi-pc">
                        <div class="other-fl left" data-floor="${leftFl}"><button><img src="${assetDir}/images/buttons/arrow_l.svg"> <span>${leftFl}</span> <span class="hide">${leftFl_txt}</button></div>
                        <div class="current-fl">${currentFl}<div class="hide">${currentFl_txt}</div></div>
                        <div class="other-fl right" data-floor="${rightFl}"><button><span class="hide">${rightFl_txt}</span> <span>${rightFl}</span> <img src="${assetDir}/images/buttons/arrow_r.svg"></button></div>
                    </div>
                    <div class="nav-icons">
                        <div class="cart">
                            <p><img src="${assetDir}/images/buttons/cart.svg" alt="Cart"></p>
                        </div>
                        <div class="hamburger">
                            <p><img src="${assetDir}/images/buttons/menu.svg" alt="Menu"></p>
                        </div>
                    </div>
                </div>
            </div>
            `;
        }

        $('#header-container').html(tag.trim());

        $('.header-navi nav ul li, .header-navi-pc > div').off('click').on('click', event => {
            const floorTo = $(event.currentTarget).data('floor');

            AppManager.setFloorAnimation(floorTo);

            let panoTo;
            switch (floorTo) {
                case '2F':
                    panoTo = AppManager.getPanoramaById('17');
                    break;
                case '1F':
                    panoTo = AppManager.getPanoramaById('03');
                    break;
                case 'B1F':
                    panoTo = AppManager.getPanoramaById('26');
                    break;
            }
            if (panoTo) {
                AppManager.offDeviceOrientationControl();
                AppManager.gotoPanorama(panoTo, true, 1500);
            }
        });
        $('.header-top .hamburger, .header-top-pc .hamburger').off('click').on('click', event => {
			AnalyticsManager.sendClickNavi('Header', 'Menu Open');
            if (AppManager.getScreenType() == 'mb') {
                PopupManager.openMapContents(AppManager.currentFloor);
            } else {
                new MapUI(AppManager.currentFloor);
            }
        });
        $('.header-top .cart, .header-top-pc .cart').off('click').on('click', event => {
			AnalyticsManager.sendClickNavi('Header', 'Cart Open');
            PopupManager.openBrandSiteByIframe(appConfig.brandSiteUrl + "cart");
        });
        $('.logo h1 img').off('click').on('click', event => {
            AnalyticsManager.sendClickNavi('Header', 'Logo');
            location.reload();
        });
        if (AppManager.getScreenType() == 'ip') {
            $('.header-navi-pc').css({ height: '85px' });
            $('.header-navi-pc .hide').css({ opacity: 1 });
        }
        this.check_country();
    }

    hide() {
        $('#header-container').html("");;
    }

    async check_country() {

		//202208 現時点ではカートは必ず非表示
		$('.cart p').hide();
        if (this.hideCart === undefined) {
            await $.get("https://ipinfo.io", res => {
                logger.info("Get country code from IP address", res, res.country.toLowerCase());
                if(res.country.toLowerCase() != 'jp') {
                    this.hideCart = true;
                    logger.info("Hide the cart button");
                    $('.cart p').hide();
                }
            }, "jsonp");
        } else if (this.hideCart === true) {
            $('.cart p').hide();
        }
    }
}

export default new HeaderNavi();