import querystring from 'querystring';

const isProduction = process.env.NODE_ENV === 'production';
const isStaging = process.env.NODE_ENV === 'staging';
const isDevelopment = !isProduction && !isStaging;
/**
 * 許容パラメータ例
 * debug=1
 * paorama=03
 * autorotate=1.5
 */
const paramStr = location.search.substring(1);
const params = !isProduction && paramStr ? querystring.parse(paramStr) : {};

const isDebug = params.debug !== undefined ? Boolean(params.debug) : false;
const measureMode = params.measure !== undefined ? Boolean(parseInt(params.measure)) : false;

const appConfig = {
  // デバッグ・開発設定
  debug: {
    visibleHelper: isDebug,
    visibleHitArea: false,
    visiblePanoramaId: false,
    measureMode: measureMode,
    suppressIntro: false,
    outputPosition: isProduction ? false : true,
  },

  // ロード時に乱数を含める
  panoramaConfigNoCache: true,
  // 初期画面ID
  initialPanoramaId: params.panorama !== undefined ? params.panorama : '03',
  // CookieConsentダイアログの有効化
  cookieConsentEnabled: false,
  // WelcomeScreenの有効化
  welcomeScreenEnabled: isProduction ? true : params.panorama === undefined ? true : false,
  // 3秒後にAutoRotate＋Speed
  autoRotateEnabled: params.autorotate !== undefined,
  autoRotateSpeed: params.autorotate !== undefined ? parseFloat(params.autorotate) : 1.0,
  // Intro動画をパノラマ画像読み込み後に開始する。falseの場合は前画面の遷移時に開始する。
  playIntroOnEnter: true,

  // FOV最大最小値
  minFov: 45,
  maxFov: 100,

  // 商品IDクエリパラメータ用セパレータ
  pidSeparator: '|',

  // GFS URL
  gfsSiteUrl: 'https://www.shiseido.co.jp/ginza/',
  brandSiteUrl: isProduction ? 'https://brand.shiseido.co.jp/' : 'https://test.brand.shiseido.co.jp/',
  // 商品詳細DB
  productDataPath: isDevelopment ? '//gfs-vr.d1fx.com/db3' : '/db3',
  // GA
  analyticsCode: process.env.analyticsCode,
  appVersion: '1.0.200728',
  // INSIDE
  counselingLink: isDevelopment
    ? "/?insidechatlink=%7Ckey=5GK1D9BD0J%7C&insidechatlinktype=link&insideschedule=true"
    : isStaging
      ? "https://inside.chat/P3SJ6RB4U"
      : "https://inside.chat/PKVW5BY4G",
      
  utm_campaign: isProduction ? 'VGFS' : 'VGFS-TEST',
  siteDomain: isProduction ? 'vgfs.shiseido.co.jp' : 'gfs-vr.d1fx.com',
  ppSiteDomain: isProduction ? 'vgfs.shiseido.co.jp' : 'gfs-demo.d1fx.com',
};
export default appConfig;