import "../../styles/partials/mapUI.scss";
import $ from 'jquery';
import { AppManager, PopupManager, SoundManager } from "@common";

import * as embeddedMapUI from '../../../src_modals/scripts/embeddedMapUI';

export default class MapUI {
	constructor(floorTo) {
		this.floorTo = floorTo;
		if ($('#mapUI').length > 0) {
			this.show();
		} else {
			this.add();
		}
	}

	add() {

		const allMapConf = PopupManager.getProductViewConfigByType('map_contents');
		if (!allMapConf.hasOwnProperty('mapUI')) return;

		AppManager.setLoading(true);

		const context = {
			language: AppManager.currentLanguage,
			popupManager: PopupManager,
			floorTo: this.floorTo,
			floorConf: allMapConf.mapUI
		};

		const container = $('body').append('<div id="mapUI"></div>');

		const elementModal = $('#mapUI', container).html(embeddedMapUI.tag).fadeIn("slow").css('display', 'flex');;
		embeddedMapUI.init(elementModal, context, false);

		const elementClose = $('.lang-area', elementModal).append('<a class="close-modal"><svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="96" height="96" fill="black"/><path d="M25.225 24L72 72M70.775 24L24 72" stroke="white"/></svg></a>');

		$('a.close-modal', elementClose).off('click').on('click', function () {
			closePanel(elementModal);
		});

		$('#mapUI').off('click').on('click', function (e) {
			if (e.target.id === "mapUI") {
				closePanel(elementModal);
			} else {
				const currentDisp = $('.container-wrapper.current-disp', '#mapUI')[0];
				const floor = currentDisp.dataset.floor !== undefined ? currentDisp.dataset.floor : "1f";
				$('.container-inner-pc', '#mapUI').css({ 'background-image': `url("assets/images/pc_floor-map/${floor}_map-bg.jpg")`, "background-size": "auto" });
			}
		});

		$(function () {
			$('.container-inner-pc', '#mapUI').css({ 'background-image': `url("assets/images/pc_floor-map/${context.floorTo}_map-bg.jpg")`, "background-size": "auto" });
			$('.container-inner-pc', elementModal).addClass('slide-in eff-open');
			AppManager.setLoading(false);
		});

		stopWallVideo();
	}

	show() {
		$('#mapUI').fadeIn("slow");
		$('.container-inner-pc', '#mapUI').addClass('slide-in');
		const currentDisp = $('.container-wrapper.current-disp', '#mapUI')[0];
		const floor = currentDisp.dataset.floor !== undefined ? currentDisp.dataset.floor : "1f";
		$('.container-inner-pc', '#mapUI').css({ 'background-image': `url("assets/images/pc_floor-map/${floor}_map-bg.jpg")`, "background-size": "auto" });

		stopWallVideo();
	}
}

const closePanel = (element) => {
	embeddedMapUI.resetAnimating($('#mapUI'));
	$('.container-inner-pc', element).addClass('slide-out eff-open').removeClass('slide-in eff-slide');

	setTimeout(() => {
		$('#mapUI').css({ "pointer-events": 'none' }).fadeOut("slow", () => {
			$('#mapUI').css({ "pointer-events": 'unset' });
		});
		$('.container-inner-pc', element).removeClass('slide-out');
		SoundManager.replayVoice();
		playWallVideo();
	}, 500);
};

const playWallVideo = () => {
	if (AppManager.viewer.panorama.userData.controller.videoWallHolder !== undefined) {
		AppManager.viewer.panorama.userData.controller.videoWallHolder.forEach(videoWall => { videoWall.video.play(); });
	}
};

const stopWallVideo = () => {
	if (AppManager.viewer.panorama.userData.controller.videoWallHolder !== undefined) {
		AppManager.viewer.panorama.userData.controller.videoWallHolder.forEach(videoWall => {
			videoWall.video.pause();
			if (!videoWall.muted) bgReplay = true;
		});
	}
};