import $ from 'jquery';

class Loading {
  constructor() {
  }

  add() { return this; }

  show() {
    $('#loading-container').fadeIn();
  }

  hide(fedeOutTime) {
    $('#loading-container').delay(300).fadeOut(fedeOutTime);
  }
}

export default new Loading();