import * as THREE from 'three';

const min_canvas_height = 600;
const min_canvas_width = 16;

function getFontFamily() { return 'font-en, font-jp'; }

export const makeTextSprite = (label, style = {}) => {

  if (style.fontSize === undefined) style.fontSize = 18;
  if (style.fontWeight === undefined) style.fontWeight = 'normal';
  if (style.borderThickness === undefined) style.borderThickness = 0;
  if (style.fontFamily === undefined) style.fontFamily = getFontFamily();
  if (style.fillStyle === undefined) style.fillStyle = "rgba(0, 0, 0, 0)";
  if (style.strokeStyle === undefined) style.strokeStyle = "rgba(0, 0, 0, 0)";
  if (style.backgroundColor === undefined) style.backgroundColor = "rgba(255, 20, 20, 0)";
  if (style.imageOffset === undefined) style.imageOffset = 0;
  if (style.padding === undefined) style.padding = 20;

  const {
    fontSize,
    fontWeight,
    fontFamily,
    backgroundColor,
    fixedWidth,
    fixedHeight,
    fillStyle,
    padding,
    image,
    imageSize,
    imageOffset,
    borderThickness,
    strokeStyle
  } = style;

  const canvas = document.createElement('canvas');
  canvas.width = min_canvas_width;
  canvas.height = min_canvas_height;

  const context = canvas.getContext('2d');
  context.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
  const imageArea = (image === undefined ? 0 : (imageSize === undefined ? fontSize * 1.8 : imageSize));
  const newLabel = label.split('<br>');
  const lineConut = newLabel.length;
  const lineHeight = (lineConut * fontSize);

  const padV = fontSize * 0.6;
  const padH = fontSize * 1.4;

  context.textAlign = 'center';

  let offset = 0;
  if (image) offset = imageOffset;

  // get size data (height depends only on font size)
  let textWidth_max = 0;
  if (lineConut > 1) {
    for (let n = 0; n < lineConut; n += 1) {
      let textWidth = context.measureText(newLabel[n]).width;
      if (textWidth_max < textWidth) textWidth_max = textWidth;
    }
  } else {
    textWidth_max = context.measureText(label).width;
  }

  const textWidth = fixedWidth ? fixedWidth : textWidth_max + imageArea;
  const textHeight = fixedHeight ? fixedHeight : lineHeight;

  // background color
  context.fillStyle = backgroundColor;

  context.fillRect(0, 0, canvas.width, canvas.height);



  context.beginPath();
  context.setLineDash([0, 20]);
  context.moveTo(canvas.width / 2, (canvas.height / 2) + 80); //Start line point
  context.lineTo(canvas.width / 2, (canvas.height / 2) + 300); //Finish line point
  context.lineWidth = 6;
  context.lineCap = 'round';

  context.strokeStyle = '#ffffff';
  context.stroke();


  // border color
  context.strokeStyle = strokeStyle;
  context.lineWidth = borderThickness;

  // text color
  context.fillStyle = fillStyle;

  const text_start = ((canvas.width + imageArea) / 2) - offset;

  if (lineConut > 1) {
    for (let n = 0; n < lineConut; n += 1) {
      context.fillText(newLabel[n], text_start, ((n + 1) * fontSize) + (padH / 2));
    }
  } else {
    context.fillText(label, text_start, fontSize + (padH / 2));
  }

  if (image) {
    context.drawImage(image, rs - offset, (rh - imageArea) / 2, imageArea, imageArea);

    context.beginPath();
    context.moveTo(rs, ((lineHeight - imageArea) / 2.3 + (padH / 2)));
    context.lineTo(rs + imageArea, ((lineHeight - imageArea) / 2.3 + (padH / 2)));

    context.moveTo(rs, imageArea);
    context.lineTo(rs + imageArea, imageArea);


    context.moveTo(rs, ((lineHeight - imageArea) / 2.3 + (padH / 2)));
    context.lineTo(rs, ((lineHeight - imageArea) / 2.3 + (padH / 2)) + imageArea);

    context.moveTo(rs + imageArea, ((lineHeight - imageArea) / 2.3 + (padH / 2)));
    context.lineTo(rs + imageArea, imageArea);

    context.strokeStyle = '#ff5500';
    context.stroke();
  }

  // canvas contents will be used for a texture
  const texture = new THREE.CanvasTexture(canvas);
  texture.minFilter = THREE.NearestFilter;
  texture.needsUpdate = true;
  // texture.generateMipmaps = false;

  const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
  const sprite = new THREE.Sprite(spriteMaterial);

  const ratio = sprite.material.map.image.width / sprite.material.map.image.height;
  sprite.scale.set(ratio, 1, 1);

  return sprite;
};