import * as THREE from "three";
import {
  AppManager,
  PopupManager,
  AnalyticsManager,
  ViewHistoryManager,
  appConfig,
} from "@common";

export default class BaseInteractiveSpot extends THREE.EventDispatcher {
  constructor(panorama) {
    super();
    this.panorama = panorama;
  }

  /*
  // #deprecated
  createHitArea({ position, scale, offset = { x: 0, y: 0 }, sizeAttenuation = true, name = '' }) {
    const sprite = new THREE.Sprite(new THREE.SpriteMaterial({
      color: '#69f',
      transparent: true,
      opacity: appConfig.debug.visibleHitArea ? 0.5 : 0,
      depthTest: false,
      sizeAttenuation: sizeAttenuation,
    }));
    sprite.position.copy(position);
    sprite.scale.copy(scale);
    sprite.scale.x += offset.x;
    sprite.scale.y += offset.y;
    sprite.name = 'hitarea--' + name;
    this.panorama.add(sprite);
    return sprite;
  }


  configureHover() {
    if (!this.hitArea) return;
    this.panorama.userData.controller.registerInteractiveSpot(this);
  }


  configureClick() {
    if (!this.hitArea) return;
    this.panorama.addEventListener('click', (event) => {
      if (event.intersects.length > 0) {
        const hit = event.intersects.find(ist => ist.object === this.hitArea);
        if (hit) {
          AppManager.playClickEffect(event.mouseEvent);
          this.dispatchEvent({ type: 'click', mouseEvent: event });
        }
      }
    });
  }
  */


  onHoverStart(info) {
  }


  onHoverEnd(info) {
  }


  onTouchStart(info, touchEvent) {
    AppManager.playClickEffect(touchEvent.touches[0]);
  }

  onChangeLanguage() {
  }


  actionHandler(action) {
    const isModal = action.type.split('--')[0] === 'modal';
    if (isModal) {
      PopupManager.openContents(action);
    } else if (action.type === 'link') {
      AppManager.setLoading(true);
      AppManager.gotoPanorama(action.id);
    } else if (action.type === 'counseling') {
      ViewHistoryManager.saveInStorage();
      window.location.href = appConfig.counselingLink;
    } else if (action.type === 'counseling_btn') {
      AnalyticsManager.sendBooking('click', this.menuLabel);
      window.open(action.link);
    } else if (action.type === 'external') {
      window.open(action.content);
    }
  }


  show() {
    this.onChangeLanguage();
  }


  hide() {
  }
}