import "../../styles/partials/ui.scss";
import $ from 'jquery';
import { AppManager, assetDir, appConfig } from '@common';
import * as PANOLENS from 'panolens';
import * as THREE from 'three';
import { makeTextSprite } from '../utils/lineSprite';
import BaseInteractiveSpot from './BaseInteractiveSpot';
import { GUI } from '~/includedAssets/js/dat.gui.module';
import AnalyticsManager from "../common/AnalyticsManager";

const buttonScale = 1;
const labelStyle = {
  fontSize: 48,
  fontFamily: 'font-en',
  trimCanvas: false,
};

export default class InfoLinkSpot extends BaseInteractiveSpot {
  constructor(panoCtl, panoCtlTo, linkConfig) {
    super(panoCtl.panorama);
    this.panoCtl = panoCtl;
    this.panoCtlTo = panoCtlTo;
    this.linkConfig = linkConfig;

    if (appConfig.debug.visibleHelper) document.querySelectorAll(".dg.main").forEach(e => e.remove());

    new THREE.TextureLoader().load(`${assetDir}/images/infospot/movepoint.png`, tex => {
      this.buttonMesh = this.createButton(tex);
      this.labelMesh = this.createLabel();
    });
    this.panorama.addEventListener('change-language', this.onChangeLanguage.bind(this));
  }


  createButton(baseTex) {
    baseTex.needsUpdate = true;
    baseTex.magFilter = THREE.LinearFilter;

    const isMobile = AppManager.isSmartPhone();
    const scale = (isMobile ? (this.linkConfig.buttonSPScale === undefined ? buttonScale : this.linkConfig.buttonSPScale) : (this.linkConfig.buttonScale === undefined ? buttonScale : this.linkConfig.buttonScale));
    const imageScale = 700 * scale;
    const info = new PANOLENS.Infospot(imageScale, baseTex, true, {
      tilesHorizontal: 8,
      tilesVertical: 8,
      numberOfTiles: 45,
      onHoverStart: this.onHoverStart,
      onHoverEnd: this.onHoverEnd,
      onTouchStart: this.onTouchStart,
      onElementClick: this.onElementClick,
    }, false);
    info.addEventListener('click', this.onLinkClick.bind(this));
    info.name = `linkto-${this.panoCtlTo.id}`;
    info.panoID = this.panoCtlTo.id;

    if (this.panoCtlTo.thumbnail && AppManager.getScreenType() === 'pc') {
      $('#element-container').append(`
      <div name="desc-container" id="desc-container${this.panoCtlTo.id}">
        <div class="desc-inner-wrapper">
          <img class="pano-image" src="${this.panoCtlTo.thumbnail}">
          <span class="pano-text-label">${this.label}</span>
        </div>
      </div>`.trim());
      info.delta = this.panoCtlTo.verticalDelta !== undefined ? this.panoCtlTo.verticalDelta : 0;
      if (window.innerHeight > window.innerWidth) {
        info.delta = info.delta * 1.5;
      }
      info.addHoverElement(document.getElementById(`desc-container${this.panoCtlTo.id}`), info.delta);
      $('#element-container').empty();
    }

    let n = 0;
    const framesPerSecond = 1000 / 30;
    const animating = () => {

      if (info.isHovering) {
        n = 45;
        info.resetAnimation();
      } else {
        n++;
        info.addAnimation();
      }

      if (n < 45) {
        setTimeout(function () { requestAnimationFrame(animating); }, framesPerSecond);
      } else {
        n = 0;
        setTimeout(function () { requestAnimationFrame(animating); }, 5000);
      }
    };
    requestAnimationFrame(animating); // call requestAnimationFrame and pass into it animation function


    if (appConfig.debug.visibleHelper) {
      info.positionOri = this.linkConfig.positionAt;

      const gui = new GUI();
      const folder = gui.addFolder('position');

      const params = {
        positionX: this.linkConfig.positionAt[0],
        positionY: this.linkConfig.positionAt[1],
        positionZ: this.linkConfig.positionAt[2],
        clear: function () { },
        print: function () { }
      };

      folder.add(params, 'positionX', -5000, 5000).step(0.001).name('position X').onChange(
        function (value) {
          info.position.x = value;
          params.positionX = value;
        }
      ).setValue(params.positionX);

      folder.add(params, 'positionY', -5000, 5000).step(0.001).name('position Y').onChange(
        function (value) {
          info.position.y = value;
          params.positionY = value;
        }
      ).setValue(params.positionY);

      folder.add(params, 'positionZ', -5000, 5000).step(0.001).name('position Z').onChange(
        function (value) {
          info.position.z = value;
          params.positionZ = value;
        }
      ).setValue(params.positionZ);

      gui.add(params, 'clear').name('Reset Value').onChange(function () {
        params.positionX = info.positionOri[0];
        params.positionY = info.positionOri[1];
        params.positionZ = info.positionOri[2];

        info.position.x = params.positionX;
        info.position.y = params.positionY;
        info.position.z = params.positionZ;

        gui.updateDisplay();
      });

      gui.add(params, 'print').name('Print Value').onChange(function () {
        console.log(`[ ${params.positionX.toFixed(2)}, ${params.positionY.toFixed(2)}, ${params.positionZ.toFixed(2)} ]`);
      });
      folder.open();
      gui.close();

      gui.domElement.querySelector(".close-bottom").innerText = this.label;

    } else {
      info.position.fromArray(this.linkConfig.positionAt);
    }

    this.panorama.add(info);
    return info;
  }


  createLabel() {
    if (!this.panoCtlTo.linkLabel) return null;
    const labelSprite = new makeTextSprite('', labelStyle);
    labelSprite.name = `linklabel-${this.panoCtlTo.id}`;
    labelSprite.material.sizeAttenuation = false;
    labelSprite.scale.multiplyScalar(0.0006);
    this.buttonMesh.addLine(labelSprite, {});
    return labelSprite;
  }


  onLinkClick(event) {
    const linkConfig = AppManager.getPanoramaById(this.linkConfig.id);
    AnalyticsManager.sendInfoSpot(linkConfig.config.analytics.title);
    AppManager.setLoading(true);
    AppManager.gotoPanorama(linkConfig);
  }

  onElementClick(info) {
    const linkConfig = AppManager.getPanoramaById(info.panoID);
    AnalyticsManager.sendInfoSpot(linkConfig.config.analytics.title);
    AppManager.setLoading(true);
    AppManager.gotoPanorama(linkConfig);
  }

  onChangeLanguage() {
    const ele = $(`.pano-text-label`, `#desc-container${this.panoCtlTo.id}`);
    if (ele.length > 0) {
      $(`.pano-text-label`, `#desc-container${this.panoCtlTo.id}`).html(this.label);
    }
  }


  onHoverStart(info) {
    info.material.color.set('#808080');
  }


  onHoverEnd(info) {
    info.material.color.set('#fff');
  }


  get label() {
    if (this.panoCtlTo['linkLabel_' + AppManager.currentLanguage]) {
      return this.panoCtlTo['linkLabel_' + AppManager.currentLanguage];
    }
    return this.panoCtlTo.linkLabel;
  }

}