// MB カテゴリ一覧＋商品カード用のスクリプトファイル
import 'ress';
import '../styles/categoryList.scss';
import '../styles/productInfo.scss';

import $ from 'jquery';
import { AppManager, logger, AnalyticsManager, appConfig } from "@common";
import { convertLanguageCode, getCurrencyString, getLinkToECLabel } from './modalUtils';
import Loading from '../../src/scripts/components/Loading';

import Scrollbar from 'smooth-scrollbar';

let xDown = null;
let yDown = null;

let animating = true;
let force = false;
let number = 0;

export function getForce() {

  const forceCheck = force;
  force = false;

  return forceCheck;
}
export function setForce() { force = false; }

export function init(modalContainer, context) {
  const confFile = context.hasOwnProperty("typeAdVideo") && context.typeAdVideo === true ? 'ad' : 'contentsMenu';
  const allConf = context.popupManager.getProductViewConfigByType('contents');
  const contentMenu = allConf[confFile].find(conf => parseInt(conf.id) === parseInt(context.contentId));
  const productsConf = allConf.productConditions;
  const alertTag = `
  <div class="product-popup-confirm" style="display: none;">
      <div class="alert-jancode"></div>
      <div class="popup-confirm-wrapper" onclick="">
          <div class="txt">
              <div class="confirm-txt"></div>
              <div class="confirm-show"><label for="product-check01"></label></div>
          </div>
          <div class="btn">
              <button class="popup-ok">${getLabel(AppManager.metaConfig.popup.btnOK)}</button>
              <button class="popup-close">${getLabel(AppManager.metaConfig.popup.btnCancel)}</button>
          </div>
      </div>
  </div>`;
  const confirmElem = modalContainer.append(alertTag);

  $('.product-popup-confirm .popup-close', confirmElem).off('click').on('click', function (event) {
    event.preventDefault();
    $('.product-popup-confirm').css('display', 'none');
    $('.modal a.close-modal').show();
  });

  if (context.screenType === "category") {
    initCategory();
  } else if (context.screenType === "productDetail" || context.screenType === "video-productDetail") {
    initProduct();
  }

  async function initProduct() {
    Loading.show();
    force = true;
    const modalElem = modalContainer.append('<div class="categry-list-bg 11"></div><div class="products-container"></div>');
    const contentMenu = allConf[confFile].find(conf => parseInt(conf.id) === parseInt(context.contentId));
    let bgSetting = "";
    if (contentMenu.hasOwnProperty("bgImage")) {
      bgSetting = {
        "background-image": `url('${contentMenu.bgImage}')`,
        "background-size": "cover"
      };
      $('.categry-list-bg').css(bgSetting);
    }

    const productsElement = $('.products-container', modalElem);
    const element = $('<div>', { class: 'products-list-wrapper', 'data-productConditionsId': context.categoryId }).appendTo(productsElement);
    setupProducts(element, context.categoryId);
    setTimeout(() => { Loading.hide(); }, 500);
  }

  async function initCategory() {
    const categoriesConf = allConf.category.find(conf => parseInt(conf.id) === parseInt(context.categoryId));
    const upperList = categoriesConf.upperCategory;
    if (!upperList) return;

    AnalyticsManager.sendCategoryLink(categoriesConf.analytics);

    const modalElem = modalContainer.append('<div class="categry-list-bg"></div><div id="category-list-wrapper"></div><div class="products-container"></div>');
    const contentMenu = allConf[confFile].find(conf => parseInt(conf.id) === parseInt(context.contentId));
    let bgSetting = "";
    if (contentMenu.hasOwnProperty("bgImage")) {
      bgSetting = {
        "background-image": `url('${contentMenu.bgImage}')`,
        "background-size": "cover"
      };
      $('.categry-list-bg').css(bgSetting);
    }

    const listElement = $('#category-list-wrapper', modalElem);
    const tag = `
    <div class="contents-title">
        <div class="close-button"><img src="assets/images/buttons/arrow_l.svg"></div>
    </div>
    <div class="category-list"></div>`;
    listElement.append(tag);

    setupCategory(listElement, categoriesConf, $('.products-container', modalElem));

    $('.category-list .category-list-wrapper').off('click').on('click', function (event) {
      event.preventDefault();
      const hasSubcategory = $(event.currentTarget).data('subcategory');
      const hasProduct = $(event.currentTarget).data('product');
      if (hasSubcategory) {
        $(event.currentTarget).closest('li').find('.sub-category-list-wrapper').show();
        $(event.currentTarget).closest('li').find('.sub-category-list-wrapper').animate({ left: '0px' });
      } else if (hasProduct) {
        const ref_t = $(event.currentTarget).data('ref_t');
        const cid = $(event.currentTarget).data('cid');
        prepareProducts(cid, ref_t);
      }
    });

    $('.close-button', listElement).off('click').on('click', function (event) {
      event.preventDefault();
      force = true;
      $.modal.close();
      if (context.screenType !== "video-productDetail") AnalyticsManager.sendMenuContents(contentMenu.analytics);
    });
  }

  function setupCategory(wrapperElem, categoriesConf, productsElement) {
    const upperList = categoriesConf.upperCategory;
    if (upperList && upperList.length > 0) {
      const listElement = $('<ul>').appendTo($('.category-list', wrapperElem));
      upperList.forEach((category, index) => {
        const category_name = getCategoryLabel(category, context.language);
        const hasSubcategory = hasProperty(category, 'lowerCategory');
        const hasProduct = hasProperty(category, 'productConditionsId');
        const ref = "P-QQQ-" + String(`${index}`).padStart(3, '0') + "-" + String(`${hasProduct ? category.productConditionsId : 'x'}`).padStart(7, 'x');
        const tag = `<li>
    <div class="category-list-wrapper" data-subcategory="${hasSubcategory}" data-ref_t="${ref}" data-product="${hasProduct}" data-cid="${hasProduct ? category.productConditionsId : ""}">    
        <div class="thumbnail">
            <img src="${category.thumbnailPath}" >
        </div>
        <div class="category-name">` + category_name.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '') + `</div>
        <div class="child-button">${hasSubcategory || hasProduct ? '<img src="assets/images/buttons/Vector_right.svg">' : ''}</div> 
    </div>
    <div class="sub-category-list-wrapper" style="left: 100%; display:none;"></a></div>
</li>`;
        const categoryElem = $(tag).appendTo(listElement);

        if (hasSubcategory) {
          const subtag = `<div class="contents-title">
        <div class="back-button"><img src="assets/images/buttons/arrow_l.svg"></div>
        <h2>${category_name}</h2>
    </div>
    <div class="category-list"></div>`;
          const subcategoryElem = $('.sub-category-list-wrapper', categoryElem).append(subtag);

          setupSubcategory(subcategoryElem, upperList, category.lowerCategory, index, productsElement);

          $('.back-button', subcategoryElem).off('click').on('click', function (event) {
            event.preventDefault();
            $(event.target).closest('.sub-category-list-wrapper').animate({ left: '100%' }, {
              complete: () => {
                $(event.target).closest('.sub-category-list-wrapper').hide();
              }
            });
            AnalyticsManager.sendCategoryLink(categoriesConf.analytics);
          });

        } else if (hasProduct) {
          const element = $('<div>', { class: 'products-list-wrapper', 'data-ref': ref, 'data-productConditionsId': category.productConditionsId }).appendTo(productsElement);
          element.append(`<a class="close-modal" data-type="category" data-category_id="${categoriesConf.id}"></a>`);
          element.css({ 'display': 'none', left: '100%' });

          $('a.close-modal', element).off('click').on('click', function (event) {
            event.preventDefault();
            const type = $(event.target).data('type');
            if (type === "category") AnalyticsManager.sendCategoryLink(categoriesConf.analytics);
          });
          setupDotElement(element, 1, upperList, categoryElem);
        }
      });
    }
  }

  function setupSubcategory(element, categoryConf, subcategoryConf, categoryIndex, productsElement) {
    const listElement = $('<ul>').appendTo($('.category-list', element));
    subcategoryConf.forEach((subcategory, index) => {
      const categoryName = getCategoryLabel(subcategory, context.language);
      const hasProduct = hasProperty(subcategory, 'productConditionsId');
      const isparent = subcategory.parentCategoryFlg ? true : false;
      const ref = (isparent ? "P" : "C") + "-" + String(`${categoryIndex}`).padStart(3, '0') + "-" + String(`${index}`).padStart(3, '0') + "-" + String(`${hasProduct ? subcategory.productConditionsId : 'x'}`).padStart(7, 'x');

      const tag = `<li data-type="${subcategory.parentCategoryFlg ? 'upper' : 'lower'}">
    <div class="category-list-wrapper" data-product="${hasProduct}" data-ref_t="${ref}" data-cid="${hasProduct ? subcategory.productConditionsId : ""}" data-isparent="${isparent}">
        <div class="category-name">` + categoryName.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '') + `</div>
        <div class="child-button">${hasProduct ? '<img src="assets/images/buttons/Vector_right.svg">' : ''}</div>
    </div>
</li>`;
      const subcategoryElem = $(tag).appendTo(listElement);
      if (hasProduct) {

        const element = $('<div>', { class: 'products-list-wrapper', 'data-ref': ref, 'data-productConditionsId': subcategory.productConditionsId }).appendTo(productsElement);
        element.append('<a class="close-modal" data-type="subcategory"></a>');
        element.css({ 'display': 'none', left: '100%' });
        setupDotElement(element, subcategory.parentCategoryFlg ? 1.5 : 2, subcategory.parentCategoryFlg ? categoryConf : subcategoryConf, subcategoryElem);
      }
    });
  }

  async function prepareProducts(productConditionsId, ref) {
    Loading.show();
    const element = $(`.products-container .products-list-wrapper[data-ref="${ref}"]`);
    setupProducts(element, productConditionsId);
    element.show();
    $('#category-list-wrapper').animate({ opacity: 0 }, {
      complete: () => {
        $('#category-list-wrapper').hide();
        $('#category-list-bg').remove();
        Loading.hide();
      }
    });
    element.css({ left: '0px' });
  }

  async function setupProducts(element, productConditionsId) {
    if ($('.products-list-container', element).length > 0) return;
    if (!productsConf) return;
    const arrProducts = productsConf.find(conf => parseInt(conf.id) === parseInt(productConditionsId));
    if (!arrProducts) return;
    const hasProducts = hasProperty(arrProducts, 'products');
    if (!hasProducts) return;

    const productInfoList = await context.popupManager.loadProductData(arrProducts['products']).catch(error => { logger.error(error); });
    if (!productInfoList) return;

    const listElement = $('<div>', { class: 'products-list-container' }).appendTo(element);
    const tag = `<div class="product-item" onclick="">
        <div class="simulator-icon" style="display:none;">シミュレータ画像あり</div>
        <div class="product-list">
            <div class="product-list-inner">
                <div class="product-information">
                    <div class="product-image list"></div>
                    <div class="product-detail" onclick="">
                      <div class="complementary-icon list" style="display:none;"></div>
                      <div class="alert list" style="display:none;"></div>
                      <div class="product-line-name list"></div>
                      <h1 class="product-name list"></h1>
                      <!--<div class="sales-name list"></div>-->
                      <div class="short-description list content"></div>
                      <div class="product-info-note list content"></div>
                      <div class="price list content"></div>
                    </div>
                    <div class="color-sample"><div class="color-sample-item"></div></div>
                    <div class="list variants-sizes"></div>
                </div>
            </div>
        </div>
        <div class="linkto-ec content list" style="bottom: 0;">
            <a data-jancode="" href="#"></a>
        </div>
    </div>`;

    arrProducts['products'].forEach((product, index) => {
      const info = productInfoList.find(info => info.jan_code === product);
      const thisElement = $(tag).appendTo(listElement);

      Scrollbar.init($('.product-information', thisElement)[0], { alwaysShowTracks: true });

      number++;
      setupProductInfo(info, thisElement);
    });

    $('.products-list-wrapper .product-item').off('touchstart').on('touchstart', touchstart);
    $('.products-list-wrapper .product-item').off('touchmove').on('touchmove', touchmove);

    $('a.close-modal', element).off('click').on('click', function (event) {
      event.preventDefault();
	  const type = $(event.target).data('type');
	  
	  if (type === "category") {
		const categoryId = $(event.target).data('category_id');
		const allConf = context.popupManager.getProductViewConfigByType('contents');
		const categoriesConf = allConf.category.find(conf => parseInt(conf.id) === parseInt(categoryId));
		if (categoriesConf.analytics) AnalyticsManager.sendCategoryLink(categoriesConf.analytics);
	  }
      $('#category-list-wrapper').show();
      $('#category-list-wrapper').animate({ opacity: 1 });
      $(event.target).parent().css({ left: '100%' });
      $(event.target).parent().hide();
    });
  }

  function setupDotElement(element, groupType = 3, groupList = [], listElement) {
    if (groupList && groupList.length > 0 && groupType > 0 && groupType < 3) {
      let eq_lower = 0;
      let eq = listElement.closest('li').index();
      let eq_all = groupList.length - 1;
      if (groupType === 1.5) {
        eq = listElement.parent('ul').closest('li').index();
      }

      if (groupType === 2) {
        eq_lower = listElement.closest('ul').find('li[data-type="lower"]:first').index();
      }
      const dotContainer = $('<div>', { class: 'dot-container', onclick: "" }).appendTo(element);
      $(`<div class="dot-title" onclick="">${getCategoryLabel(groupList[eq], context.language)}</div><a class="prev${(eq === 0 || eq === eq_lower ? ' disabled' : '')}"><img src="assets/images/buttons/arrow_l.svg"></a><a class="next${(eq === eq_all ? ' disabled' : '')}"><img src="assets/images/buttons/arrow_r.svg"></a>`).appendTo(dotContainer);
      const dotWrapper = $('<div>', { class: 'dot-wrapper', onclick: "" }).appendTo(dotContainer);
      groupList.forEach((item, i) => {
        const hasSubcategory = hasProperty(item, 'lowerCategory');
        const hasProduct = hasProperty(item, 'productConditionsId');
        if (((groupType === 1 || groupType === 1.5) && ((hasSubcategory && item.lowerCategory[0].parentCategoryFlg) || hasProduct)) || ((groupType === 2 && !item.parentCategoryFlg) && hasProduct)) {
          $(`<span class="dot${(i === eq ? ' active' : '')}" data-content='${getCategoryLabel(item)}'></span>`).appendTo(dotWrapper);
        }
      });

      $(dotContainer).find('a.prev').off('click').on('click', function (event) {
        event.preventDefault();
        let cid, ref_t, ref;
        if ($(dotWrapper).find('.active').index() > 0) {
          const thisElement = $(event.target).closest('.products-list-wrapper');
          ref = thisElement.data('ref');

          const parentElement = $(`[data-ref_t="${ref}"]`);
          if (groupType === 2) {
            let hasProduct = parentElement.closest('li').prev().find('.category-list-wrapper').data('product');
            if (hasProduct) {
              cid = parentElement.closest('li').prev().find('.category-list-wrapper').data('cid');
              ref_t = parentElement.closest('li').prev().find('.category-list-wrapper').data('ref_t');
            } else {
              cid = parentElement.closest('li').prev().prev().find('.category-list-wrapper').data('cid');
              ref_t = parentElement.closest('li').prev().prev().find('.category-list-wrapper').data('ref_t');
            }
          } else {
            if (groupType === 1.5) {
              const parentThisElement = parentElement.closest('.sub-category-list-wrapper').closest('li');
              const this_hasSubcategory = parentThisElement.find('.category-list-wrapper').data('subcategory');
              if (this_hasSubcategory) {
                parentThisElement.find('.sub-category-list-wrapper').hide();
                parentThisElement.find('.sub-category-list-wrapper').css({ left: '100%' });
              }
            }
            let parentNewElement = parentElement.closest('li').prev();
            if (groupType === 1.5) {
              parentNewElement = parentElement.closest('.sub-category-list-wrapper').closest('li').prev();
            }
            let hasSubcategory = parentNewElement.find('.category-list-wrapper').data('subcategory');
            let hasProduct = parentNewElement.find('.category-list-wrapper').data('product');
            if (!hasSubcategory && !hasProduct) {
              parentNewElement = parentNewElement.prev();
              hasSubcategory = parentNewElement.find('.category-list-wrapper').data('subcategory');
              hasProduct = parentNewElement.find('.category-list-wrapper').data('product');
            }
            if (hasSubcategory) {
              cid = parentNewElement.find('.sub-category-list-wrapper .category-list li[data-type="upper"]').find('.category-list-wrapper').data('cid');
              ref_t = parentNewElement.find('.sub-category-list-wrapper .category-list li[data-type="upper"]').find('.category-list-wrapper').data('ref_t');
              parentNewElement.find('.sub-category-list-wrapper').css({ left: '0px' });
              parentNewElement.find('.sub-category-list-wrapper').show();
            } else if (hasProduct) {
              cid = parentNewElement.find('.category-list-wrapper').data('cid');
              ref_t = parentNewElement.find('.category-list-wrapper').data('ref_t');
            }
          }
          if (cid && ref_t) {
            prepareProducts(cid, ref_t);
            thisElement.css({ 'display': 'none', left: '100%' });
          }
        }
      });

      $(dotContainer).find('a.next').off('click').on('click', function (event) {
        event.preventDefault();
        let cid, ref_t, ref;
        const index = $(dotWrapper).find('.active').index();
        if (index < ($(dotWrapper).find('span.dot').length - 1)) {
          const thisElement = $(event.target).closest('.products-list-wrapper');
          ref = thisElement.data('ref');

          const parentElement = $(`[data-ref_t="${ref}"]`);
          if (groupType === 2) {
            let hasProduct = parentElement.closest('li').next().find('.category-list-wrapper').data('product');
            if (hasProduct) {
              cid = parentElement.closest('li').next().find('.category-list-wrapper').data('cid');
              ref_t = parentElement.closest('li').next().find('.category-list-wrapper').data('ref_t');
            } else {
              cid = parentElement.closest('li').next().next().find('.category-list-wrapper').data('cid');
              ref_t = parentElement.closest('li').next().next().find('.category-list-wrapper').data('ref_t');
            }
          } else {
            if (groupType === 1.5) {
              const parentThisElement = parentElement.closest('.sub-category-list-wrapper').closest('li');
              const this_hasSubcategory = parentThisElement.find('.category-list-wrapper').data('subcategory');
              if (this_hasSubcategory) {
                parentThisElement.find('.sub-category-list-wrapper').hide();
                parentThisElement.find('.sub-category-list-wrapper').css({ left: '100%' });
              }
            }
            let parentNewElement = parentElement.closest('li').next();
            if (groupType === 1.5) {
              parentNewElement = parentElement.closest('.sub-category-list-wrapper').closest('li').next();
            }
            let hasSubcategory = parentNewElement.find('.category-list-wrapper').data('subcategory');
            let hasProduct = parentNewElement.find('.category-list-wrapper').data('product');
            if (!hasSubcategory && !hasProduct) {
              parentNewElement = parentNewElement.next();
              hasSubcategory = parentNewElement.find('.category-list-wrapper').data('subcategory');
              hasProduct = parentNewElement.find('.category-list-wrapper').data('product');
            }
            if (hasSubcategory) {
              cid = parentNewElement.find('.sub-category-list-wrapper .category-list li[data-type="upper"]').find('.category-list-wrapper').data('cid');
              ref_t = parentNewElement.find('.sub-category-list-wrapper .category-list li[data-type="upper"]').find('.category-list-wrapper').data('ref_t');
              parentNewElement.find('.sub-category-list-wrapper').css({ left: '0px' });
              parentNewElement.find('.sub-category-list-wrapper').show();
            } else if (hasProduct) {
              cid = parentNewElement.find('.category-list-wrapper').data('cid');
              ref_t = parentNewElement.find('.category-list-wrapper').data('ref_t');
            }
          }
          if (cid && ref_t) {
            prepareProducts(cid, ref_t);
            thisElement.css({ 'display': 'none', left: '100%' });
          }
        }
      });
    }
  }
  function setupProductInfo(info, element) {
    $('<img>').appendTo($('.product-image', element));

    let langInfo = info.language.find(entry => entry.language_code === convertLanguageCode(context.language));
    let jaLangInfo = info.language.find(entry => entry.language_code === convertLanguageCode("ja"));
    setProductInfo(info, langInfo, jaLangInfo, element);
    setupColorSample(info, jaLangInfo, element);
    setupVariantsSizes(info, jaLangInfo, element);
  }

  function isStockUnavailable(janCode) {
    return context.popupManager.getProductStock(janCode) === null;
  }

  function isOutOfStock(janCode) {
    return context.popupManager.getProductStock(janCode) === 0;
  }

  function setupDandG(drType) {
    const element = $('#dandg');
    if (drType === 1) {
      element.addClass('defend');
    } else if (drType === 2) {
      element.addClass('regenerate');
    } else {
      element.hide();
    }
  }

  function setupColorSample(info, langInfo, element) {
    if (info.variants_colors && info.variants_colors.length > 0) {
      let cnt = 0;
      info.variants_colors.forEach(value => {
        const variants_value_ja = (value.variants_value_ja !== undefined && value.variants_value_ja != "") ? value.variants_value_ja : "-";
        const variants_color_description_ja = (value.variants_color_description_ja !== undefined && value.variants_color_description_ja != "") ? value.variants_color_description_ja : "-";
        const tag = `<a href="#" data-pid="${value.jan_code}" data-sfccid="${info.ori_product_id}" data-jancode="${value.jan_code}" data-linename="${langInfo.line_name}" data-pname="${value.product_name_vgfs_ja}" data-pvariant="${variants_value_ja}" data-pdiscription="${variants_color_description_ja}"><img src=${value.variants_value} /></a>`;
        const button = $(tag).appendTo($('.color-sample-item', element));
        // 現在の規格を判定
        if (value.jan_code === info.jan_code) {
          button.addClass('current');
        }
        // out of stock
        if (isOutOfStock(value.jan_code)) {
          button.addClass('out-of-stock');
        }
        button.off('click').on('click', function (event) {
          event.preventDefault();
          $('.color-sample-item', element).find('.current').removeClass('current');
          const pid = $(this).data('pid');
          getProductInfo(pid, element, button);
          // out of stock
          if (isOutOfStock(pid)) {
            button.addClass('out-of-stock');
          }
        });
        cnt++;
      });
      if (cnt > 6) {
        $('.color-sample-item', element).addClass('break');
      } else {
        $('.color-sample-item', element).addClass('no-break');
      }
    } else {
      $('.color-sample-item', element).hide();
    }
  }

  function setupVariantsSizes(info, langInfo, element) {
    if (info.variants_sizes && info.variants_sizes.length > 0) {
      info.variants_sizes.forEach(value => {
        const variants_value_ja = (value.variants_value_ja !== undefined && value.variants_value_ja != "") ? value.variants_value_ja : "-";
        const variants_color_description_ja = (value.variants_color_description_ja !== undefined && value.variants_color_description_ja != "") ? value.variants_color_description_ja : "-";

        const label = value.variants_value[convertLanguageCode(context.language)];
        const id = `size-${value.jan_code}-${number}`;
        const tag = `
<div class="radio">
  <input id="${id}" name="radio-${number}" type="radio" value="${value.jan_code}" data-sfccid="${info.ori_product_id}" data-jancode="${value.jan_code}" data-linename="${langInfo.line_name}" data-pname="${value.product_name_vgfs_ja}" data-pvariant="${variants_value_ja}" data-pdiscription="${variants_color_description_ja}">
  <label for="${id}" class="radio-label">${label}</label>
</div>
`;
        const button = $(tag.trim()).appendTo($('.variants-sizes', element));
        // 現在の規格を判定
        if (value.jan_code === info.jan_code) {
          $('input', button).attr('checked', true);
        }
        // out of stock
        if (isOutOfStock(value.jan_code)) {
          $('input', button).addClass('out-of-stock');
        }
        $('input', button).off('change').on('change', function (event) {
          event.preventDefault();
          $('.variants-sizes', element).find('.current').removeClass('current');
          const pid = $(this).val();
          getProductInfo(pid, element, $(this));
          // out of stock
          if (isOutOfStock(pid)) {
            $(this).addClass('out-of-stock');
          }
        });
      });
    } else {
      $('.variants-sizes', element).hide();
    }
  }

  async function getProductInfo(pid, element, button) {
    const productData = await context.popupManager.loadProductData([pid.toString()]).catch(error => { logger.error(error); });
    if (!productData[0]) return null;

    const info = productData[0];
    let langInfo = info.language.find(entry => entry.language_code === convertLanguageCode(context.language));
    let jaLangInfo = info.language.find(entry => entry.language_code === convertLanguageCode('ja'));
    setProductInfo(info, langInfo, jaLangInfo, element);

    button.addClass('current');
  }

  const setProductInfo = (info, langInfo, jaLangInfo, element) => {
    const priceComma = new Intl.NumberFormat().format(info.price);
    let currency = getCurrencyString(context.language);
    let alert_message = "";
    const fontClass = `"font-en", "font-jp"`;
    const replaceLang = info.language.find(entry => entry.language_code === convertLanguageCode('ja'));
    if (!langInfo.product_name_vgfs || langInfo.product_name_vgfs == "") {
      langInfo.product_name_vgfs = replaceLang.product_name_vgfs;
      langInfo.complementary_txt_1 = replaceLang.complementary_txt_1;
      langInfo.complementary_txt_2 = replaceLang.complementary_txt_2;
      $('.product-name', element).css("font-family", fontClass);
      alert_message = getLabel(AppManager.metaConfig.guide.soldOnlyJP);
    }
    if (!langInfo.line_name || langInfo.line_name == "") {
      langInfo.line_name = replaceLang.line_name;
      $('.product-line-name', element).css("font-family", fontClass);
    }

    $('.product-image', element).find('img').attr('src', info.product_file);
    $('.linkto-ec', element).find('a').attr('data-jancode', info.jan_code);
    $('.linkto-ec', element).find('a').data('jancode', info.jan_code);
    $('.price', element).html(`<span class="price-num">${priceComma}</span>${currency}`);
    
    $('.linkto-ec a', element).html(getLinkToECLabel(context.language, (!isOutOfStock(info.jan_code) && !isStockUnavailable(info.jan_code))));
    
    $('.product-image', element).find('img').attr('data-sfccid', info.ori_product_id);
    $('.product-image', element).find('img').attr('data-jancode', info.jan_code);
    $('.product-image', element).find('img').attr('data-linename', jaLangInfo.line_name == "" ? "-" : jaLangInfo.line_name);
    $('.product-image', element).find('img').attr('data-pname', jaLangInfo.product_name_vgfs == "" ? "-" : jaLangInfo.product_name_vgfs);
    $('.product-image', element).find('img').attr('data-pvariant', jaLangInfo.variants_value == "" ? "-" : jaLangInfo.variants_value);
    $('.product-image', element).find('img').attr('data-pdiscription', jaLangInfo.variants_color_description == "" ? "-" : jaLangInfo.variants_color_description);
    
    $('.linkto-ec', element).find('a').attr('data-sfccid', info.ori_product_id);
    $('.linkto-ec', element).find('a').attr('data-jancode', info.jan_code);
    $('.linkto-ec', element).find('a').attr('data-linename', jaLangInfo.line_name == "" ? "-" : jaLangInfo.line_name);
    $('.linkto-ec', element).find('a').attr('data-pname', jaLangInfo.product_name_vgfs == "" ? "-" : jaLangInfo.product_name_vgfs);
    $('.linkto-ec', element).find('a').attr('data-pvariant', jaLangInfo.variants_value == "" ? "-" : jaLangInfo.variants_value);
    $('.linkto-ec', element).find('a').attr('data-pdiscription', jaLangInfo.variants_color_description == "" ? "-" : jaLangInfo.variants_color_description);
    
    $('.product-image,.product-line-name,.product-name,.short-description,.product-info-note,.price', element).off('click').on('click', event => { event.preventDefault(); });

    $('.simulator-icon', element).off('click');
    $('.simulator-icon', element).on('click', event => { });

    $('.linkto-ec', element).off('click');
    $('.linkto-ec', element).on('click', event => {
      event.preventDefault();
      const jancode = $(event.currentTarget).find('a').data('jancode');

      if (history.pushState) {
        let new_param = '';
        const url = window.location.href.split('?');
        if (url.length > 1 && url[1] != '') {
          const aParam = url[1].split("&");
          aParam.forEach((param, i) => {
            if (!param.startsWith("linkec_jancode")) {
              new_param += (new_param === '' ? '' : '&') + param;
            }
          });
        }
        new_param += (new_param === '' ? '' : '&') + 'linkec_jancode=' + jancode;
        var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + new_param;
        window.history.pushState({ path: newurl }, '', newurl);
      }

      $('.product-popup-confirm .popup-ok').off();
      if (AppManager.getCookie('no_alert') !== "true" && AppManager.currentLanguage !== "ja") {
        $('.modal a.close-modal').hide();
        $('.product-popup-confirm .alert-jancode').text(jancode);
        $('.product-popup-confirm .txt .confirm-txt').html(getLabel(AppManager.metaConfig.guide.supportOnlyJP));
        $('.product-popup-confirm').removeAttr('style');

        $('.product-popup-confirm .confirm-show label').html(`<input type="checkbox" name="confirm_show" id="product-check01"><div class="text">&ensp;` + getLabel(AppManager.metaConfig.guide.checkTag) + `</div>`);

        $('.product-popup-confirm .popup-ok').off('click').on('click', function (event) {
          event.preventDefault();
          $('.modal a.close-modal').show();
          const linkJanCode = $('.product-popup-confirm .alert-jancode').text();
          $('.product-popup-confirm').css('display', 'none');
          $('.product-popup-confirm .popup-ok').off();
          $('.product-popup-confirm .txt .confirm-txt').text('');
          $('.product-popup-confirm .alert-jancode').text('');
          if (document.querySelector('.product-popup-confirm #product-check01').checked) AppManager.setCookie('no_alert', true, 30);

          context.popupManager.openNewTab(appConfig.brandSiteUrl + jancode + ".html" + context.popupManager.getViewHistoryParams());
          //context.popupManager.openBrandSiteByIframe(appConfig.brandSiteUrl + jancode + ".html" + context.popupManager.getViewHistoryParams());
        });
      } else {
        context.popupManager.openNewTab(appConfig.brandSiteUrl + jancode + ".html" + context.popupManager.getViewHistoryParams());
        //context.popupManager.openBrandSiteByIframe(appConfig.brandSiteUrl + jancode + ".html" + context.popupManager.getViewHistoryParams());
      }
    });

    const simulator_label = { 'ja': 'シミュレーター機能あり', 'en': 'Simulation available', 'zh-Hans': '有预想机功能', 'zh-Hant': '有模擬功能' };
    if (!langInfo) return;

    $('.product-line-name', element).html(langInfo.line_name.replace(/\n/g, '<br>'));
    $('.product-name', element).html(langInfo.product_name_vgfs.replace(/\n/g, '<br>'));
    if (info.simulator_flg) {
      $('.simulator-icon', element).html('<img src="assets/images/buttons/cam_white.png">' + simulator_label[context.language]);
      $('.simulator-icon', element).show();
    }

    if (langInfo.complementary_txt_1 || langInfo.complementary_txt_2) {
      let content = "";
      if (langInfo.complementary_txt_1) content += `<p class="icon-1">${langInfo.complementary_txt_1}</p>`;
      if (langInfo.complementary_txt_2) content += `<p class="icon-2">${langInfo.complementary_txt_2}</p>`;
      if (content !== "") {
        $('.complementary-icon', element).html(content);
        $('.complementary-icon', element).show();
      }
    }
    if (alert_message !== "") {
      $('.alert', element).html(alert_message);
      $('.alert', element).show();
    }

    setupDandG(info.dr_type_id);
  };

  const touchstart = (evt) => {
    xDown = evt.touches[0].clientX;
    yDown = evt.touches[0].clientY;
  };

  const touchmove = (evt) => {

    if (!xDown || !yDown) {
      return;
    }
    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
      if (xDiff > 0) {
        /* left swipe */
        if ($(evt.currentTarget).next().length > 0 && animating) {
          animating = false;
          $(evt.currentTarget).prev().css({ left: '-100%' });
          $(evt.currentTarget).animate({ left: '-77%' });
          $(evt.currentTarget).next().animate({ left: '9%' }, {
            complete: () => {
              $(evt.currentTarget).next().next().css({ left: '95%' });
              animating = true;
            }
          });
        }
      } else {
        /* right swipe */
        if ($(evt.currentTarget).prev().length > 0 && animating) {
          animating = false;
          $(evt.currentTarget).next().css({ left: '100%' });
          $(evt.currentTarget).animate({ left: '95%' });
          $(evt.currentTarget).prev().animate({ left: '9%' }, { complete: () => animating = true });
          $(evt.currentTarget).prev().prev().css({ left: '-77%' });
        }
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
  };

  function getLabel(obj) {
    if (obj['label_' + AppManager.currentLanguage]) {
      return obj['label_' + AppManager.currentLanguage];
    }
    return obj.label;
  }

  return contentMenu.analytics;
}

export function addCategory(context) {
  const container = $('#modal-1 .category-area');

  const allConf = context.popupManager.getProductViewConfigByType('contents');
  const categoriesConf = allConf.category.find(conf => parseInt(conf.id) === parseInt(context.categoryId));
  const upperList = categoriesConf.upperCategory;
  if (!upperList) return;

  AnalyticsManager.sendCategoryLink(categoriesConf.analytics);

  const tag = `<div class="category-list-wrapper">
  <div class="contents-title">
      <div class="close-button"><img src="assets/images/buttons/arrow_l.svg"></div>
  </div>
  <div class="category-list"></div>
</div>`;
  const listElement = $(tag).appendTo(container);

  setupCategory(listElement, categoriesConf, context.language);

  $('.category-list .category-list-wrapper', listElement).off('click').on('click', function (event) {
    event.preventDefault();
    const hasSubcategory = $(event.currentTarget).data('subcategory');
    const hasProduct = $(event.currentTarget).data('product');
    if (hasSubcategory) {
      $(event.currentTarget).closest('li').find('.sub-category-list-wrapper').show();
      $(event.currentTarget).closest('li').find('.sub-category-list-wrapper').animate({ left: '0px' });
    } else if (hasProduct) {
      const ref_t = $(event.currentTarget).data('ref_t');
      const cid = $(event.currentTarget).data('cid');

      const videoElement = $('video', $('#modal-1'));
      videoElement[0].pause();
      context.popupManager.openCategoryPCMode(context.type, context.categoryId, context.contentId, cid, ref_t, () => {
          videoElement[0].play();
          if ($(event.currentTarget).data('isparent') === undefined) {
            AnalyticsManager.sendCategoryLink(categoriesConf.analytics);
          }
      });
    }
  });

  $('.category-list-wrapper .close-button', container).off('click').on('click', function (event) {
    event.preventDefault();
    container.hide();
    listElement.remove();
    const contentMenu = allConf[confFile].find(conf => parseInt(conf.id) === parseInt(context.contentId));
    AnalyticsManager.sendMenuContents(contentMenu.analytics);
  });

  container.show();

  function setupCategory(wrapperElem, categoriesConf, language) {
    const upperList = categoriesConf.upperCategory;
    if (upperList && upperList.length > 0) {
      const listElement = $('<ul>').appendTo($('.category-list', wrapperElem));
      upperList.forEach((category, index) => {
        const category_name = getCategoryLabel(category, language);
        const hasSubcategory = hasProperty(category, 'lowerCategory');
        const hasProduct = hasProperty(category, 'productConditionsId');
        const ref = "P-QQQ-" + String(`${index}`).padStart(3, '0') + "-" + String(`${hasProduct ? category.productConditionsId : 'x'}`).padStart(7, 'x');
        const tag = `<li>
    <div class="category-list-wrapper" data-subcategory="${hasSubcategory}" data-ref_t="${ref}" data-product="${hasProduct}" data-cid="${hasProduct ? category.productConditionsId : ""}">    
        <div class="thumbnail">
            <img src="${category.thumbnailPath}" >
        </div>
        <div class="category-name">` + category_name.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '') + `</div>
        <div class="child-button">${hasSubcategory || hasProduct ? '<img src="assets/images/buttons/Vector_right.svg">' : ''}</div> 
    </div>
    <div class="sub-category-list-wrapper" style="left: 100%; display:none;"></a></div>
</li>`;
        const categoryElem = $(tag).appendTo(listElement);


        if (hasSubcategory) {
          const subtag = `<div class="contents-title">
        <div class="back-button"><img src="assets/images/buttons/arrow_l.svg"></div>
        <h2>${category_name}</h2>
    </div>
    <div class="category-list"></div>`;
          const subcategoryElem = $('.sub-category-list-wrapper', categoryElem).append(subtag);

          setupSubcategory(subcategoryElem, upperList, category.lowerCategory, index, language);

          $('.back-button', subcategoryElem).off('click').on('click', function (event) {
            event.preventDefault();
            $(event.target).closest('.sub-category-list-wrapper').animate({ left: '100%' }, {
              complete: () => {
                $(event.target).closest('.sub-category-list-wrapper').hide();
              }
            });
            AnalyticsManager.sendCategoryLink(categoriesConf.analytics);
          });

        }
      });
    }
  }

  function setupSubcategory(element, categoryConf, subcategoryConf, categoryIndex, language) {
    const listElement = $('<ul>').appendTo($('.category-list', element));
    subcategoryConf.forEach((subcategory, index) => {
      const categoryName = getCategoryLabel(subcategory, language);
      const hasProduct = hasProperty(subcategory, 'productConditionsId');
      const isparent = subcategory.parentCategoryFlg ? true : false;
      const ref = (isparent ? "P" : "C") + "-" + String(`${categoryIndex}`).padStart(3, '0') + "-" + String(`${index}`).padStart(3, '0') + "-" + String(`${hasProduct ? subcategory.productConditionsId : 'x'}`).padStart(7, 'x');

      const tag = `<li data-type="${subcategory.parentCategoryFlg ? 'upper' : 'lower'}">
    <div class="category-list-wrapper" data-product="${hasProduct}" data-ref_t="${ref}" data-cid="${hasProduct ? subcategory.productConditionsId : ""}" data-isparent="${isparent}">
        <div class="category-name">` + categoryName.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '') + `</div>
        <div class="child-button">${hasProduct ? '<img src="assets/images/buttons/Vector_right.svg">' : ''}</div>
    </div>
</li>`;
      $(tag).appendTo(listElement);
    });
  }
}

function getCategoryLabel(obj, language) {
  if (obj[`categoryName_${language}`]) {
    return obj[`categoryName_${language}`];
  }
  return obj['categoryName'];
}

function hasProperty(obj, name) {
  return obj.hasOwnProperty(name) && (typeof obj[name] === "object" ? obj[name].length > 0 : true);
}