import ua from 'universal-analytics';
import appConfig from '../config/appConfig';
import logger from '../utils/logger';

const logTag = '[AnalyticsManager]';

class AnalyticsManager {

  sendWelcome() {
    logger.info(`${logTag} sendWelcome`, { "trackPageview": "welcome", "trackTitle": "Welcome Page", "event": "loadready" });
    this.sendAnalytics({ "code": "welcome", "title": "Welcome Page", "event": "loadready" });
  }
  
  sendWelcomePC() {
    logger.info(`${logTag} sendWelcome PC`, { "trackPageview": "welcome_pc", "trackTitle": "Welcome Page PC", "event": "loadready" });
    this.sendAnalytics({ "code": "welcome_pc", "title": "Welcome Page PC", "event": "loadready" });
  }

  sendLanguage(language, contents, eventVal) {
    const eaGtm = "Language";
    const ecGtm = contents ? contents : 'Main UI';
    const elGtm = language;
    const event = eventVal ? eventVal : 'event-gtm3';
    logger.info(`${logTag} sendLanguage`, {'event': event,'ec-gtm': ecGtm,'ea-gtm': eaGtm,'el-gtm': language});
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': event,
      'ec-gtm': ecGtm,
      'ea-gtm': eaGtm,
      'el-gtm': elGtm
    });
  }

  sendAd(analytics) {
	logger.info(`${logTag} sendAd`, { "trackPageview": analytics.code, "trackTitle": analytics.title, "event": analytics.event });
    this.sendAnalytics(analytics);
  }

  sendPanorama(analytics) {
	logger.info(`${logTag} sendPanorama`, { "trackPageview": analytics.code, "trackTitle": analytics.title, "event": analytics.event });
    this.sendAnalytics(analytics);
  }


  sendVideo(content, action) {
    logger.info(`${logTag} sendVideo`, {'event': "event-gtm2",'ec-gtm': "Video",'ea-gtm': action,'el-gtm': content});
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': "event-gtm2",
      'ec-gtm': "Video",
      'ea-gtm': action,
      'el-gtm': content
    });
  }


  sendProduct(productId, action) {
    // logger.info(`${logTag} sendProduct`, productId, action);
    // visitor.event({
    //   ec: 'Product',
    //   ea: action,
    //   el: productId,
    //   dh: hostname,
    // }).send();
  }


  sendBooking(eventAction, eventLabel) { }

  sendTutorial(step) {
    logger.info(`${logTag} sendTutorial`, { "trackPageview": `tutorial${step}`, "trackTitle": `Tutorial ${step}`, "event": "loadready" });
    this.sendAnalytics({ "code": `tutorial${step}`, "title": `Tutorial ${step}`, "event": "loadready" });
  }

  sendMenuContents(analytics) {
    logger.info(`${logTag} sendMenuContents`, { "trackPageview": analytics.code, "trackTitle": analytics.title, "event": analytics.event });
    this.sendAnalytics(analytics);
  }

  sendOpeningContents(eaGtm, elGtm) {
    const ecGtm = 'Contents';
    const event = 'event-gtm1';
    logger.info(`${logTag} sendOpeningContents`, { 'event': event, 'ec-gtm': ecGtm, 'ea-gtm': eaGtm, 'el-gtm': elGtm });
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': event,
      'ec-gtm': ecGtm,
      'ea-gtm': eaGtm,
      'el-gtm': elGtm,
    });
  }

  sendCategoryLink(analytics) {
    logger.info(`${logTag} sendCategoryLink`, { "trackPageview": analytics.code, "trackTitle": analytics.title, "event": analytics.event });
    this.sendAnalytics(analytics);
  }

  sendAnalytics(analytics) {
    if (analytics) {
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'trackPageview': analytics.code,
        'trackTitle': analytics.title,
        'event': analytics.event
      });
    }
  }

  sendClickAdEvent(eaGtm, elGtm, ecGtm) {
    const event = 'event-gtm1';
    logger.info(`${logTag} sendClickAdEvent`, { 'event': event, 'ec-gtm': ecGtm, 'ea-gtm': eaGtm, 'el-gtm': elGtm });
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': event,
      'ec-gtm': ecGtm,
      'ea-gtm': eaGtm,
      'el-gtm': elGtm
    });
  }

  sendClickNavi(eaGtm, elGtm) {
    const ecGtm = 'Main UI';
    const event = 'event-gtm1';
    logger.info(`${logTag} sendClickNavi`, { 'event': event, 'ec-gtm': ecGtm, 'ea-gtm': eaGtm, 'el-gtm': elGtm });
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': event,
      'ec-gtm': ecGtm,
      'ea-gtm': eaGtm,
      'el-gtm': elGtm
    });
  }

  sendController(control_name, status, contents) {
    const eaGtm = control_name;
    const ecGtm = contents ? contents : 'Main UI';
    const event = 'event-gtm1';
    logger.info(`${logTag} sendController`, { 'event': event, 'ec-gtm': ecGtm, 'ea-gtm': eaGtm, 'el-gtm': status ? 'ON' : 'OFF' });
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': event,
      'ec-gtm': ecGtm,
      'ea-gtm': eaGtm,
      'el-gtm': status ? 'ON' : 'OFF'
    });
  }

  sendFloor(floorTo) {
    logger.info(`${logTag} sendFloor`, { 'event': 'event-gtm1', 'ec-gtm': 'Main UI', 'ea-gtm': 'Floor Movement', 'el-gtm': floorTo });
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'event-gtm1',
      'ec-gtm': 'Main UI',
      'ea-gtm': 'Floor Movement',
      'el-gtm': floorTo
    });
  }

  sendLinkMenu(elGtm, ecGtm) {
    const eaGtm = 'THUMBNAIL Menu';
    const event = 'event-gtm1';
    logger.info(`${logTag} sendLinkMenu`, { 'event': event, 'ec-gtm': ecGtm, 'ea-gtm': eaGtm, 'el-gtm': elGtm });
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': event,
      'ec-gtm': ecGtm,
      'ea-gtm': eaGtm,
      'el-gtm': elGtm
    });
  }
  
  sendInfoSpot(elGtm) {
    const eaGtm = 'Info Spot';
    const ecGtm = 'Menu';
    const event = 'event-gtm1';
    logger.info(`${logTag} sendInfoSpot`, { 'event': event, 'ec-gtm': ecGtm, 'ea-gtm': eaGtm, 'el-gtm': elGtm });
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': event,
      'ec-gtm': ecGtm,
      'ea-gtm': eaGtm,
      'el-gtm': elGtm
    });
  }
}

export default new AnalyticsManager();