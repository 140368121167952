import * as log from 'loglevel';

const logger = log.getLogger('gfs');
if (process.env.NODE_ENV !== 'production') {
  logger.enableAll();
  //logger.disableAll();
} else {
  logger.setLevel(log.levels.ERROR);
}

export default logger;