import "../../styles/partials/qrcode.scss";
import { AppManager, assetDir } from '@common';
import $ from 'jquery';

class QrCodeScreen {
    constructor() {}

    add() {
        const tag = `
    <div id="qrCode">
      <div class="container">
        <div class="qr">
          <div class="qr-logo">
            <img src="${assetDir}/images/top/logo_pc.svg">
          </div>
          <div class="qr-text"></div>
          <img class="qr-image" src="${assetDir}/images/top/qrcode.png">
        </div>
      </div>
    </div>`;
        const qrElement = $('body').append(tag.trim());

        const lang = AppManager.currentLanguage;
        const font_style = `font-${lang}`;
        const guideLabel = `<p>` + this.label + `</p>`;
        const guideText = $('.qr-text', qrElement).html(guideLabel);
        $('p', guideText).css({ 'font-family': font_style });
        return this;
    }

    get label() {
        const pcGuide = AppManager.metaConfig.pcGuide;
        if (pcGuide['label_' + AppManager.currentLanguage]) {
            return pcGuide['label_' + AppManager.currentLanguage];
        }
        return pcGuide.label;
    }
}

export default new QrCodeScreen();