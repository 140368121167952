import "../../styles/partials/tutorial.scss";
import { assetDir, AppManager, appConfig, AnalyticsManager } from '@common';
import $ from 'jquery';

class WelcomeScreen {
  constructor() {
  }

  add() {
    let type = '';
    switch (AppManager.getScreenType()) {
      case 'ip':
        type = '_ip';
        break;
      case 'pc':
        type = '_pc';
        break;
    }
    const welcome_class = `welcome${type}`;
    // font-face適用のためダミーテキストを含める
    const tag = `
<div id="welcome" class="${welcome_class}">
  <div class="welcome-inner">
    <div class="welcome-inner-section-1">
      <img class="title" src="${assetDir}/images/top/logo_pc.svg" alt="SHISEIDO VIRTUAL GLOBAL FLAGSHIP STORE">
    </div>
    <div class="welcome-inner-section-2">
      <div class="welcome-text"></div>
      <div class="welcome-button">
        <div class="btn-start disabled sound-on" data-val="1"><div class="btn-bg"></div><div class="btn-icon"><img src="${assetDir}/images/top/on.svg"></div><div class="text">ON</div></div>
        <div class="btn-start disabled sound-off" data-val="0"><div class="btn-bg"></div><div class="btn-icon"><img src="${assetDir}/images/top/off.svg"></div><div class="text">OFF</div></div>
      </div>
    </div>
  </div>
</div>
      `;
    const welcomeElement = $('body').append(tag.trim());
    const welcome_text = '<p>' + this.label + '</p>';
    
    $('.welcome-text', welcomeElement).html(welcome_text);
    $('.sound-on div.text', welcomeElement).html(this.labelSoundOn);
    $('.sound-off div.text', welcomeElement).html(this.labelSoundOff);

    return this;
  }

  waitForTap() {
    const deferred = new $.Deferred();

    $('#welcome .welcome-inner .welcome-inner-section-1 img').animate({ 'opacity': '1' }, {
      complete: () => {
        if (AppManager.getScreenType() === 'ip' && window.innerWidth <= "1024") {
          $('#welcome .welcome-inner .welcome-inner-section-1').animate({ 'top': '42%' }, { duration: 1800 });
        } else {
          $('#welcome .welcome-inner .welcome-inner-section-1').animate({ 'top': '35%' }, { duration: 1800 });
        }
        $('#welcome .welcome-inner .welcome-inner-section-2').show();
        setTimeout(() => {
          $('#welcome .welcome-inner .welcome-inner-section-2').animate({ 'opacity': '1' }, {
            complete: () => {
              $('#welcome .welcome-inner .welcome-inner-section-2 .welcome-button .btn-start').removeClass('disabled');
            }, duration: 1800
          });
        }, 900);
      }, duration: 2700
    });

    $('#welcome .welcome-button .btn-start').off('click').on('click', (e) => {
      $("#loading-container").addClass("bl-opacity");
      $('#welcome .welcome-button .btn-start').addClass('disabled');
      deferred.resolve(true);
      e.preventDefault();
      const isSound = $(e.currentTarget).data('val');
      AnalyticsManager.sendController('SOUND', isSound, 'Welcome Page');
      AnalyticsManager.sendLanguage(this.labelLang, 'Welcome Page');
      if (isSound === 0) AppManager.switchVolume();
      $('#welcome div').animate({ opacity: 0 }, {
        complete: () => {
          $('#welcome').remove();
        }, duration: 2700
      });

      if (window.DeviceOrientationEvent !== undefined && typeof window.DeviceOrientationEvent.requestPermission === 'function') {
        window.DeviceOrientationEvent.requestPermission()
        .then(permissionState => {
            if (permissionState === 'granted') {
                AppManager.setHasGyroFuncton(true);
            } else {
                AppManager.setHasGyroFuncton(false);
                $('.gyro-toggle').addClass('disabled').off().remove();
            }
        });
      }
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) this.setHasGyroFuncton(true);
    });

    return deferred.promise();
  }

  get label() {
      const welcomeLabel = AppManager.metaConfig.welcome.guide;
      if (welcomeLabel['label_' + AppManager.currentLanguage]) {
          return welcomeLabel['label_' + AppManager.currentLanguage];
      }
      return welcomeLabel.label;
  }

  get labelSoundOn() {
        const welcomeLabel = AppManager.metaConfig.welcome.soundOn;
        if (welcomeLabel['label_' + AppManager.currentLanguage]) {
            return welcomeLabel['label_' + AppManager.currentLanguage];
        }
        return welcomeLabel.label;
    }

  get labelSoundOff() {
      const welcomeLabel = AppManager.metaConfig.welcome.soundOff;
      if (welcomeLabel['label_' + AppManager.currentLanguage]) {
          return welcomeLabel['label_' + AppManager.currentLanguage];
      }
      return welcomeLabel.label;
  }
  
  get labelLang() {
    const language = AppManager.metaConfig.language.langBtns;
    if (language['label_' + AppManager.currentLanguage]) {
        return language['label_' + AppManager.currentLanguage];
    }
    return language.label;
  }
}

export default new WelcomeScreen();