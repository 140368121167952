import '../styles/embeddedMapUI.scss';

import $ from 'jquery';
import { AppManager, PopupManager, LanguageSelect } from '@common';

let animating = false;

export function resetAnimating(modalElem = null) {
    if (modalElem) {
        const langElement = $('.lang-wrapper', modalElem);
        const langListElement = $('.lang-list', langElement);
        const arrowElement = $('.arrow', langElement);

        if (arrowElement.hasClass('open') && animating) {
            arrowElement.removeClass('open');
            langListElement.animate({ height: 'toggle' }, { complete: () => { langListElement.css('display', 'none'); } });
        }
    }
    animating = false;
}

export const tag = () => {
    const languageLabel = stepLanguageLabel();
    const langBtns = AppManager.metaConfig.language.langBtns;
    const html = `<div class="container-inner ${AppManager.isSmartPhone() ? '' : 'container-inner-pc'}">
        <div class="lang-area"> 
            <div class="lang-wrapper">
                <button class="lang-btn"><span>${languageLabel}</span><div class="arrow"></div></button>
                <div class="lang-list">
                <a href="#" data-lang="ja">${langBtns["label_ja"]}</a>
                <a href="#" data-lang="en">${langBtns["label"]}</a>
                <a href="#" data-lang="zh-Hans">${langBtns["label_zh-Hans"]}</a>
                <a href="#" data-lang="zh-Hant">${langBtns["label_zh-Hant"]}</a>
                </div>
            </div>
        </div>
        <div class="container-item"></div>
    </div>`.trim();
    return html;
};

export function init(modalElem, context, isModal = true) {

    const langElement = $('.lang-wrapper', modalElem);
    const langListElement = $('.lang-list', langElement);
    const arrowElement = $('.arrow', langElement);

    langListElement.find(`[data-lang=${AppManager.currentLanguage}]`).addClass('active');

    const menu_content_tag = `
    <div class="container-wrapper">
        <div class="map-area"> 
            <div class="map-wrapper">
                <div class="prev"></div>
                <div class="current">
                    <div class="point"></div>
                </div>
                <div class="next"></div>
            </div>
            <div class="floor-area"></div>
        </div>
        <div class="menu-area">
            <div class="content-menu"></div>
        </div>
    </div>`;

    $('<div class="menu-area-bg"></div>').appendTo($('.container-item', modalElem));

    context.floorConf.forEach(floor => {
        const menuContainer = $(menu_content_tag).appendTo($('.container-item', modalElem));
        menuContainer.attr('data-floor', floor.floor);

        if (floor.pager.left) {
            $('.map-wrapper .prev', menuContainer).attr('data-direction', 'prev').attr('data-floor', floor.pager.left.floor);
            $(`<div style="order: 2" data-direction="prev" data-floor='${floor.pager.left['floor']}' data-label='${floor.pager.left['label']}'  
            data-label_ja='${floor.pager.left['label_ja']}' 
            data-label_zh-Hant='${floor.pager.left['label_zh-Hant']}' 
            data-label_zh-Hans='${floor.pager.left['label_zh-Hans']}'>${get_label(floor.pager.left)}</div><img style="order: 1" src='assets/images/buttons/Vector_left.svg'>`).appendTo($('.map-wrapper .prev', menuContainer));
        }
        if (floor.pager.right) {
            $('.map-wrapper .next', menuContainer).attr('data-direction', 'next').attr('data-floor', floor.pager.right.floor);
            $(`<div data-direction="next" data-floor='${floor.pager.right['floor']}' data-label='${floor.pager.right['label']}' 
            data-label_ja='${floor.pager.right['label_ja']}' 
            data-label_zh-Hant='${floor.pager.right['label_zh-Hant']}' 
            data-label_zh-Hans='${floor.pager.right['label_zh-Hans']}'>${get_label(floor.pager.right)}</div><img src='assets/images/buttons/Vector_right.svg'>`).appendTo($('.map-wrapper .next', menuContainer));
        }
        if (floor.floor === context.floorTo) {
            menuContainer.addClass('current-disp');
        }

        const elmImg = $('<img>').appendTo($('.map-wrapper .point', menuContainer));
        elmImg.attr('src', floor.image);
        if (floor.link) {
            floor.link.forEach(point => {
                const elmPoint = $(`<a data-id='${point.id}'></a>`).appendTo($('.map-wrapper .point', menuContainer));
                elmPoint.addClass('map-point');
                elmPoint.css({ top: `${point.x}%`, left: `${point.y}%` });
                const elmPointTxT = $(`<span>${point.no}</span>`).appendTo($('.map-wrapper .point', menuContainer));
                elmPointTxT.addClass('map-point');
                elmPointTxT.css({ top: `${point.x}%`, left: `${point.y}%` });

            });
            $('a.map-point', menuContainer).on('click', function (event) {
                event.preventDefault();
                const id = $(event.currentTarget).data('id');
                if (!id) return;
                AppManager.gotoPanorama(id.toString());
                if (isModal) {
                    $.modal.close();
                } else {
                    $('a.close-modal', modalElem).trigger('click');
                }
            });
        }

        const menuListElement = $('<ul>').appendTo($('.content-menu', menuContainer));
        const elmFloor = $(`<img>`).appendTo($('.map-area .floor-area', menuContainer));
        elmFloor.attr('src', `assets/images/map/${floor.floor}_txt.svg`);

        if (!isModal) {
            $('.map-area', menuContainer).addClass(`floor-${floor.floor}`);
        }

        floor.contentsMenu.forEach(menu => {
            const tag = `<li data-id="${menu.id}">
            <div class="point-id">${menu.no}</div>
            <div class="point-name font-ext" data-label='${menu.label}' 
            data-label_ja='${menu['label_ja']}' 
            data-label_zh-Hant='${menu['label_zh-Hant']}' 
            data-label_zh-Hans='${menu['label_zh-Hans']}'>${get_label(menu)}</div>
            <div class="child-button"><img src="assets/images/buttons/Vector_right_map.svg"></div>
        </li>`;
            const menuElem = $(tag).appendTo(menuListElement);

            menuElem.on('click', function (event) {
                event.preventDefault();
                const id = $(event.currentTarget).data('id');
                if (!id) return;
                AppManager.gotoPanorama(id.toString(), false, undefined, undefined, () => {
                    AppManager.setCurrentFloor(AppManager.viewer.panorama.userData.controller.config.floor);
                    if (!AppManager.viewer.panorama.userData.controller.config.moreView) return;
                    AppManager.viewer.panorama.userData.controller.config.moreView[0].action.panoId = id;
                    PopupManager.openContents(AppManager.viewer.panorama.userData.controller.config.moreView[0].action, null);
                });
                if (isModal) {
                    $.modal.close();
                } else {
                    $('a.close-modal', modalElem).trigger('click');
                }
            });
        });

        $('.map-wrapper .prev,.map-wrapper .next', menuContainer).on('click', function (event) {
            event.preventDefault();
            const direction = $(this).data('direction');
            const floorTo = $(event.currentTarget).data('floor');
            $('.container-wrapper').removeClass('from-disp direction-next direction-prev');
            menuContainer.closest('.container-item').find('.current-disp').addClass('from-disp direction-' + direction).css({ 'pointer-events': 'none' });
            $('.container-inner').removeClass('eff-open').addClass('eff-slide');
            $('.container-wrapper').removeClass('current-disp');
            menuContainer.closest('.container-item').find(`.container-wrapper[data-floor="${floorTo}"]`).addClass('current-disp direction-' + direction).removeAttr('style');
        });
    });

    $('.lang-btn', langElement).on('click', function (event) {
        event.preventDefault();
        if (animating) return false;
        animating = true;
        arrowElement.addClass('open');
        langListElement.animate({ height: 'toggle' }, { complete: () => { langListElement.css('display', 'block'); } });
    });

    $('.lang-list a', langElement).on('click', function (event) {
        event.preventDefault();
        if (!animating) return false;
        langListElement.find(`[data-lang=${AppManager.currentLanguage}]`).removeClass('active');
        const lang = $(event.target).data('lang');
        langListElement.find(`[data-lang=${lang}]`).addClass('active');
        if (AppManager.onLanguageSelected(lang)) LanguageSelect.determineLanguage(lang);
        animating = false;
        arrowElement.removeClass('open');
        langListElement.animate({ height: 'toggle' }, { complete: () => { langListElement.css('display', 'none'); } });
        const attr_label = lang === 'en' ? 'data-label' : `data-label_${lang}`;
        $('[data-label]', modalElem).each(function () { $(this).html($(this).attr(attr_label)); });
        $('.lang-btn span').html(stepLanguageLabel());
        AppManager.updateControlsLabel(lang);
    });

    $('.container-item', modalElem).on('click', function (event) {
        event.preventDefault();
        if (arrowElement.hasClass('open') && animating) {
            animating = false;
            arrowElement.removeClass('open');
            langListElement.animate({ height: 'toggle' }, { complete: () => { langListElement.css('display', 'none'); } });
        }
    });

    function get_label(link) {
        if (link[`label_${context.language}`]) {
            return link[`label_${context.language}`];
        }
        return link['label'];
    }

}

function stepLanguageLabel() {
    const lang = AppManager.metaConfig.language.boxTitle;
    if (lang['label_' + AppManager.currentLanguage]) {
        return lang['label_' + AppManager.currentLanguage];
    }
    return lang.label;
}