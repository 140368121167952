import { AppManager, PopupManager } from '@common';
import $ from 'jquery';
import { assetDir } from '../config/paths';

const audioPaths = {
  '1f': `${assetDir}/sounds/bgm_1f.aac`,
  '2f': `${assetDir}/sounds/bgm_2f.aac`,
  'b1f': `${assetDir}/sounds/bgm_b1f.aac`
};
const maxVolume = 1.0;

class SoundManager {
  constructor() {
    this.enabled = true;
    this.paused = false;
    this.currentBGM;
    window.document.addEventListener('visibilitychange', this.onVisibilityChange.bind(this), false);
    // window.document.addEventListener('webkitvisibilitychange', this.onVisibilityChange.bind(this), false);
  }

  init() {
    const audioElement = $(`<audio id='bgm' controls hidden loop></audio>`).appendTo($('body'));
    $(`<source>`, { id: 'aac_src', type: 'audio/aac' }).appendTo(audioElement);
    $("#aac_src").attr("src", audioPaths['1f']);
  }

  setupBGM(bgmId) {
    if (bgmId === undefined) return;
    const audio = document.getElementById("bgm");
	if (audio) {
		audio.pause();
		$("#aac_src").attr("src", audioPaths[bgmId]);
		
		audio.volume = maxVolume;
		audio.load();
	}

    const soundIcon = this.enabled ? "sound_on.gif" : "sound_off.gif";
    $('.sound-toggle .sound').children('img').attr('src', `assets/images/controls/${soundIcon}`);
    

    this.replayVoice();
  }


  replayVoice() {
    if (!this.enabled) return;
    const audio = document.getElementById("bgm");
    if (PopupManager.isModalVisible()) {
      let bReplayBGM = true;
      $('[id^="modal-"]:visible').find('video').each((index, element) => {
        let bgm = element.dataset.bgm == null || element.dataset.bgm == "" || element.dataset.bgm == undefined ? 'false' : element.dataset.bgm;
        if (bgm === "false") bReplayBGM = false;
      });
      if (!audio) return;
      if (bReplayBGM) audio.play();
    } else {
      if (AppManager.viewer.panorama.userData.controller.videoWallHolder === undefined) {
        if (audio) audio.play();
      } else {
        let bgReplay = true;
        AppManager.viewer.panorama.userData.controller.videoWallHolder.forEach(videoWall => {
          if (!videoWall.muted) {
            videoWall.video.muted = false;
            bgReplay = false;
          }
        });
        if (!audio) return;
        if (bgReplay) audio.play();
      }
    }
  }

  playBGM() {
    if (!this.enabled) return;
    const audio = document.getElementById("bgm");
    if (!audio) return;
    audio.play();
  }

  muteBGM() {
    if (!this.enabled) return;
    const audio = document.getElementById("bgm");
    if (!audio) return;
    if (!audio.paused) audio.pause();
  }


  muteVoice() {
    this.paused = true;
    const audio = document.getElementById("bgm");
    if (!audio) return;
    if (!audio.paused) audio.pause();

    if (!PopupManager.isModalVisible()) {
      if (AppManager.viewer.panorama.userData.controller.videoWallHolder !== undefined) {
        AppManager.viewer.panorama.userData.controller.videoWallHolder.forEach(videoWall => { videoWall.video.muted = true; });
      }
    }
  }


  onVisibilityChange(event) {
    if (!this.enabled) return;
    const audio = document.getElementById("bgm");
    if (!audio) return;
    if (window.document.visibilityState === 'visible') {
      this.replayVoice();
    } else {
      this.muteVoice();
    }
  }
}

export default new SoundManager();