import { AppManager, appConfig, assetDir } from '@common';
import * as THREE from 'three';
import BaseInteractiveSpot from './BaseInteractiveSpot';
import { GUI } from '~/includedAssets/js/dat.gui.module';
import logger from '../utils/logger';
import { GLTFLoader } from '~/includedAssets/jsm/loaders/GLTFLoader';
import TWEEN from '@tweenjs/tween.js';

const buttonScale = 5;
const baseSize = 200;
export default class InfoMoreViewSpot extends BaseInteractiveSpot {
  constructor(panorama, config, viewer) {
    super(panorama);
    if (config.action.panoId !== undefined) config.action.panoId = "";
    this.productViewConfig = config;
    this.viewer = viewer;

    if (appConfig.debug.visibleHelper) document.querySelectorAll(".dg.main").forEach(e => e.remove());

    const gltfLoader = new GLTFLoader();
    new THREE.TextureLoader().load(this.button, (tex) => {
      gltfLoader.load(`${assetDir}/images/buttons/more_btn.glb`,
        (gltf) => {
          this.buttonMesh = this.createButton(gltf, tex);
        },
        (xhr) => {
          logger.info((xhr.loaded / xhr.total * 100) + '% loaded');
        },
        (error) => { console.log('An error happened =>', error); });
    });

    this.panorama.addEventListener('change-language', this.onChangeLanguage.bind(this));
  }


  createButton(gltf, baseTex) {

    let selectedObject = null;

    const isMobile = AppManager.isSmartPhone();
    const scale = (isMobile ? (this.productViewConfig.buttonSPScale === undefined ? buttonScale : this.productViewConfig.buttonSPScale) : (this.productViewConfig.buttonScale === undefined ? buttonScale : this.productViewConfig.buttonScale));
    const position = (isMobile ? (this.productViewConfig.positionSP === undefined ? this.productViewConfig.position : this.productViewConfig.positionSP) : this.productViewConfig.position);
    const mesh = gltf.scene;

    baseTex.wrapS = baseTex.wrapT = THREE.RepeatWrapping;
    baseTex.flipY = false;

    mesh.children[0].material = new THREE.MeshBasicMaterial();
    mesh.children[0].material.transparent = true;
    mesh.children[0].material.opacity = 0;
    mesh.children[0].renderOrder = 1;

    mesh.children[1].material = new THREE.MeshBasicMaterial();
    mesh.children[1].material.transparent = true;
    mesh.children[1].material.map = baseTex;
    mesh.children[1].material.opacity = 0;

    mesh.scale.multiplyScalar(baseSize);
    mesh.scale.multiplyScalar(scale);

    mesh.userMouse = new THREE.Vector2();

    this.panorama.add(mesh);

    const scaleOri = mesh.children[0].scale.clone().x;
    const durationScale = 1500;
    const durationShow = 1500;
    const durationHide = 1500;

    const scaleUpAnimation = new TWEEN.Tween(mesh.children[0].scale)
      .to({ x: 1.125, y: 1.125, z: 1.125 }, durationScale)
      .easing(TWEEN.Easing.Exponential.Out);

    const scaleDownAnimation = new TWEEN.Tween(mesh.children[0].scale)
      .to({ x: scaleOri, y: scaleOri, z: scaleOri }, durationScale)
      .easing(TWEEN.Easing.Exponential.Out);

    const showOuterMeshAnimation = new TWEEN.Tween(mesh.children[0].material)
      .to({ opacity: 0.1 }, durationShow)
      .easing(TWEEN.Easing.Quartic.Out);

    const hideOuterMeshAnimation = new TWEEN.Tween(mesh.children[0].material)
      .to({ opacity: 0 }, durationHide)
      .easing(TWEEN.Easing.Quartic.Out);

    const showInnerMeshAnimation = new TWEEN.Tween(mesh.children[1].material)
      .to({ opacity: 0.98 }, durationShow)
      .easing(TWEEN.Easing.Quartic.Out);

    const hideInnerMeshAnimation = new TWEEN.Tween(mesh.children[1].material)
      .to({ opacity: 0 }, durationHide)
      .easing(TWEEN.Easing.Quartic.Out);

    mesh.dispose = () => {
      AppManager.viewer.renderer.domElement.removeEventListener('pointerdown', onSelectStart);
      AppManager.viewer.renderer.domElement.removeEventListener('mousemove', onSelectMove);
      AppManager.viewer.renderer.domElement.removeEventListener('pointerup', onSelectEnd);
      AppManager.viewer.scene.remove(mesh);
      disposeHierchy(this.viewer.scene, disposeNode);
    };

    const disposeNode = (node) => {
        if (node instanceof THREE.Mesh) {
            if (node.geometry) {
                node.geometry.dispose();
            }
        }
      };
    
      const disposeHierchy = (node, callback) => {
        for (var i = node.children.length - 1; i >= 0; i--) {
          var child = node.children[i];
    
          disposeHierchy(child, callback);
          callback(child);
        }
      };

    mesh.show = (delay = 0) => {
      hideOuterMeshAnimation.stop();
      hideInnerMeshAnimation.stop();
      showOuterMeshAnimation.delay(delay).start();
      showInnerMeshAnimation.delay(delay).start();
    };
    mesh.hide = (delay = 0, forceHide = true) => {
      showOuterMeshAnimation.stop();
      showInnerMeshAnimation.stop();
      hideOuterMeshAnimation.delay(delay).start();
      hideInnerMeshAnimation.delay(delay).start();
    };
    mesh.isScaleAnim = false;

    const onSelectStart = (event) => {
      const x = event.clientX;
      const y = event.clientY;

      mesh.userMouse.set(x, y);

      AppManager.getObject(event, mesh, () => {
      });
    };

    const onSelectMove = (event) => {
      if (selectedObject) {
        selectedObject = null;
        mesh.isHover = false;
        this.viewer.container.style.cursor = 'default';
      }

      const res = AppManager.getObject(event, mesh);

      if (res && res.material) {
        selectedObject = res;
        mesh.isHover = true;
        this.viewer.container.style.cursor = 'pointer';
        if (!mesh.isScaleAnim) {
          scaleDownAnimation.stop();
          scaleUpAnimation.start();
          mesh.isScaleAnim = true;
        }
      } else {
        if (mesh.isScaleAnim) {
          scaleUpAnimation.stop();
          scaleDownAnimation.start();
          mesh.isScaleAnim = false;
        }
      }
    };

    const onSelectEnd = (event) => {
      const x = event.clientX;
      const y = event.clientY;

      const deltaX = Math.abs(x - mesh.userMouse.x);
      const deltaY = Math.abs(y - mesh.userMouse.y);

      if (selectedObject) {
        selectedObject = null;
        mesh.isHover = false;
      }

      AppManager.getObject(event, mesh, () => {

        if (deltaX < 3 && deltaY < 3) {
          const { action } = this.productViewConfig;
          this.actionHandler(action);
        }
      });
    };

    this.viewer.renderer.domElement.addEventListener('pointerdown', onSelectStart);
    this.viewer.renderer.domElement.addEventListener('mousemove', onSelectMove);
    this.viewer.renderer.domElement.addEventListener('pointerup', onSelectEnd);

    const animating = () => {

      TWEEN.update();
      mesh.quaternion.copy(this.viewer.camera.quaternion);

      requestAnimationFrame(animating);
    };
    requestAnimationFrame(animating);

    if (appConfig.debug.visibleHelper) {

      const gui2 = new GUI();
      const folder2 = gui2.addFolder('position');

      const params2 = {
        positionX: position[0] * -1,
        positionY: position[1],
        positionZ: position[2],
        multiply: scale,
        clear: function () { },
        print: function () { }
      };

      folder2.add(params2, 'positionX', -5000, 5000).step(0.001).name('position X').onChange(
        function (value) {
          mesh.position.x = value;
          params2.positionX = value;
        }
      ).setValue(params2.positionX);

      folder2.add(params2, 'positionY', -5000, 5000).step(0.001).name('position Y').onChange(
        function (value) {
          mesh.position.y = value;
          params2.positionY = value;
        }
      ).setValue(params2.positionY);

      folder2.add(params2, 'positionZ', -5000, 5000).step(0.001).name('position Z').onChange(
        function (value) {
          mesh.position.z = value;
          params2.positionZ = value;
        }
      ).setValue(params2.positionZ);

      folder2.add(params2, 'multiply', 0.1, 5).step(0.001).name('scale').onChange(
        function (value) {
          mesh.scale.setScalar(baseSize * value, baseSize * value, baseSize * value);
          params2.multiply = value;
        }
      ).setValue(params2.multiply);

      gui2.add(params2, 'clear').name('Reset Value').onChange(function () {
        params2.positionX = position[0] * -1;
        params2.positionY = position[1];
        params2.positionZ = position[2];
        params2.multiply = mesh.multiply;

        mesh.position.x = params2.positionX;
        mesh.position.y = params2.positionY;
        mesh.position.z = params2.positionZ;
        mesh.multiply = params2.multiply;

        gui2.updateDisplay();
      });

      gui2.add(params2, 'print').name('Print Value').onChange(function () {
        console.log(`[ ${params2.positionX.toFixed(2)}, ${params2.positionY.toFixed(2)}, ${params2.positionZ.toFixed(2)} ]`);
        console.log(`[ ${params2.rotateX.toFixed(2)}, ${params2.rotateY.toFixed(2)}, ${params2.rotateZ.toFixed(2)} ]`);
      });

      folder2.open();
      gui2.close();

      gui2.domElement.querySelector(".close-bottom").innerText = this.productViewConfig.action.content;

    } else {
      mesh.position.fromArray(position).multiply(new THREE.Vector3(-1, 1, 1));
    }

    showOuterMeshAnimation.delay(1750).start();
    showInnerMeshAnimation.delay(1750).start();

    return mesh;
  }

  onChangeLanguage() {
    if (this.buttonMesh && this.buttonMesh.children[1].material) {
      new THREE.TextureLoader().load(this.button, tex => {
        tex.wrapS = tex.wrapT = THREE.RepeatWrapping;
        tex.flipY = false;
        this.buttonMesh.children[1].material.map = tex;
      });
    }
  }

  get button() {
    let prefix = AppManager.getScreenType() === "mb" ? "" : "PC";

    if (this.productViewConfig[`button${prefix}_` + AppManager.currentLanguage]) {
      return this.productViewConfig[`button${prefix}_` + AppManager.currentLanguage];
    }
    return this.productViewConfig[`button${prefix}`];
  }

  get label() {
    if (this.productViewConfig['label_' + AppManager.currentLanguage]) {
      return this.productViewConfig['label_' + AppManager.currentLanguage];
    }
    return this.productViewConfig.label;
  }

}