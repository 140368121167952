import { AppManager } from '@common';
import * as THREE from 'three';
import logger from '../utils/logger';

class CacheManager {
  constructor() {
    this.panoramaHistory = [];
  }


  check(panoramaId, msg = '') {
    logger.info(`[CacheManager] cache check ${panoramaId} ${msg}`, THREE.Cache);
    const fileKeys = Object.keys(THREE.Cache.files);
    const panoramaFiles = fileKeys.filter(key => key.indexOf('images/panoramas/') >= 0);
    logger.info(`[CacheManager] panoramaFiles: ${panoramaFiles.length} | ${panoramaFiles}`);

    if (msg === 'onEnter') {
      this.panoramaHistory = this.panoramaHistory.filter(p => p !== panoramaId);
      this.panoramaHistory.push(panoramaId);
      logger.info(`[CacheManager] panoramaHistory ${this.panoramaHistory}`);
    }

    if (panoramaFiles.length >= 5) {
      const lastPanoId = this.panoramaHistory.shift();
      const ctl = AppManager.getPanoramaById(lastPanoId);
      if (ctl) {
        logger.info(`[CacheManager] cache removing ${ctl.config.image}`);
        THREE.Cache.remove(ctl.config.image);
        logger.info(`[CacheManager] panoramaFiles(removed): ${panoramaFiles.length} | ${panoramaFiles}`);
      }
    }
  }

}

export default new CacheManager();