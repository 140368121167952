import $ from 'jquery';
import colorParse from 'color-parse';
import { appConfig } from '@common';


export function getParams() {
  var arg = new Object;
  var pair = location.search.substring(1).split('&');
  for (var i = 0; pair[i]; i++) {
    var kv = pair[i].split('=');
    arg[kv[0]] = kv[1];
  }
  return arg;
}


export function createContext() {
  const popupManager = window.parent && window.parent.PopupManager;
  if (!popupManager) return null;
  const ctx = {};
  ctx.popupManager = popupManager;

  const params = getParams();

  // content
  ctx.videoId = params.vid ? decodeURIComponent(unescape(params.vid)) : null;
  ctx.productIds = params.pids ? decodeURIComponent(params.pids).split(appConfig.pidSeparator) : null;
  ctx.viewType = params.view ? params.view : null;
  ctx.detailId = params.did ? params.did : null;
  ctx.productId = params.pid ? params.pid : null;
  ctx.language = params.lang ? params.lang : 'en';
  ctx.iframeId = params.ifid ? '#' + params.ifid : null;

  return ctx;
}


export function convertLanguageCode(lang) {
  switch (lang) {
    case 'ja':
      return 'japanese';
    case 'zh-Hant':
      return 'traditional_chinese';
    case 'zh-Hans':
      return 'simplified_chinese';
    case 'en':
    default:
      return 'english';
  }
}


// 日本語：円（税込）　例-> 10,000円（税込）
// 英語：Yen (tax included)　例-> 10,000Yen (tax included)
// 簡体文字：日元（含税）　例-> 10,000日元（含税）
// 繁体文字：日圓（含稅）　例-> 10,000日圓（含稅） (edited) 
export function getCurrencyString(lang) {
  switch (lang) {
    case 'ja':
      return `円（税込）`;
    case 'zh-Hant':
      return `日圓（含稅）`;
    case 'zh-Hans':
      return `日元（含税）`;
    case 'en':
    default:
      return `Yen (tax included)`;
  }
}


export function getLinkToECLabel(lang, purchasable = true) {
  const cartIcon = `<img src="assets/images/buttons/cart.svg" class="cart">`;
  switch (lang) {
    case 'ja':
      return purchasable ? cartIcon + '詳細・購入する' : '詳細';
    case 'zh-Hant':
      return purchasable ? cartIcon + '詳細內容‧購買' : '詳細內容';
    case 'zh-Hans':
      return purchasable ? cartIcon + '详细内容‧购买' : '详细内容';
    case 'en':
    default:
      return purchasable ? cartIcon + '<span class="mini">See more information<br />and purchase</span>' : 'More information';
  }
}


export function isPC() {
  return window.matchMedia('screen and (min-width: 768px)').matches;
}


export function createProductIconButton(productIds, context, valueType) {
  if (valueType !== 'pid' && valueType !== 'url') return null;

  $('#wrapper').append('<ul id="product-list"></ul>');

  productIds.forEach(pidColored => {
    const [pid, color, colorHover] = pidColored.split('--');
    const info = context.popupManager.getProductInfoById(pid);

    const buttonStyle = (color && colorParse(color).space !== undefined) ? 'class="custom"' : 'class="default"';
    const buttonValue = valueType === 'pid' ? pid : info.brand_site_url;
    const buttonTag = `<button value="${buttonValue}" ${buttonStyle}><img src="${info.product_file}" alt=""></button>`;
    const elButton = $(buttonTag);

    if (color && colorParse(color).space !== undefined) {
      elButton.css({ backgroundColor: color });
      elButton.hover(function () {
        if (colorHover && colorParse(colorHover).space !== undefined) {
          $(this).css({ backgroundColor: colorHover });
        } else {
          const parsed = colorParse(color);
          const [r, g, b] = parsed.values;
          const a = Math.min(parsed.alpha + 0.2, 1);
          $(this).css({ backgroundColor: `rgba(${r},${g},${b},${a})` });
        }
      }, function () {
        $(this).css({ backgroundColor: color });
      });
    }

    const elLi = ($('<li>', { class: 'product-button' })).appendTo('#product-list');
    elButton.appendTo(elLi);
  });
  return $('.product-button button');
}