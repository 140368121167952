import $ from 'jquery';
import { AppManager, assetDir } from '@common';

require('../../../assets/js/pixi.min.js');
require('../../../assets/js/TweenMax.min.js');

var ripples = [];
var filters = [];

class Animation {
  constructor() {
    this.app_bg = null;
    this.app_title = null;

    PIXI.settings.RESOLUTION = window.devicePixelRatio > 2 ? 2 : window.devicePixelRatio;
    PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;
    PIXI.settings.ROUND_PIXELS = false;
    PIXI.settings.FILTER_RESOLUTION = window.devicePixelRatio > 2 ? 2 : window.devicePixelRatio;
    PIXI.settings.TARGET_FPMS = 0.003;
  }

  add() {
    $('<canvas>', { id: 'canvas' }).appendTo($('#animation-wrapper-bg'));
    $('<canvas>', { id: 'canvas2' }).appendTo($('#animation-wrapper-title'));
  }

  showFloorLogo(floorTo) {
    this.add();

    this.setupBgCanvas(floorTo);
    this.setupTitleCanvas(floorTo);

    $('#animation-white').show();
    $('#animation-white').animate({ opacity: 1 }, {
      complete: () => {
        $('#animation-white').animate({ opacity: 1 });
        this.show();
      }, duration: 1500
    });
  }

  showWecomeFloor() {
    this.add();

    this.setupBgCanvas('1f');
    this.setupTitleCanvas('1f');

    $('#animation-bg').css({ opacity: 1 });

    AppManager.enabledDeviceControl();

    this.show(false);
  }

  show(changeFloor = true) {
    if (changeFloor) $('#animation-white').animate({ opacity: 0 }, { complete: () => { $('#animation-white').hide(); }, duration: 3600 });

    setTimeout(() => { this.startDrops(); }, 900);

    $('#animation-bg').show();
    $('#animation-title').show();
    $('#animation-bg').animate({ opacity: 1 });

    setTimeout(() => {
      $('#animation-title').css({ 'z-index': 10000 });
      $('#animation-title').animate({ opacity: 1 }, { complete: () => { $('#animation-bg').hide(); }, duration: 2700 });
    }, 1800);

    //シャボン玉消えるタイミング ミリ秒数指定
    setTimeout(() => { this.hide(); }, 5100);
  }

  hide() {
    AppManager.onDeviceOrientationControl();
    $('#animation-title').animate({ opacity: 0 }, {
      complete: () => {
        $('#animation-title').css({ 'z-index': 0 });
        $('#animation-title').hide();
        AppManager.showFloorAnimation = false;
        this.resetCanvas();
        AppManager.addTutorial();
      }, duration: 2700
    });
  }

  isVisible() {
    return $('#animation-title:visible').length > 0;
  }

  setupBgCanvas(floorTo) {
    const lowFloor = floorTo.toLowerCase();
    const screenType = AppManager.getScreenType();

    switch (screenType) {
      case 'ip':
        this.texture_width = 2732;
        this.texture_height = 2048;
        this.filetype = 'webp';
        break;
      case 'mb':
        this.texture_width = 1170;
        this.texture_height = 2532;
        this.filetype = 'webp';
        break;
      case 'pc':
      default:
        this.texture_width = 1920;
        this.texture_height = 1080;
        this.filetype = 'jpg';
        break;
    }

    var texture = new PIXI.Texture.fromImage(`${assetDir}/images/tobira/${screenType}/bg/${lowFloor}_bg.${this.filetype}`);

    this.app_bg = new PIXI.Application({
      view: document.querySelector("#canvas"),
      height: window.innerHeight,
      width: window.innerWidth,
      autoResize: true,
      transparent: true
    });

    var container = new PIXI.Container();
    this.app_bg.stage.addChild(container);

    var scale = 1;
    var position = new PIXI.Point(0, 0);

    this.domElementRatio = window.innerWidth / window.innerHeight;
    this.imageRatio = this.texture_width / this.texture_height;

    if (this.domElementRatio > this.imageRatio) {
      //photo is taller than background
      scale = window.innerWidth / this.texture_width;
      position.y = -(this.texture_height * scale - window.innerHeight) / 2;
      position.x = 0;
    } else {
      //photo is wider than background
      scale = window.innerHeight / this.texture_height;
      position.x = -(this.texture_width * scale - window.innerWidth) / 2;
      position.y = 0;
    }

    const bg = new PIXI.Sprite(texture);
    bg.scale = new PIXI.Point(scale, scale);
    bg.position = position;

    const displacementSprite = new PIXI.Sprite(texture);

    var displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
    displacementFilter.scale.x = 0;
    displacementFilter.scale.y = 0;

    filters.push(displacementFilter);
    const loopCount = AppManager.isSmartPhone() ? 10 : 3;
    for (let i = 0; i < loopCount; i++) {
      const ripple = new PIXI.Sprite.fromImage(`${assetDir}/images/ripple_1028.png`);
      ripple.anchor.set(0.5);
      ripple.scale.set(0);
      this.app_bg.stage.addChild(ripple);

      const filter = new PIXI.filters.DisplacementFilter(ripple);
      filters.push(filter);
      ripples.push(ripple);
    }

    this.app_bg.stage.filters = filters;
    container.addChild(bg);
    window.addEventListener('resize', () => {
      if (this.app_bg !== null) {
        this.app_bg.view.style.width = window.innerWidth + 'px';
        this.app_bg.view.style.height = window.innerHeight + 'px';
      }
    });
  }

  setupTitleCanvas(floorTo) {
    const lowFloor = floorTo.toLowerCase();
    const screenType = AppManager.getScreenType();
    var texture = new PIXI.Texture.fromImage(`${assetDir}/images/tobira/${screenType}/${AppManager.currentLanguage}/${lowFloor}_title.${this.filetype}`);

    this.app_title = new PIXI.Application({
      view: document.querySelector("#canvas2"),
      height: window.innerHeight,
      width: window.innerWidth,
      autoResize: true,
      transparent: true
    });

    var container = new PIXI.Container();
    this.app_title.stage.addChild(container);

    var scale = 1;
    var position = new PIXI.Point(0, 0);

    if (this.domElementRatio > this.imageRatio) {
      //photo is taller than background
      scale = window.innerWidth / this.texture_width;
      position.y = -(this.texture_height * scale - window.innerHeight) / 2;
      position.x = 0;
    } else {
      //photo is wider than background
      scale = window.innerHeight / this.texture_height;
      position.x = -(this.texture_width * scale - window.innerWidth) / 2;
      position.y = 0;
    }

    const bg = new PIXI.Sprite(texture);
    bg.scale = new PIXI.Point(scale, scale);
    bg.position = position;

    const displacementSprite = new PIXI.Sprite(texture);
    displacementSprite.resolution = 2;
    displacementSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY;

    var displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
    displacementFilter.scale.x = 0;
    displacementFilter.scale.y = 0;

    filters.push(displacementFilter);

    this.app_title.stage.filters = filters;
    container.addChild(bg);
    window.addEventListener('resize', () => {
      if (this.app_title !== null) {
        this.app_title.view.style.width = window.innerWidth + 'px';
        this.app_title.view.style.height = window.innerHeight + 'px';
      }
    });
  }

  startDrops() {
    const rippleCount = AppManager.isSmartPhone() ? 3 : 2;
    var scaleX = window.innerWidth / 10;
    var scaleY = window.innerHeight / 3;
    (function myLoop(i) {

      if (i % 2 == 0) {
        var minY = 0;
        var maxY = scaleY * 1;
        var minX = scaleX * 10;
        var maxX = scaleX * 10;
      } else {
        var minY = scaleY * 2;
        var maxY = scaleY * 3;
        var minX = scaleX * 4;
        var maxX = scaleX * 4;
      }

      var y = Math.floor(Math.random() * (maxY - minY) + maxY);
      var x = Math.floor((Math.random() * (maxX - minX)) + minX);

      ripples[i].position.set(x, y);
      TweenMax.fromTo(ripples[i].scale, 6, { x: .1, y: .1 }, { x: 2.5, y: 2.5 });

      setTimeout(function () {
        if (--i) myLoop(i);
      }, 200);
    })(rippleCount);//  pass the number of iterations as an argument
  }

  resetCanvas() {
    this.destroy();
    $('#animation-wrapper-bg').empty();
    $('#animation-wrapper-title').empty();
    $('<canvas>', { id: 'canvas' }).appendTo($('#animation-wrapper-bg'));
    $('<canvas>', { id: 'canvas2' }).appendTo($('#animation-wrapper-title'));
  }

  destroy() {
    TweenMax.killAll(true, true, true);
    if (this.app_bg !== null) {
      while (this.app_bg.stage.children[0]) {
        this.app_bg.stage.removeChild(this.app_bg.stage.children[0]);
      }
      this.app_bg.stage.removeChild();
      this.app_bg.destroy();
      this.app_bg = null;
    }
    if (this.app_title !== null) {
      while (this.app_title.stage.children[0]) {
        this.app_title.stage.removeChild(this.app_title.stage.children[0]);
      }
      this.app_title.stage.removeChild();
      this.app_title.destroy();
      this.app_title = null;
    }

    ripples = [];
    filters = [];
  }
}

export default new Animation();