import "../../styles/partials/welcome.scss";
import { AppManager, LanguageSelect, AnalyticsManager } from '@common';
import $ from 'jquery';
import logger from "../utils/logger";

let animating = false;
let stepReady = false;
let current_step = 1;
let beforePointerClass = "";
let beforeHelperClass = "";

class TutorialScreen {
    constructor() { }

    add() {
        const languageLabel = stepLanguageLabel();
        const langBtns = AppManager.metaConfig.language.langBtns;
        const tag = `<div id="intro" style="display: none;">
    <div class="lang-area"> 
        <div class="lang-wrapper">
            <button class="lang-btn"><span>${languageLabel}</span><div class="arrow"></div></button>
            <div class="lang-list">
                <a href="#" data-lang="ja">${langBtns["label_ja"]}</a>
                <a href="#" data-lang="en">${langBtns["label"]}</a>
                <a href="#" data-lang="zh-Hans">${langBtns["label_zh-Hans"]}</a>
                <a href="#" data-lang="zh-Hant">${langBtns["label_zh-Hant"]}</a>
            </div>
        </div>
    </div>
    <div class="introjs-overlay" style="position: fixed; cursor: pointer;"></div>
    <div class="introjs-tooltipReferenceLayer introjs-${AppManager.getScreenType()} ">
      <div class="introjs-tooltip" role="dialog">
        <div class="introjs-tooltiptext"></div>
        <div class="introjs-arrow" style="display: inherit;"></div>
        <a role="button" tabindex="0" class="introjs-button introjs-nextbutton"><img src="assets/images/tutorial/${AppManager.currentLanguage === 'ja' ? 'en' : AppManager.currentLanguage}/next.svg"></a>
      </div>
    </div>
    <div class="introjs-pointer"></div>
    <div class="introjs-helperLayer introjs-${AppManager.getScreenType()}"></div>
  </div>`;
        $('body').append(tag.trim());

        $('#intro').fadeIn('slow');
        $('#intro .introjs-arrow').addClass('bottom');
        $('#changeVR-nav').addClass('tutorial');

        let init_pointer = {};
        let init_tooltip = {};
        let init_helperLayer = { 'opacity': '1' };
        if (!AppManager.isSmartPhone()) {
            init_pointer = {};
            init_tooltip = { 'animation-name': 'slideTooltips', 'animation-duration': '1500ms', 'animation-fill-mode': 'forwards', 'width': 'fit-content', 'max-width': "70%" };
        } else {
            init_pointer = {};
            init_tooltip = { 'animation-name': 'slideTooltipsMB', 'animation-duration': '1500ms', 'animation-fill-mode': 'forwards', 'width': 'fit-content', 'max-width': "70%" };
        }
        setupMessage(1, 2, init_helperLayer, init_tooltip, 2000);

        const langListElement = $('.lang-area .lang-list');
        const arrowElement = $('.lang-area .arrow');

        langListElement.find(`[data-lang=${AppManager.currentLanguage}]`).addClass('active');

        $('.lang-btn').off('click').on('click', function (event) {
            event.preventDefault();
            if (animating) return false;
            animating = true;
            arrowElement.addClass('open');
            langListElement.animate({ height: 'toggle' }, { complete: () => { langListElement.css('display', 'block'); } });
        });

        $('.lang-list a').off('click').on('click', function (event) {
            event.preventDefault();
            if (!animating) return false;
            langListElement.find(`[data-lang=${AppManager.currentLanguage}]`).removeClass('active');
            const lang = $(event.target).data('lang');
            langListElement.find(`[data-lang=${lang}]`).addClass('active');
            if (AppManager.onLanguageSelected(lang)) LanguageSelect.determineLanguage(lang);
            animating = false;
            arrowElement.removeClass('open');
            langListElement.animate({ height: 'toggle' }, { complete: () => { langListElement.css('display', 'none'); } });

            const prefix = !AppManager.isSmartPhone() ? "PC" : "";
            $('#intro .introjs-tooltiptext').html(stepLabel(`step${current_step === -1 ? 4 : current_step - 1}${prefix}`));
            $('#intro .lang-btn span').html(stepLanguageLabel());
            $('#intro .introjs-nextbutton img').attr('src', `assets/images/tutorial/${AppManager.currentLanguage === 'ja' ? 'en' : AppManager.currentLanguage}/${current_step === -1 ? 'close' : 'next'}.svg`);
            AppManager.updateControlsLabel(lang);
        });

        $('#intro .introjs-tooltipReferenceLayer').off('click').on('click', (e) => {
            e.preventDefault();
            if (arrowElement.hasClass('open') && animating) {
                autoCloseLang();
            } else {
                if (!stepReady) return false;
                stepReady = false;
                if (current_step === -1) {
                    if (AppManager.getCookie(`vgfs_accessed`) !== "true") AppManager.setCookie(`vgfs_accessed`, true, 30);
                    AnalyticsManager.sendPanorama(AppManager.viewer.panorama.userData.controller.config.analytics);
                    AppManager.asWelcomeScreen = false;
                    $('#intro').animate({ opacity: 0 }, {
                        complete: () => {
                            $('#intro').css('display', 'none');
                            $('#intro').remove();
                        }, duration: 900
                    });
                } else if (current_step > 1 || current_step <= 4) {
                    $('#changeVR-nav').removeClass('tutorial');
                    $('#intro .introjs-helperLayer').removeAttr('style');
                    $('#intro .introjs-pointer').removeAttr('style');
                    $('#intro .introjs-tooltip').removeAttr('style');

                    let tooltip = {};
                    let nextStep = current_step + 1;

                    // GYRO未対応の端末はGYROに関する案内をSKIPする
                    logger.info("Has gyro functon : ", AppManager.getHasGyroFuncton());
                    if (!AppManager.getHasGyroFuncton() && (current_step === 2)) {
                        current_step++;
                        nextStep++;
                        $('#intro .introjs-helperLayer').empty();
                    }

                    if (current_step === 2) {
                        $('#intro .introjs-helperLayer').empty();
                        $('.gyro-toggle').css({ 'z-index': '10000', 'pointer-events': 'none', 'cursor': 'not-allowed' });
                        if (!AppManager.isSmartPhone()) {
                            tooltip = { 'animation-name': 'slideTooltipsGyro', 'animation-duration': '1500ms', 'animation-fill-mode': 'forwards', 'width': 'fit-content', 'max-width': "70%" };
                        } else {
                            tooltip = { 'animation-name': 'slideTooltipsGyroMB', 'animation-duration': '1500ms', 'animation-fill-mode': 'forwards', 'width': 'fit-content', 'max-width': "70%" };
                        }
                    } else if (current_step === 3) {
                        $('.gyro-toggle').removeAttr('style');
                        $('#intro .introjs-arrow').removeClass('bottom').addClass('bottom-middle');

                        $('#intro .introjs-helperLayer').empty();

                        if (!AppManager.isSmartPhone()) {
                            $('#slideMenu').css({ 'z-index': '10000', 'pointer-events': 'none', 'cursor': 'not-allowed' });
                            tooltip = { 'animation-name': 'slideTooltipsStep2', 'animation-duration': '1500ms', 'animation-fill-mode': 'forwards', 'width': 'fit-content', 'max-width': "70%" };
                        } else {
                            $('.sound-toggle').css({ 'z-index': '10000', 'pointer-events': 'none', 'cursor': 'not-allowed' });
                            tooltip = { 'animation-name': 'slideTooltipsSound', 'animation-duration': '1500ms', 'animation-fill-mode': 'forwards', 'width': 'fit-content', 'max-width': "70%" };
                        }
                    } else if (current_step === 4) {
                        $('#intro .introjs-arrow').removeClass('bottom-middle').addClass('top-right');
                        if (!AppManager.isSmartPhone()) {
                            $('#slideMenu').removeAttr('style');
							const pcAnimationName = window.innerHeight < 700 ? "slideTooltipsStep3-min" : "slideTooltipsStep3";
                            tooltip = { 'animation-name': AppManager.getScreenType() === "pc" ? pcAnimationName : 'slideTooltipsStep3Ip', 'animation-duration': '1500ms', 'animation-fill-mode': 'forwards' };
                        } else {
                            $('.sound-toggle').removeAttr('style');
                            tooltip = { 'animation-name': 'slideTooltipsStep4', 'animation-duration': '1500ms', 'animation-fill-mode': 'forwards', 'width': 'fit-content', 'max-width': "70%" };
                        }
                        nextStep = -1;

                        $('#intro .introjs-nextbutton img').attr('src', `assets/images/tutorial/${AppManager.currentLanguage === 'ja' ? 'en' : AppManager.currentLanguage}/close.svg`);
                    }
                    setupMessage(current_step, nextStep, init_helperLayer, tooltip);
                }
            }
        });

        function setupMessage(thisStep, nextStep, helperLayer, tooltip, delay = 1000) {
            const prefix = !AppManager.isSmartPhone() ? "PC" : "";
            const label = stepLabel(`step${thisStep}${prefix}`);
            const pointerClass = `pointerstep${thisStep}-${nextStep}-${AppManager.getScreenType()}`;
            const helperClass = `helperLayer${thisStep}-${nextStep}-${AppManager.getScreenType()}`;
            current_step = nextStep;
            $('#intro .introjs-helperLayer').css(helperLayer);
            $('#intro .introjs-pointer').removeClass(beforePointerClass);
            $('#intro .introjs-pointer').addClass(pointerClass);
            $('#intro .introjs-helperLayer').removeClass(beforeHelperClass);
            $('#intro .introjs-helperLayer').addClass(helperClass);
            $('#intro .introjs-tooltip').hide();
            setTimeout(() => {
                $('#intro .introjs-tooltip').fadeIn();
                $('#intro .introjs-tooltip').css(tooltip);
                setTimeout(() => { stepReady = true; }, 2000);
            }, delay);
            $('#intro .introjs-tooltiptext').html(label);
            AnalyticsManager.sendTutorial(thisStep);
            beforePointerClass = pointerClass;
            beforeHelperClass = helperClass;
        }

        function stepLabel(step) {
            const lang = AppManager.metaConfig.tutorial[step];
            if (lang['label_' + AppManager.currentLanguage]) {
                return lang['label_' + AppManager.currentLanguage];
            }
            return lang.label;
        }

        function stepLanguageLabel() {
            const lang = AppManager.metaConfig.language.boxTitle;
            if (lang['label_' + AppManager.currentLanguage]) {
                return lang['label_' + AppManager.currentLanguage];
            }
            return lang.label;
        }
        function autoCloseLang() {
            if (arrowElement.hasClass('open') && animating) {
                animating = false;
                arrowElement.removeClass('open');
                langListElement.animate({ height: 'toggle' }, { complete: () => { langListElement.css('display', 'none'); } });
            }
        }
    }
}

export default new TutorialScreen();