import "../../styles/partials/linksMenu.scss";
import $ from 'jquery';
import { AppManager } from "@common";
import AnalyticsManager from "../common/AnalyticsManager";

export default class LinksMenu {
  constructor(configs) {
    this.configs = configs;
    this.force = false;

    if (AppManager.getBeforeFloor() === AppManager.getCurrentFloor()) {
      $('#slideMenu .item.active').removeClass('active');
      AppManager.setupCurrentLinkMenu();

      document.onmouseover = null;
      document.onmouseover = function (e) {
        var event = e || window.event;
        var target = event.target;
        if ($(target).closest('div#slideMenu').length < 1) {
          $('#slideMenu .container').addClass('hide');
        }
        document.onmouseover = null;
      };
    } else {
      this.add();
    }
  }

  add() {
    const st = AppManager.getScreenType();
    const element = $('#slideMenu');
    element.empty();
    const container = $(`
    <div class="howtoicon">MENU</div>
    <div class="container hide">
      <div class="item-lists"></div>
      <div class="info">
        <div class="icon"><img src="assets/images/information.png"></div>
        <div class="text">
          <span class="label"></span>
          <span class="description"></span>
        </div>
      </div>
      <div class="pager">
        <div class="next${st === 'pc' ? '-pc' : ''}"><svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="24" cy="24" rx="24" ry="24" fill="#343434"/><path d="M22.8587 30.896L29.7998 24L22.8587 17.104" stroke="white"/><path d="M18 24L29.7998 24" stroke="white"/></svg></div>
        <div class="prev${st === 'pc' ? '-pc' : ''}"><svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="24" cy="24" rx="24" ry="24" transform="rotate(-180 24 24)" fill="#343434"/><path d="M25.1413 17.104L18.2002 24L25.1413 30.896" stroke="white"/><path d="M30 24L18.2002 24" stroke="white"/></svg></div>
      </div>
    </div>`);

    if ($(".item-lists").length === 0) {
      container.appendTo(element);
    }

    let currentPanoId = AppManager.viewer.panorama.userData.controller.config.id;
    let cnt = 0;
    this.configs.forEach(config => {
      if (config.floor === AppManager.getCurrentFloor()) {
        $(`<div class="item" data-pano="${config.id}"><div class="image"><img src="${config.thumbnail ? config.thumbnail : 'assets/images/panoramas/thumbnail/no_thumbnail.gif'}"><div class="image-overlay"></div></div><div class="title">${AppManager.getlabel(config)}</div></div>`).appendTo($('.item-lists', container));
        cnt++;
      }
    });
    AppManager.setupCurrentLinkMenu();

    const scrollContainer = document.querySelector('.item-lists');
    let scrollWidth = scrollContainer.scrollWidth - scrollContainer.clientWidth;
    let itemWidth = document.querySelector('#slideMenu .item').clientWidth;

    window.addEventListener("orientationchange", function (eeee) {
      setTimeout(() => {
        AppManager.setupCurrentLinkMenu();
        scrollWidth = scrollContainer.scrollWidth - scrollContainer.clientWidth;
        itemWidth = document.querySelector('#slideMenu .item').clientWidth;
      }, 200);
    }, false);

    // if (AppManager.currentFloor === "2f" && window.innerWidth > 1500) {
    //   $(".item-lists").addClass('center');
    // } else {
    this.enableSideScroll(".item-lists", scrollWidth, (itemWidth / 4));
    // }

    if (scrollContainer.scrollWidth > scrollContainer.clientWidth) {

      $('.pager .prev,.pager .prev-pc', container).off('click').on('click', event => {
        $('.pager .next,.pager .next-pc', container).fadeIn();
        if (scrollContainer.scrollLeft > 0) {
          scrollContainer.scrollLeft -= itemWidth;
        }
        if ((scrollContainer.scrollLeft - itemWidth) <= 0) {
          $('.pager .prev,.pager .prev-pc', container).fadeOut();
        }
      });

      $('.pager .next,.pager .next-pc', container).off('click').on('click', event => {
        $('.pager .prev,.pager .prev-pc', container).fadeIn();
        if (scrollContainer.scrollLeft < scrollWidth) {
          scrollContainer.scrollLeft += itemWidth;
        }
        if ((scrollContainer.scrollLeft + itemWidth) >= scrollWidth) {
          $('.pager .next,.pager .next-pc', container).fadeOut();
        }
      });

    } else {
      $('.pager', container).hide();
    }

    if (st === 'pc') {
      scrollContainer.addEventListener('wheel', (evt) => {
        evt.preventDefault();
        scrollContainer.scrollLeft += evt.deltaY;
        if (scrollContainer.scrollLeft > (scrollWidth - (itemWidth / 4))) {
          $('#slideMenu .pager .next,#slideMenu .pager .next-pc').fadeOut();
        } else {
          $('#slideMenu .pager .next,#slideMenu .pager .next-pc').fadeIn();
        }
        if (scrollContainer.scrollLeft < (itemWidth / 4)) {
          $('#slideMenu .pager .prev,#slideMenu .pager .prev-pc').fadeOut();
        } else {
          $('#slideMenu .pager .prev,#slideMenu .pager .prev-pc').fadeIn();
        }
      });
    } else {
      scrollContainer.addEventListener('scroll', () => {
        if (scrollContainer.scrollLeft > (scrollWidth - (itemWidth / 4))) {
          $('#slideMenu .pager .next,#slideMenu .pager .next-pc').fadeOut();
        } else {
          $('#slideMenu .pager .next,#slideMenu .pager .next-pc').fadeIn();
        }
        if (scrollContainer.scrollLeft < (itemWidth / 4)) {
          $('#slideMenu .pager .prev,#slideMenu .pager .prev-pc').fadeOut();
        } else {
          $('#slideMenu .pager .prev,#slideMenu .pager .prev-pc').fadeIn();
        }
      });
    }
    $('.item', container).off('click').on('click', event => {
      const panoID = ($(event.currentTarget).data('pano')).toString();
      currentPanoId = AppManager.viewer.panorama.userData.controller.config.id;
      if (currentPanoId != panoID) {
        const linkConfig = AppManager.getPanoramaById(panoID);
        $('#slideMenu .item.active').removeClass('active');
        $('#slideMenu .info .text').css({ 'opacity': 0, 'margin-left': '20%' });
        $(event.currentTarget).addClass('active');
        this.force = true;
        AppManager.setLoading(true);
        AnalyticsManager.sendLinkMenu(linkConfig.config.analytics.title, 'Menu');
        
        AppManager.gotoPanorama(linkConfig);
        setTimeout(() => {
          AppManager.setupCurrentLinkMenu(false);
        }, 500);
      }
    });

    if (st === 'pc') {
      this.removeEventHandler("#slideMenu", "mouseenter");
      this.removeEventHandler("#slideMenu", "mouseleave");

      $('#slideMenu').on('mouseenter', () => {
        if (document.getElementById('slideMenu').getElementsByClassName('container')[0].getBoundingClientRect().height < 5) {
          AnalyticsManager.sendLinkMenu('Open', 'Main UI');
        }
        $('#slideMenu .container').removeClass('hide');
      });

      $('#slideMenu').on('mouseleave', () => {
        if (!this.force) $('#slideMenu .container').addClass('hide');
        this.force = false;
      });

      $('.item', container).on('mouseleave', event => {
        $('#slideMenu .info .text').stop();
        $('.image-overlay', $(event.currentTarget)).css({ 'opacity': 0, 'margin-left': '0%' });
        $('#slideMenu .info .text').animate({ 'opacity': 0 }, {
          complete: () => {
            const currentPano = AppManager.viewer.panorama.userData.controller.config;
            $('#slideMenu .info .text .label').html(AppManager.getlabel(currentPano));
            $('#slideMenu .info .text .description').html(AppManager.getlabel(currentPano, 'infoLabel'));
            $('#slideMenu .info .text').css({ 'margin-left': '0%' });
            $('#slideMenu .info .text').animate({ 'opacity': 1 }, {
              complete: () => {

              }, duration: 700
            });
          }, duration: 1
        });
      });

      $('.item', container).on('mouseenter', event => {
        $('#slideMenu .info .text').stop();
        const panoID = ($(event.currentTarget).data('pano')).toString();
        $('.image-overlay', $(event.currentTarget)).css({ 'opacity': 0.5 });
        $('#slideMenu .info .text').animate({ 'opacity': 0, 'margin-left': '20%' }, {
          complete: () => {
            const panoDetail = AppManager.getPanoramaConfig(panoID);
            const info = AppManager.getlabel(panoDetail, 'infoLabel');
            $('#slideMenu .info .text .label').html(AppManager.getlabel(panoDetail));
            $('#slideMenu .info .text .description').html(info ? info : '');
            $('#slideMenu .info .text').animate({ 'opacity': 1, 'margin-left': '0' }, {
              complete: () => {

              }, duration: 700
            });
          }, duration: 1
        });
      });
    } else if (st === 'ip') {
      this.removeEventHandler("#slideMenu .howtoicon", "touchstart");
      $('#slideMenu .howtoicon').on('touchstart', () => {
        if (document.getElementById('slideMenu').getElementsByClassName('container')[0].getBoundingClientRect().height < 5) {
          AnalyticsManager.sendLinkMenu('Open');
        }
        $('#slideMenu .container').toggleClass('hide');
      });
      document.addEventListener("touchstart", function () { }, false);
    }
  }

  removeEventHandler(element, event) {
    let handler = $._data($(element).get(0), "events");
    if (handler !== undefined) {
      $(element).off(event);
    }
  }

  enableSideScroll(element, scrollWidth, adjustWidth) {
    let target; // 動かす対象
    $(element).each(function (i, e) {
      $(e).mousedown(function (event) {
        event.preventDefault();
        target = $(e); // 動かす対象
        $(e).data({
          "down": true,
          "move": false,
          "x": event.clientX,
          "y": event.clientY,
          "scrollleft": $(e).scrollLeft(),
          "scrolltop": $(e).scrollTop(),
        });
        return;
      });
      // move後のlink無効
      $(e).click(function (event) {
        if ($(e).data("move")) {
          return;
        }
      });
    });
    // list要素内/外でのevent
    $(document).mousemove(function (event) {
      if ($(target).data("down")) {
        event.preventDefault();
        let move_x = $(target).data("x") - event.clientX;
        let move_y = $(target).data("y") - event.clientY;
        if (move_x !== 0 || move_y !== 0) {
          $(target).data("move", true);
        } else { return; };

        let scrollLeft = $(target).data("scrollleft") + move_x;

        $(target).scrollLeft(scrollLeft);
        $(target).scrollTop($(target).data("scrolltop") + move_y);

        if (scrollLeft > (scrollWidth - adjustWidth)) {
          $('#slideMenu .pager .next,#slideMenu .pager .next-pc').fadeOut();
        } else {
          $('#slideMenu .pager .next,#slideMenu .pager .next-pc').fadeIn();
        }
        if (scrollLeft < adjustWidth) {
          $('#slideMenu .pager .prev,#slideMenu .pager .prev-pc').fadeOut();
        } else {
          $('#slideMenu .pager .prev,#slideMenu .pager .prev-pc').fadeIn();
        }

        return;
      }
    }).mouseup(function (event) {
      $(target).data("down", false);
      return;
    });
  }

  getlabel(obj, name = "linkLabel") {
    if (obj[name + '_' + AppManager.currentLanguage]) {
      return obj[name + '_' + AppManager.currentLanguage];
    }
    return obj[name];
  }
}