import $ from 'jquery';
import { AppManager, AnalyticsManager } from "@common";

require('../styles/ad.scss');

export function init(modalContainer, context) {

	const allConf = context.popupManager.getProductViewConfigByType('contents');
	const adsConf = allConf.ad;
	const adConf = adsConf.find(conf => conf.id === context.adId);
	const count = adConf['link'].length;

	let thumbnail_list = "";
	let cellX = 0;
	let userMouse = { x: 0, y: 0 };
	const gallery_cell_size = AppManager.isSmartPhone() ? 85.85 : 41;
	const labelVar = AppManager.isSmartPhone() ? "descriptionSP" : "description";
	const pxOffset = AppManager.isSmartPhone() ? 9 : 10;

	adConf['link'].forEach((e) => {
		thumbnail_list += `
		<div class="gallery-cell">
			<div class="thumbnail" data-bname="${e.label_ja}">
				<img src="${get_link_label(e, 'thumbnail')}">
			</div>
			<div class="caption">${get_link_label(e)}</div>
		</div>`;
	});

	const contents = `
	<div class="contents-container">
		<div class="scroll-content">
			<div class="contents-area">
				<div class="title"><h2>${get_link_label(adConf, 'title')}</h2></div>
				<div class="description"><p>${get_link_label(adConf, labelVar)}</p></div>
			</div>
			<div class="gallery" tabindex="0">
				<div class="gallery-viewport">
					<div class="gallery-slider" style="transform: translateX(${pxOffset}%);">${thumbnail_list}</div>
				</div>
			</div>
		</div>
	</div>`;

	const tag = `
    <div id="contents-wrapper">
        <div id="contents-ad-wrapper">
          ${contents}
        </div>
    </div>
	${AppManager.isSmartPhone() ? "" : `
	<div class="pager" style="display: none;">
		<div class="prev${AppManager.getScreenType() === 'pc' ? '-pc' : ''}"><svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="40.8134" cy="40" rx="40.1866" ry="40" transform="rotate(-180 40.8134 40)" fill="black"/><path d="M41.8705 32.727L34.5161 39.9997L41.8705 47.2725" stroke="white"/><path d="M47.0186 40L34.5161 40" stroke="white"/></svg></div>
		<div class="next${AppManager.getScreenType() === 'pc' ? '-pc' : ''}"><svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="40.1866" cy="40" rx="40.1866" ry="40" fill="black"/><path d="M39.1292 47.273L46.4836 40.0003L39.1292 32.7275" stroke="white"/><path d="M33.9812 40L46.4837 40" stroke="white"/></svg></div>
	</div>`}`.trim();

	modalContainer.append(tag);

	if (AppManager.isSmartPhone()) {
		let bgSetting = "";
		if (adConf.hasOwnProperty("bgImage")) {
			bgSetting = {
				"background-image": `url('${adConf.bgImage}')`,
				"background-size": "cover"
			};
			$('#contents-ad-wrapper').css(bgSetting);
		}
	}

	$('.gallery .gallery-cell .thumbnail').off('click').on('click', function (event) {
		event.preventDefault();
		const x = event.clientX;
		const y = event.clientY;

		const deltaX = Math.abs(x - userMouse.x);
		const deltaY = Math.abs(y - userMouse.y);

		if ((deltaX < 3 && deltaY < 3) || AppManager.isSmartPhone()) {
			const target = $(event.currentTarget);
			const index = target.parent().index();
			const bName = target.data('bname');
			const conf = adConf['link'][index];
			const videoConf = conf['video'];
			if (videoConf && videoConf.length > 0) {
				let option = videoConf[0]['action'];
				if (videoConf[0]['action']['productConditionsId']) {
					option.label = get_link_label(videoConf[0]);
				}
				AnalyticsManager.sendClickAdEvent("Click", conf.label_ja, "Banner - Slider");
				option.contentId = adConf.id;
				context.popupManager.openMovie(option, conf.label_ja, () => {
					AnalyticsManager.sendAd(adConf.analytics);
				});
			}
		}
	});

	window.onresize = function () {
		if (cellX > (count - (gallery_cell_size > 50 ? 1 : 2))) {
			cellX--;
		}

		setTranslateX();
	};

	function get_link_label(conf, name = "label") {
		if (conf[`${name}_${context.language}`]) {
			return conf[`${name}_${context.language}`];
		}
		return conf[name];
	}

	const container = $(modalContainer)[0];
	const element = container.querySelector('.scroll-content .gallery-slider');
	const jElPager = $(container.querySelector('.pager'));
	const btnPrev = $(container.querySelector('.prev,.prev-pc'));
	const btnNext = $(container.querySelector('.next,.next-pc'));
	const jBtnPrev = btnPrev[0];
	const jBtnNext = btnNext[0];

	function setTranslateX() {
		var adjustedX = (cellX * -1 * gallery_cell_size) + pxOffset;
		element.style.transform = 'translateX(' + adjustedX + '%)';
	}

	// なぜか2回目クリック以降は初期値が0じゃなくて50とか90になるので閾値を決める。
	const threshold = (adConf && adConf.length > 0) ? 150 : 100;

	const intervalId = setInterval(() => {
		// これも2回目クリック以降対応。外側枠と内側枠の高さが一緒になることはあり得ないので、もし一緒の場合は条件を満たさないとする
		if (($(".contents-container").innerHeight() > threshold) && ($("#contents-ad-wrapper").innerHeight() != $(".contents-container").innerHeight())) {
			clearInterval(intervalId);
			if (AppManager.isSmartPhone()) {
				$(".contents-container").css("margin-top", "13.7vh");
			} else {
				const diffHeight = $("#contents-ad-wrapper").innerHeight() - ($(".contents-container").innerHeight());
				if (diffHeight > 0) {
					$(".contents-container").css("margin-top", diffHeight / 2);
				}
			}
		}
	}, 500);

	if (AppManager.isSmartPhone()) {
		if (count > 0) {
			let xDown = null;
			let yDown = null;
			function addTouchEvent() {

				var touchstart = function (event) {
					xDown = event.touches[0].pageX;
					yDown = event.touches[0].pageY;
				};
				const touchmove = (evt) => {

					if (!xDown || !yDown) {
						return;
					}

					const case1 = (cellX - 1) >= 0;
					const case2 = (cellX + 1) <= (count - (gallery_cell_size > 50 ? 1 : 2));

					if ((case1 || case2)) {
						var xUp = evt.touches[0].clientX;
						if ((xUp > xDown) && case1) {
							cellX--;
						} else if ((xUp < xDown) && case2) {
							cellX++;
						}

						setTranslateX();
					}
					/* reset values */
					xDown = null;
					yDown = null;
				};
				element.addEventListener("touchstart", touchstart);
				element.addEventListener("touchmove", touchmove);
			}

			addTouchEvent();
		}

	} else {

		function addSlideEvent() {

			element.addEventListener('pointerdown', function (e) {
				userMouse.x = e.clientX;
				userMouse.y = e.clientY;
			});

			element.addEventListener('pointerup', function (e) {
				const x = e.clientX;
				const y = e.clientY;

				const deltaX = Math.abs(x - userMouse.x);

				if (deltaX >= 3) {

					const case1 = (x > userMouse.x) && ((cellX - 1) >= 0);
					const case2 = (x < userMouse.x) && ((cellX + 1) <= (count - 2));

					if ((case1 || case2)) {
						if (x > userMouse.x) {
							cellX--;
							if (cellX < count) {
								if (jBtnNext.classList.contains("fade")) {
									jBtnNext.classList.remove("fade");
								}
							}
						} else {
							cellX++;
							if (cellX > 0) {
								if (jBtnPrev.classList.contains("fade")) {
									jBtnPrev.classList.remove("fade");
								}
							}
						}

						setTranslateX();

						if (cellX <= 0) {
							jBtnPrev.classList.add("fade");
						} else if (cellX >= count - 2) {
							jBtnNext.classList.add("fade");
						}
					}
				}
			});
		}

		function addClickEvent() {
			jBtnPrev.classList.add("fade");

			btnPrev.off('click').on('click', event => {
				event.preventDefault();
				if (jBtnNext.classList.contains("fade")) {
					jBtnNext.classList.remove("fade");
				}

				if (((cellX - 1) >= 0)) {
					cellX--;
					setTranslateX();
					if (cellX <= 0) {
						jBtnPrev.classList.add("fade");
					}
				}
			});

			btnNext.off('click').on('click', event => {
				event.preventDefault();
				if (jBtnPrev.classList.contains("fade")) {
					jBtnPrev.classList.remove("fade");
				}
				if (((cellX + 1) <= (count - 2))) {
					cellX++;
					setTranslateX();
					if (cellX >= count - 2) {
						jBtnNext.classList.add("fade");
					}
				}
			});
		}

		if (count > 2) {
			addClickEvent();
			addSlideEvent();
			setTimeout(() => { jElPager.fadeIn(); }, 4000);
		} else {
			jElPager.hide();
		}
	}
	AnalyticsManager.sendAd(adConf.analytics);
}
