import '../styles/embeddedVideo.scss';
import $ from 'jquery';
import { createProductIconButton } from './modalUtils';
import { AnalyticsManager, SoundManager, AppManager } from '@common';


export const tag = `
<div class="wrapper-inmodal">
  <div class="video-container">
    <video controls webkit-playsinline playsinline controlsList="nodownload" disablePictureInPicture oncontextmenu="return false;">
      Your browser does not support the video tag.
    </video>
    <div class="play-bt"></div>
  </div>
</div>
`.trim();


export function init(videoModal, context, productNotice = null, completeCallback = null) {
  const videoElement = $('video', videoModal);
  const wrapperElement = $('.wrapper-inmodal', videoModal);
  const playCustom = $(".play-bt", wrapperElement);
  let never_play = true;
  let never_end = true;
  if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
    playCustom.addClass('safari-only');
  }
  if (context.thumbnail !== "") {
    videoElement[0].poster = context.thumbnail;
  }

  if (!SoundManager.enabled) {
    videoElement[0].muted = true;
  }
  if (context.videoId) {
    videoElement.attr('src', context.videoId);
    videoElement.attr('data-bgm', context.bgm);
  }
  if (context.productIds) {
    setupProductThumbnails(wrapperElement, videoElement, context);
    if (productNotice) {
      setupProductNotice(wrapperElement, productNotice);
    }
  }
  if (context.productConditionsId) {
    setupProductsList(wrapperElement, videoElement, context);
  }
  // 再生完了コールバック
  videoElement[0].addEventListener('ended', function () {
    if (completeCallback) completeCallback(this);
    if (never_end) {
      AnalyticsManager.sendVideo(context.title, 'End');
      never_end = false;
    }
  });
  videoElement[0].addEventListener('play', function () {
    if (never_play) AnalyticsManager.sendVideo(context.title, 'Play');
    playCustom.hide();
  });
  videoElement[0].addEventListener('pause', function () {
    never_play = false;
    playCustom.show();
  });
  playCustom.on('click', function () {
    playCustom.hide();
    videoElement[0].play();
  });

  videoElement[0].addEventListener('volumechange', function () {
    if (videoElement[0].muted === SoundManager.enabled ||
      (videoElement[0].muted && videoElement[0].volume === 0 && SoundManager.enabled) ||
      (!videoElement[0].muted && videoElement[0].volume === 1 && !SoundManager.enabled)) {
      $('.sound-toggle').trigger('click');
    }
  }, false);
}


function setupProductThumbnails(wrapperElement, videoElement, context) {
  wrapperElement.append('<ul id="product-list"></ul>');
  const buttonElements = createProductIconButton(context.productIds, context, 'url');
  $('button', buttonElements).on('click', function () {
    const siteUrl = $(this).val();
    window.open(siteUrl + context.popupManager.getViewHistoryParams());
    videoElement[0].pause();
  });
}

function setupProductsList(wrapperElement, videoElement, context) {
  const buttonElements = wrapperElement.append(`<button>${context.label}</button>`);
  $('button', buttonElements).on('click', function () {
    videoElement[0].pause();
    const video_bgm = videoElement.attr('data-bgm');
    videoElement.attr('data-bgm', true);
    if (video_bgm) SoundManager.playBGM();
    const st = AppManager.getScreenType();
    if (st === 'mb') {
      context.popupManager.openCategoryListView('video-productDetail', context.productConditionsId, context.contentId, context, () => {
        videoElement.attr('data-bgm', video_bgm);
        if (video_bgm) SoundManager.muteBGM();
      });
    } else {
      context.popupManager.openCategoryPCMode('video-productDetail', null, context.contentId, context.productConditionsId, null, () => {
        videoElement.attr('data-bgm', video_bgm);
        if (video_bgm) SoundManager.muteBGM();
      });
    }
  });
}

function setupProductNotice(wrapperElement, notice) {
  const container = $('<div class="product-notice"></div>').appendTo(wrapperElement);
  container.html(notice);
}