import $ from 'jquery';
import logger from '../utils/logger';

let attrLang = "ja";

export function detectLanguage() {
  const uaLang = (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    window.navigator.userLanguage ||
    window.navigator.browserLanguage;
    logger.info("user detect language : ", uaLang);
  
  let lang;
  if (uaLang.slice(0, 2) === 'ja') {
    lang = 'ja';
  } else if (uaLang.slice(0, 2) === 'zh') {
    if (uaLang.indexOf('Hant') > 0 || (uaLang.toLowerCase()).indexOf('tw') > 0 || (uaLang.toLowerCase()).indexOf('hk') > 0) {
      lang = 'zh-Hant';
    } else {
      lang = 'zh-Hans';
    }
  } else {
    lang = 'en';
  }
  logger.info("Response site language : ", lang);
  return lang;
}

export function determineLanguage(lang) {

  $('body').removeClass('lang-ja lang-zh-Hant lang-zh-Hans lang-en');
  $('body').addClass(`lang-${lang}`);
  if (lang === 'ja') {
    attrLang = 'ja';
  } else if (lang === 'en') {
    attrLang = 'en';
  } else if (lang === 'zh-Hant') {
    attrLang = 'zh-cmn-Hant';
  } else if (lang === 'zh-Hans') {
    attrLang = 'zh-cmn-Hans';
  }
  $('html').attr('lang', attrLang);
}