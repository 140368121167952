import 'ress';

import $ from 'jquery';
import { AppManager, AnalyticsManager, ViewHistoryManager, appConfig } from '@common';

import Scrollbar from 'smooth-scrollbar';

export function init(element, context) {
  const mode = AppManager.getScreenType();
  if (mode === "mb") {
    require('../styles/contentsMenu.scss');
  } else {
    require('../styles/contentsMenu_PC.scss');
  }

  const allConf = context.popupManager.getProductViewConfigByType('contents');
  const contentsConf = allConf.contentsMenu;
  const contentConf = contentsConf.find(conf => parseInt(conf.id) === parseInt(context.detailId));

  if (context.isSendAnalytics) AnalyticsManager.sendMenuContents(contentConf.analytics);

  const videoId = get_movie();

  const contents_mb = `
<div class="contents-area">
    <div class="contents-title"><h2 class="font-ext">${get('title')}</h2></div>
    <div class="guide-text"><p>${get('discription')}</p></div>
    <div class="contents-links"></div>
</div>`;

  const contents = `<div class="contents-container">${contents_mb}</div>`;

  const tag = `
    <div id="contents-wrapper">
        <div id="contents-menu-wrapper">
          <div class="video-area">
            <video id="bg-video" autoplay muted loop playsinline webkit-playsinline controlsList="nodownload" disablePictureInPicture oncontextmenu="return false;"><source src="${videoId}" type="video/mp4"></video>
          </div>
          ${mode === "mb" ? contents_mb : contents}
        </div>
        <div class="category-area"></div>
        <div id="popup-confirm" style="display: none;">
            <div class="popup-confirm-wrapper">
                <div class="txt">
                    <div class="confirm-txt" onclick=""></div>
                    <div class="confirm-show"><label for="check01"></label></div>
                </div>
                <div class="btn">
                    <button class="popup-ok">${getLabel(AppManager.metaConfig.popup.btnOK)}</button>
                    <button class="popup-close">${getLabel(AppManager.metaConfig.popup.btnCancel)}</button>
                </div>
            </div>
        </div>
    </div>`.trim();
  element.append(tag);

  if (mode !== "mb") Scrollbar.init($('.contents-container', element)[0], { alwaysShowTracks: true });
  $('.scrollbar-track-x', element).remove();

  if (AppManager.isSmartPhone()) {
    let bgSetting = "";
    if (contentConf.hasOwnProperty("bgImage")) {
        bgSetting = {
        "background-image": `url('${contentConf.bgImage}')`,
        "background-size": "cover"
        };
        $('#contents-menu-wrapper').css(bgSetting);
    }
  }

  const videoElement = $('video', element);
  videoElement.attr('data-bgm', context.bgm);

  ViewHistoryManager.saveVideo(videoId);

  setupLinks();

  $('.contents-links li').off('click').on('click', function (event) {
    event.preventDefault();
    const type = $(event.currentTarget).find('button').data('type');
    const content = $(event.currentTarget).find('button').data('content');
    const hasComfirm = $(event.currentTarget).find('button').data('hascomfirm');
    const bName = $(event.currentTarget).find('button').data('bname');
    const tmpGAParams = $(event.currentTarget).find('button').data('gaparams') !== "" ? $(event.currentTarget).find('button').data('gaparams') : context.popupManager.getViewHistoryParams();
    const gaParams = $(event.currentTarget).find('button').data('hidegaparams') === true ? "" : tmpGAParams;

    $('#popup-confirm .popup-ok').off();
    switch (type) {
      case 'url':
        const linkUrl = content.replace(`{VGFS_PP_DOMAIN}`, appConfig.ppSiteDomain);
        AnalyticsManager.sendOpeningContents(contentConf.analytics.title, bName);
        getComfirmPopup(() => { context.popupManager.openNewTab(linkUrl + gaParams); });
        break;
      case 'iframe-url':
        videoElement[0].pause();
        AnalyticsManager.sendOpeningContents(contentConf.analytics.title, bName);
        getComfirmPopup(() => {
               context.popupManager.openBrandSiteByIframe(content + gaParams, 
               () => { 
                    videoElement[0].play(); 
                    AnalyticsManager.sendMenuContents(contentConf.analytics);
             }); 
          });
        break;
      case 'video':
        videoElement[0].pause();
        const conf = contentConf['link'][$(event.target).closest('li').index()];
        const videoConf = conf['video'];
        if (videoConf && videoConf.length > 0) {
          let option = videoConf[0]['action'];
          if (videoConf[0]['action']['productConditionsId']) {
            option.label = get_link_label(videoConf[0]);
          }
          AnalyticsManager.sendOpeningContents(contentConf.analytics.title, bName);
          option.contentId = contentConf.id;
          context.popupManager.openMovie(option, conf.label_ja, () => {
            videoElement[0].play();
            AnalyticsManager.sendMenuContents(contentConf.analytics);
          });
        }
        break;
      case 'category':
        AnalyticsManager.sendOpeningContents(contentConf.analytics.title, bName);
        context.popupManager.openCategoryListView(type, content, context.detailId, context);
        break;
      case 'productDetail':
        AnalyticsManager.sendOpeningContents(contentConf.analytics.title, bName);
        videoElement[0].pause();
        if (mode === 'mb') {
          context.popupManager.openCategoryListView(type, content, context.detailId, context, () => { videoElement[0].play(); });
        } else {
          context.popupManager.openCategoryPCMode(type, null, context.contentId, content, null, () => {
              videoElement[0].play();
              AnalyticsManager.sendMenuContents(contentConf.analytics);
          });
        }
        break;
    }

    function getComfirmPopup(callback = null) {
      if (hasComfirm && AppManager.getCookie('no_alert') !== "true" && AppManager.currentLanguage !== "ja") {
        $('.modal a.close-modal').hide();
        $('#popup-confirm .txt .confirm-txt').html(getLabel(AppManager.metaConfig.guide.supportOnlyJP));
        $('#popup-confirm').removeAttr('style');

        $('#popup-confirm .confirm-show label').html(get_alert_check_tag());

        $('#popup-confirm .popup-ok').off('click').on('click', function (event) {
          event.preventDefault();
          $('.modal a.close-modal').show();
          $('#popup-confirm').css('display', 'none');
          $('#popup-confirm .popup-ok').off();
          $('#popup-confirm .txt .confirm-txt').text('');
          if (document.querySelector('#popup-confirm #check01').checked) AppManager.setCookie('no_alert', true, 30);
          if (callback) callback();
        });
      } else {
        if (callback) callback();
      }
    }
  });

  $('#popup-confirm .popup-close').off('click').on('click', function (event) {
    event.preventDefault();
    $('#popup-confirm').css('display', 'none');
    $('.modal a.close-modal').show();
  });

  function get(name) {
    if (contentConf[`${name}_${context.language}`]) {
      return contentConf[`${name}_${context.language}`];
    }
    return contentConf[name];
  }

  function get_movie() {
    if (contentConf['movie'][`content_${context.language}`]) {
      return contentConf['movie'][`content_${context.language}`];
    }
    return contentConf['movie']['content'];
  }

  function get_link_label(link) {
    if (link[`label_${context.language}`]) {
      return link[`label_${context.language}`];
    }
    return link['label'];
  }

  function get_alert_check_tag() {
    const tag = `<input type="checkbox" name="confirm_show" id="check01">`;
    const tag_guide = getLabel(AppManager.metaConfig.guide.checkTag);
    return `${tag}<div class="text">&ensp;${tag_guide}</div>`;
  }

  function setupLinks() {
    const linksConf = contentConf['link'];

    if (!AppManager.isSmartPhone()) {

        // なぜか2回目クリック以降は初期値が0じゃなくて50とか90になるので閾値を決める。
        const threshold = (linksConf && linksConf.length > 0) ? 150 : 100;
        const intervalId = setInterval(() => {

            // これも2回目クリック以降対応。外側枠と内側枠の高さが一緒になることはあり得ないので、もし一緒の場合は条件を満たさないとする
            if (($(".scroll-content").innerHeight() > threshold) && ($(".contents-container").innerHeight() != $(".scroll-content").innerHeight())) {
                clearInterval(intervalId);
                const diffHeight = $(".contents-container").innerHeight() - $(".scroll-content").innerHeight();
                if (diffHeight > 0) {
                    $(".scroll-content").css("margin-top", diffHeight / 2);
                } else {
                    $(".put-on-top").css("padding", "0");
                }
            } 
        }, 500);
    }

    if (linksConf && linksConf.length > 0) {
      const listElement = $('<ul>').appendTo('.contents-links');
      linksConf.forEach(link => {
        let content = "";
        let newtabIcon = "";
        switch (link.type) {
          case 'url':
            content = getLinkUrl(link);
            if (link.thumbnail === undefined) {
                newtabIcon = `<img src="assets/images/newtab_wt.svg" class="newtab">`;
            } else {
                newtabIcon = `<img src="assets/images/newtab_bk.svg" class="newtab">`;
            }
            break;
          case 'iframe-url':
            content = getLinkUrl(link);
            break;
          case 'category':
            content = link.upperCategoryId;
            break;
          case 'productDetail':
            content = link.productConditionsId;
            break;
        }

        let tag;
        if (link.bannerImage !== undefined) {
            tag = `<li>
                <div class="no-thumb"><div class="button-wrap">
                    <button
                        data-type="${link.type}" 
                        data-content="${content}" 
                        data-hascomfirm="${link.alert === true ? true : false}" 
                        data-hidegaparams="${link.hideGAParams === true ? true : false}" 
                        data-gaparams="${link.specifyGAParams ? link.specifyGAParams.replace(`{VGFS_UTM_CAMPAIGN}`, appConfig.utm_campaign).replace(`{VGFS_DOMAIN}`, appConfig.siteDomain) : ""}" 
                        data-bname="${link.label_ja}">
                            <img src="${link.bannerImage}" alt="${link.label_ja}" />
                    </button></div>
                </div></li>`;
        } else if (link.thumbnail === undefined) {
            tag = `<li>
                <div class="no-thumb"><div class="button-wrap">
                    ${link.showNewIcon === true ? "<div class=\"new-icon\">NEW</div>" : ""}
                    <button
                        data-type="${link.type}" 
                        data-content="${content}" 
                        data-hascomfirm="${link.alert === true ? true : false}" 
                        data-hidegaparams="${link.hideGAParams === true ? true : false}" 
                        data-gaparams="${link.specifyGAParams ? link.specifyGAParams.replace(`{VGFS_UTM_CAMPAIGN}`, appConfig.utm_campaign).replace(`{VGFS_DOMAIN}`, appConfig.siteDomain) : ""}" 
                        data-bname="${link.label_ja}">
                        ${get_link_label(link)} ${newtabIcon}
                    </button></div>
                </div></li>`;
        } else {
            tag = `<li>
                <div class="has-thumb"><div class="button-wrap">
                    ${link.type === "video" ? "<div class=\"video-icon\"></div>" : ""} 
                    <button class="thumb" 
                        data-type="${link.type}" 
                        data-content="${content}" 
                        data-hascomfirm="${link.alert === true ? true : false}" 
                        data-hidegaparams="${link.hideGAParams === true ? true : false}" 
                        data-gaparams="${link.specifyGAParams ? link.specifyGAParams : ""}" 
                        data-bname="${link.label_ja}">
                        <img src="${link.thumbnail}" alt="${link.label_ja}" />
                    </button></div>
                    <div class="button-wrap2">
                        ${link.showNewIcon === true ? "<div class=\"new-icon\">NEW</div>" : ""}
                        <button class="label"
                            data-type="${link.type}" 
                            data-content="${content}" 
                            data-hascomfirm="${link.alert === true ? true : false}" 
                            data-hidegaparams="${link.hideGAParams === true ? true : false}" 
                            data-bname="${link.label_ja}">
                            ${get_link_label(link)}
                        ${link.type === "url" ? `<div class=\"new-tab\">${newtabIcon}</div>` : ""}
                    </button></div>
                </div></li>`;
        }
        $(tag).appendTo(listElement);
      });
      if (linksConf.length > 2) {
          $(".contents-container").addClass("put-on-top");
      }
    } 
  }

  function getLinkUrl(obj) {
    if (obj['linkUrl_' + AppManager.currentLanguage]) {
      return obj['linkUrl_' + AppManager.currentLanguage];
    }
    return obj.linkUrl;
  }

  function getLabel(obj) {
    if (obj['label_' + AppManager.currentLanguage]) {
      return obj['label_' + AppManager.currentLanguage];
    }
    return obj.label;
  }
}

export function openCategory(element, context, panoId) {
  const videoElement = $('video', element);
  let content = $('.contents-links li', element).find('button[data-type="category"]').data('content');
  if (panoId === '05') {
    content = $('.contents-links li', element).eq(1).find('button[data-type="category"]').data('content');
  }
  if (content) {
    if (AppManager.getScreenType() === 'mb') {
      videoElement[0].pause();
      context.popupManager.openCategoryListView('category', content, context.detailId, context, () => { videoElement[0].play(); });
    } else {
      context.popupManager.openCategoryListView('category', content, context.detailId, context);
    }
  } else {
    const allConf = context.popupManager.getProductViewConfigByType('contents');
    const contentsConf = allConf.contentsMenu;
    const contentConf = contentsConf.find(conf => parseInt(conf.id) === parseInt(context.detailId));
    AnalyticsManager.sendMenuContents(contentConf.analytics);
  }
}