import $ from 'jquery';
import * as jqueryModal from 'jquery-modal';
import querystring from 'querystring';
import {
	AppManager,
	SoundManager,
	modalDir,
	appConfig,
	logger,
	AnalyticsManager,
	ViewHistoryManager,
	SFCCManager,
} from '@common';
import * as embeddedVideo from '../../../src_modals/scripts/embeddedVideo';
import * as embeddedCategoryList from '../../../src_modals/scripts/embeddedCategoryList';
import * as embeddedProductsList from '../../../src_modals/scripts/embeddedProductsList';
import * as embeddedMapUI from '../../../src_modals/scripts/embeddedMapUI';
import * as embeddedContentsMenu from '../../../src_modals/scripts/embeddedContentsMenu';
import * as embeddedAd from '../../../src_modals/scripts/embeddedAd';

const addRandom = true;
const commonModalSettings = {
	closeExisting: false,
	escapeClose: false,
	clickClose: false,
	closeClass: 'initial-hidden',
	blockerClass: 'blocker-blur',
};

class PopupManager {
	constructor() {
		this.productInfoList = [];
		this.productViewConfigs = {};
	}


	cancelLoadingVideo(videoModal) {
		// embeddedVideoでの動画ロード中にモーダルを閉じた時にロードをキャンセルする
		// https://stackoverflow.com/questions/4071872/html5-video-force-abort-of-buffering
		const videoElement = $('video', videoModal);
		const video = videoElement.get(0);
		if (!video) return false;
		const wasPlaying = !video.paused;
		video.currentTime = 0.0; // Optional -- can be left out for pause
		video.pause();

		const sourseElement = $('source', videoElement);
		const sourse = sourseElement.get(0);
		if (sourse) {
			sourse.src = '';
		} else {
			video.src = '';
		}

		video.preload = 'none';
		video.autoplay = false;
		if (wasPlaying) { // This prevents the browser from trying to load the entire source
			video.load();
		}
	}

	openNewTab(url) {
		window.open(url, '_blank');
	}

	async openMovie(options, title, closeCallback = null, completeCallback = null) {
		const context = {
			language: AppManager.currentLanguage,
			popupManager: this,
			conf: options
		};
		if (!options.hasOwnProperty('content') || !options.hasOwnProperty('type') || options.type.indexOf('modal--video') === -1) return;

		let videoModal = $('#modal-3').html(embeddedVideo.tag);
		context.videoId = this.getLocalizedContent(options);
		context.title = title;
		context.thumbnail = options.hasOwnProperty('thumbnail') ? this.getLocalizedThumbnail(options) : "";
		context.contentId = options.hasOwnProperty('contentId') ? options.contentId : "";
		context.bgm = options.bgm == null || options.bgm == "" || options.bgm == undefined ? false : options.bgm;
		switch (options.type) {
			case 'modal--video-products':
				if (options.productConditionsId) {
					context.productConditionsId = options.productConditionsId;
					context.label = options.label;
				}
				break;
			case 'modal--video-products-ad':
				if (options.productConditionsId) {
					context.productConditionsId = options.productConditionsId;
					context.label = options.label;
					context.typeAdVideo = true;
				}
				break;
		}
		embeddedVideo.init(videoModal, context, null, completeCallback);
		if (!context.bgm) SoundManager.muteVoice();
		let videoRatioClass = (
			(AppManager.isSmartPhone() && options.isVerticalSP === true) ||
			(!AppManager.isSmartPhone() && options.isVertical === true)
		) ? 'vertical' : 'horizontal';

		let modalClass = "modal--video-mb";
		if (AppManager.getScreenType() === "ip") {
			modalClass = "modal--video-ip";
		} else if (AppManager.getScreenType() === "pc") {
			modalClass = "modal--video-pc";
		}
		$('#modal-3').modal({
			...commonModalSettings,
			closeClass: '',
			fadeDuration: 500,
			modalClass: `modal modal--embed modal--video ${modalClass} ${videoRatioClass}`,
			blockerClass: 'blocker-movie blocker-movie-pc add-duration',
			closeText: '<svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="96" height="96" fill="black"/><path d="M25.225 24L72 72M70.775 24L24 72" stroke="white"/></svg>',
		});

		$('#modal-3').one($.modal.BEFORE_CLOSE, (event, modal) => {
			event.preventDefault();
			this.cancelLoadingVideo(videoModal);
		});

		$('#modal-3').one($.modal.AFTER_CLOSE, (event, modal) => {
			event.preventDefault();
			$(event.currentTarget).empty();
			$(event.currentTarget).removeClass();
			SoundManager.replayVoice();
			if (closeCallback) closeCallback();
		});
		ViewHistoryManager.saveVideo(context.videoId);
	}

	async openContents(options, closeCallback = null, isSendAnalytics = true) {
		const mode = AppManager.getScreenType();
		if (mode === "mb") {
			this.openContentsMobileMode(options, closeCallback, isSendAnalytics);
		} else if (mode === "ip" | mode === "pc") {
			this.openContentsPCMode(options, closeCallback, isSendAnalytics);
		}
	}

	async openContentsMobileMode(options, closeCallback = null, isSendAnalytics = true) {
		if (!options.hasOwnProperty('content')) return;

		this.disabledRenderUpdate();
		let bgReplay = false;
		if (AppManager.viewer.panorama.userData.controller.videoWallHolder !== undefined) {
			AppManager.viewer.panorama.userData.controller.videoWallHolder.forEach(videoWall => {
				videoWall.video.pause();
				if (!videoWall.muted) bgReplay = true;
			});
		}

		SoundManager.playBGM();

		const context = {
			language: AppManager.currentLanguage,
			popupManager: this,
			detailId: options.contentsMenuId,
			viewType: options.content,
			isSendAnalytics: isSendAnalytics,
			bgm: true
		};

		embeddedContentsMenu.init($('#modal-1'), context);

		AppManager.toggleControls(false, true);
		AppManager.toggleHeaderNavi(false, true);
		$('#modal-1').modal({
			...commonModalSettings,
			fadeDuration: 500,
			// fadeDelay: 1,
			closeClass: '',
			modalClass: 'modal ' + options.type,
			blockerClass: 'blocker-contents',
			closeText: '<svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="96" height="96" fill="black"/><path d="M25.225 24L72 72M70.775 24L24 72" stroke="white"/></svg>',
		});
		if (options.panoId) {
			$('#modal-1').one($.modal.BEFORE_OPEN, (event, modal) => {
				//                embeddedContentsMenu.openCategory($('#modal-1'), context, options.panoId);
			});
		}

		$('#modal-1').one($.modal.BEFORE_CLOSE, (event, modal) => {
			this.cancelLoadingVideo($('#modal-1 .contents-menu-wrapper'));
			AppManager.viewer.skipLoading = true;

			if (AppManager.viewer.panorama.userData.controller.videoWallHolder !== undefined) {
				AppManager.viewer.panorama.userData.controller.videoWallHolder.forEach(videoWall => { videoWall.video.play(); });
			}

			this.enabledRenderUpdate();
		});

		$('#modal-1').one($.modal.AFTER_CLOSE, (event, modal) => {
			event.preventDefault();
			AppManager.toggleControls(true, true);
			AppManager.toggleHeaderNavi(true, true);
			if (bgReplay) SoundManager.muteBGM();
			$(event.currentTarget).empty();
			$(event.currentTarget).removeClass();
			$(event.currentTarget).removeAttr('style');
			SoundManager.replayVoice();
			if (closeCallback) closeCallback();

			AnalyticsManager.sendPanorama(AppManager.viewer.panorama.userData.controller.config.analytics);
		});
	}

	async openContentsPCMode(options, closeCallback = null, isSendAnalytics = true) {
		if (!options.hasOwnProperty('content')) return;

		// Todo : PCの場合でもレンダリングをモーダルOpen時は止める。でも今はこれをするとgotoPanoramaがモーダル閉じた後じゃないとビューが切り替わらない。
		// this.disabledRenderUpdate();
		let bgReplay = false;
		if (AppManager.viewer.panorama.userData.controller.videoWallHolder !== undefined) {
			AppManager.viewer.panorama.userData.controller.videoWallHolder.forEach(videoWall => {
				videoWall.video.pause();
				if (!videoWall.muted) bgReplay = true;
			});
		}

		SoundManager.playBGM();

		const context = {
			language: AppManager.currentLanguage,
			popupManager: this,
			detailId: options.contentsMenuId,
			viewType: options.content,
			isSendAnalytics: isSendAnalytics,
			bgm: true
		};

		embeddedContentsMenu.init($('#modal-1'), context);

		AppManager.toggleControls(false, true);
		//AppManager.toggleHeaderNavi(false, true);
		$('#modal-1').modal({
			...commonModalSettings,
			closeClass: '',
			closeExisting: true,
			escapeClose: true,
			clickClose: true,
			modalClass: 'modal ' + options.type + '-' + AppManager.getScreenType(),
			blockerClass: 'blocker-contents blocker-contents-pc',
			fadeDuration: 500,
			closeText: '<svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="96" height="96" fill="black"/><path d="M25.225 24L72 72M70.775 24L24 72" stroke="white"/></svg>',
		});

		$('#modal-1').one($.modal.BEFORE_CLOSE, (event, modal) => {
			this.cancelLoadingVideo($('#modal-1 .contents-menu-wrapper'));
			AppManager.viewer.skipLoading = true;

			if (AppManager.viewer.panorama.userData.controller.videoWallHolder !== undefined) {
				AppManager.viewer.panorama.userData.controller.videoWallHolder.forEach(videoWall => { videoWall.video.play(); });
			}
		});

		$('#modal-1').one($.modal.AFTER_CLOSE, (event, modal) => {
			event.preventDefault();
			if (bgReplay) SoundManager.muteBGM();
			$(event.currentTarget).empty();
			$(event.currentTarget).removeClass();
			$(event.currentTarget).removeAttr('style');
			SoundManager.replayVoice();
			if (closeCallback) closeCallback();

			AppManager.toggleControls(true, true);

			AnalyticsManager.sendPanorama(AppManager.viewer.panorama.userData.controller.config.analytics);
		});
	}

	async openMobileAd(adConf, closeCallback = null, isSendAnalytics = true) {
		if (!adConf.hasOwnProperty('id')) return;

		let bgReplay = false;
		if (AppManager.viewer.panorama.userData.controller.videoWallHolder !== undefined) {
			AppManager.viewer.panorama.userData.controller.videoWallHolder.forEach(videoWall => {
				videoWall.video.pause();
				if (!videoWall.muted) bgReplay = true;
			});
		}

		SoundManager.playBGM();

		const context = {
			language: AppManager.currentLanguage,
			iframeId: 'modal-1',
			popupManager: this,
			adConf: adConf,
			adId: adConf.id,
			bgm: true,
		};

		embeddedAd.init($('#modal-1'), context);

		AppManager.toggleControls(false, true);
		AppManager.toggleAd(false);
		$('#modal-1').modal({
			...commonModalSettings,
			closeClass: '',
			closeExisting: true,
			escapeClose: true,
			clickClose: true,
			modalClass: 'modal modal--ad modal--ad-mb',
			blockerClass: 'blocker-contents blocker-contents-' + AppManager.getScreenType(),
			fadeDuration: 500,
			closeText: '<svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="96" height="96" fill="black"/><path d="M25.225 24L72 72M70.775 24L24 72" stroke="white"/></svg>',
		});

		$('#modal-1').one($.modal.BEFORE_CLOSE, (event, modal) => {
			this.cancelLoadingVideo($('#modal-1 .contents-ad-wrapper'));
			AppManager.viewer.skipLoading = true;

			if (AppManager.viewer.panorama.userData.controller.videoWallHolder !== undefined) {
				AppManager.viewer.panorama.userData.controller.videoWallHolder.forEach(videoWall => { videoWall.video.play(); });
			}
		});

		$('#modal-1').one($.modal.AFTER_CLOSE, (event, modal) => {
			event.preventDefault();
			if (bgReplay) SoundManager.muteBGM();
			$(event.currentTarget).empty();
			$(event.currentTarget).removeClass();
			$(event.currentTarget).removeAttr('style');
			SoundManager.replayVoice();
			if (closeCallback) closeCallback();

			AppManager.toggleControls(true, true);
			AppManager.toggleAd(true, adConf);

			AnalyticsManager.sendPanorama(AppManager.viewer.panorama.userData.controller.config.analytics);
		});
	}

	async openPCAd(adConf, closeCallback = null, isSendAnalytics = true) {
		if (!adConf.hasOwnProperty('id')) return;

		let bgReplay = false;
		if (AppManager.viewer.panorama.userData.controller.videoWallHolder !== undefined) {
			AppManager.viewer.panorama.userData.controller.videoWallHolder.forEach(videoWall => {
				videoWall.video.pause();
				if (!videoWall.muted) bgReplay = true;
			});
		}

		SoundManager.playBGM();

		const context = {
			language: AppManager.currentLanguage,
			popupManager: this,
			adConf: adConf,
			adId: adConf.id,
			bgm: true,
		};

		embeddedAd.init($('#modal-1'), context);

		AppManager.toggleControls(false, true);
		AppManager.toggleAd(false);
		$('#modal-1').modal({
			...commonModalSettings,
			closeClass: '',
			closeExisting: true,
			escapeClose: true,
			clickClose: true,
			modalClass: 'modal modal--ad modal--ad-pc',
			blockerClass: 'blocker-contents blocker-contents-' + AppManager.getScreenType(),
			fadeDuration: 500,
			closeText: '<svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="96" height="96" fill="black"/><path d="M25.225 24L72 72M70.775 24L24 72" stroke="white"/></svg>',
		});

		$('#modal-1').one($.modal.BEFORE_CLOSE, (event, modal) => {
			this.cancelLoadingVideo($('#modal-1 .contents-ad-wrapper'));
			AppManager.viewer.skipLoading = true;

			if (AppManager.viewer.panorama.userData.controller.videoWallHolder !== undefined) {
				AppManager.viewer.panorama.userData.controller.videoWallHolder.forEach(videoWall => { videoWall.video.play(); });
			}
		});

		$('#modal-1').one($.modal.AFTER_CLOSE, (event, modal) => {
			event.preventDefault();
			if (bgReplay) SoundManager.muteBGM();
			$(event.currentTarget).empty();
			$(event.currentTarget).removeClass();
			$(event.currentTarget).removeAttr('style');
			SoundManager.replayVoice();
			if (closeCallback) closeCallback();

			AppManager.toggleControls(true, true);
			AppManager.toggleAd(true, adConf);

			AnalyticsManager.sendPanorama(AppManager.viewer.panorama.userData.controller.config.analytics);
		});
	}

	async openMapContents(floorTo, closeCallback = null) {
		const allMapConf = this.getProductViewConfigByType('map_contents');
		if (!allMapConf.hasOwnProperty('mapUI')) return;

		this.disabledRenderUpdate();

		const context = {
			language: AppManager.currentLanguage,
			popupManager: this,
			floorTo: floorTo,
			floorConf: allMapConf.mapUI
		};

		const elementModal = $('#modal-3').html(embeddedMapUI.tag);
		embeddedMapUI.init(elementModal, context);
		if (AppManager.isSmartPhone()) {
			AppManager.toggleControls(false);
			AppManager.toggleHeaderNavi(false, true);
		}

		$('#modal-3').modal({
			...commonModalSettings,
			closeClass: `button-close`,
			closeExisting: true,
			escapeClose: true,
			clickClose: true,
			blockerClass: AppManager.isSmartPhone() ? 'blocker-map-blur' : 'blocker-map-pc-blur',
			modalClass: 'modal ' + (AppManager.isSmartPhone() ? 'modal--map-contents eff-open' : 'modal--map-contents-pc'),
			fadeDuration: 500,
			closeText: '<svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="96" height="96" fill="black"/><path d="M25.225 24L72 72M70.775 24L24 72" stroke="white"/></svg>',
		});

		$('#modal-3').one($.modal.BEFORE_CLOSE, (event, modal) => {
			this.enabledRenderUpdate();
		});

		$('#modal-3').one($.modal.AFTER_CLOSE, (event, modal) => {
			event.preventDefault();
			embeddedMapUI.resetAnimating();
			AppManager.toggleControls(true, true);
			AppManager.toggleHeaderNavi(true, true);
			SoundManager.replayVoice();
			$(event.currentTarget).empty();
			$(event.currentTarget).removeClass();
			$(event.currentTarget).removeAttr('style');
			if (closeCallback) closeCallback();
		});
	}

	loadProductData(productIds) {
		return new Promise((resolve, reject) => {
			const requests = productIds.map(pidColored => {
				const pid = pidColored.split('--')[0];
				return $.getJSON(`${appConfig.productDataPath}/products_${pid}.json`);
			});
			if (requests.length > 1) {
				$.when(...requests)
					.then((...results) => {
						resolve(results.map(res => res[0]));
					})
					.fail((...results) => {
						console.error('product data load error', results);
						reject(results);
					});
			} else if (requests.length === 1) {
				requests[0]
					.done((data) => {
						resolve([data]);
					})
					.fail((jqXHR, textStatus, errorThrown) => {
						reject(errorThrown);
					});
			} else {
				reject('no request found');
			}
		});
	}

	// カテゴリiframe表示
	async openCategoryListView(type, categoryId, contentId, context, backCallback = null) {
		const mode = AppManager.getScreenType();
		if (mode === "mb") {
			this.openCategoryMobileMode(type, categoryId, contentId, context, backCallback);
		} else if (mode === "ip" || mode === "pc") {
			const context = {
				language: AppManager.currentLanguage,
				popupManager: this,
				iframeId: 'modal-2',
				categoryId: categoryId,
				contentId: contentId,
				screenType: type,
			};
			embeddedCategoryList.addCategory(context);
		}
	}

	async openCategoryPCMode(type, categoryId, contentId, productId = null, ref = null, backCallback = null) {
		const context = {
			language: AppManager.currentLanguage,
			popupManager: this,
			iframeId: 'modal-2',
			categoryId: categoryId,
			contentId: contentId,
			productId: productId,
			ref: ref,
			screenType: type,
		};

		embeddedProductsList.init($('#modal-2'), context);

		$('#modal-2').modal({
			...commonModalSettings,
			closeClass: '',
			fadeDuration: 500,
			modalClass: 'modal modal--category-list-pc',
			blockerClass: 'blocker-product blocker-product-pc add-duration',
			closeText: '<svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="96" height="96" fill="black"/><path d="M25.225 24L72 72M70.775 24L24 72" stroke="white"/></svg>',
		});

		$('#modal-2').one($.modal.BEFORE_CLOSE, (event, modal) => {
			event.preventDefault();
		});

		$('#modal-2').one($.modal.AFTER_CLOSE, (event, modal) => {
			event.preventDefault();
			$(event.currentTarget).empty();
			$(event.currentTarget).removeClass();
			$(event.currentTarget).removeAttr('style');
			if (backCallback) backCallback();
		});
	}

	async openCategoryMobileMode(type, categoryId, contentId, options, backCallback = null) {

		const context = {
			language: AppManager.currentLanguage,
			popupManager: this,
			iframeId: 'modal-2',
			categoryId: categoryId,
			contentId: contentId,
			screenType: type,
			typeAdVideo: options.typeAdVideo ? true : false
		};
		const this_analytics = embeddedCategoryList.init($('#modal-2'), context);

		$('#modal-2').modal({
			...commonModalSettings,
			closeClass: '',
			fadeDuration: 500,
			modalClass: 'modal modal--category-list',
			blockerClass: 'blocker-product add-duration',
			closeText: '<svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="96" height="96" fill="black"/><path d="M25.225 24L72 72M70.775 24L24 72" stroke="white"/></svg>',
		});

		$('#modal-2').one($.modal.BEFORE_OPEN, (event, modal) => {
			$('.modal--contents-menu').animate({ opacity: 0 });
		});

		$('#modal-2').one($.modal.BEFORE_CLOSE, (event, modal) => {
			event.preventDefault();
			if (embeddedCategoryList.getForce()) {
				$('.modal--contents-menu').css({ opacity: 1 });
				if (type === "productDetail") AnalyticsManager.sendMenuContents(this_analytics);
			} else {
				$.modal.close();
			}
		});

		$('#modal-2').one($.modal.AFTER_CLOSE, (event, modal) => {
			event.preventDefault();
			$(event.currentTarget).empty();
			$(event.currentTarget).removeClass();
			$(event.currentTarget).removeAttr('style');
			if (backCallback) backCallback();
		});
	}

	notifyClosePosition(positions, calledFrom) {
		const parentModal = $(calledFrom).parent();
		const close = parentModal.length > 0 ? $('a.close-modal', parentModal) : $('.blocker.current .modal a.close-modal');
		if (positions && Object.keys(positions).length > 0) {
			const buttonOffsets = {
				top: -12.5,
				right: -12.5,
			};
			Object.keys(positions).forEach(key => {
				if (buttonOffsets[key] !== undefined) positions[key] += buttonOffsets[key];
			});
			close.css(positions);
		}
		close.removeClass('initial-hidden');
	}


	getProductInfoById(productId) {
		return this.productInfoList.find(info => info.jan_code === productId);
	}


	// 別規格（別サイズ・別カラー）商品情報追加読み込み
	async addProductInfoById(productId) {
		let info = null;
		info = this.productInfoList.find(info => info.jan_code === productId);
		if (info) return info;
		// 追加でJSON取得
		const productData = await this.loadProductData([productId]).catch(error => {
			logger.error(error);
		});
		if (!productData) return null;
		this.productInfoList = this.productInfoList.concat(productData);
		info = this.productInfoList.find(info => info.jan_code === productId);
		return info;
	}


	registerProductViewConfig(type, config) {
		if (this.registerProductViewConfig[type]) return;
		this.registerProductViewConfig[type] = config;
	}


	getProductViewConfigByType(type) {
		return this.registerProductViewConfig[type];
	}


	isModalVisible() {
		return $('[id^="modal-"]:visible').length > 0;
	}


	getLocalizedContent(action) {
		const prefix = AppManager.isSmartPhone() && action[`contentSP`] ? "SP" : "";
		const varName = `content${prefix}`;
		if (action[`${varName}_` + AppManager.currentLanguage]) {
			return action[`${varName}_` + AppManager.currentLanguage];
		}
		return action[varName];
	}

	getLocalizedThumbnail(action) {
		const prefix = AppManager.isSmartPhone() && action[`thumbnailSP`] ? "SP" : "";
		const varName = `thumbnail${prefix}`;
		if (action[`${varName}_` + AppManager.currentLanguage]) {
			return action[`${varName}_` + AppManager.currentLanguage];
		}
		return action[varName];
	}


	getViewHistoryParams() {
		return ViewHistoryManager.buildParams();
	}


	getProductStock(productId) {
		const inventory = SFCCManager.findProductInventory(productId);
		if (inventory && inventory.hasOwnProperty('ats')) {
			logger.info(`[getProductStock]`, productId, inventory.ats);
			return inventory.ats;
		}
		return null;
	}


	notifyClickBooking(infoId, eventLabel) {
		if (infoId === 'inner_beauty_charge') {
			eventLabel = '体験予約はこちら';
		} else if (infoId === 'ultimune_fountain') {
			eventLabel = 'UTLIMUNE FOUNTAINを予約する';
		}
		AnalyticsManager.sendBooking('click', eventLabel);
	}


	notifyOpenBrandSite(productId, action) {
		AnalyticsManager.sendProduct(productId, action);
	}

	openBrandSiteByIframe(url, closeCallback = null) {
		let target;
		let params = {
			lang: AppManager.currentLanguage,
			ifid: 'modal-4-iframe',
		};

		if (addRandom) {
			params.r = new Date().valueOf();
		}
		const qs = querystring.stringify(params);
		$('#modal-4-iframe').attr({
			src: target + (qs.length > 0 ? `?${qs}` : ''),
			width: '100%',
			height: '100%'
		});

		SoundManager.muteVoice();
		const iframeFlass = AppManager.isSmartPhone() ? "iframe-mb" : "iframe-pc";
		$('#modal-4').html(`<iframe id="modal-4-iframe" allow="camera;geolocation;" frameborder="no" src="${url}"></iframe>`);

		$('#modal-4').modal({
			...commonModalSettings,
			closeClass: '',
			fadeDuration: 500,
			modalClass: `modal modal--brand-site ${iframeFlass}`,
			blockerClass: `blocker-iframe add-duration ${AppManager.isSmartPhone() ? "" : "blocker-iframe-pc"}`,
			closeText: '<svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="96" height="96" fill="black"/><path d="M25.225 24L72 72M70.775 24L24 72" stroke="white"/></svg>',
		});

		$('#modal-4').one($.modal.AFTER_CLOSE, (event, modal) => {
			$('#modal-4-iframe').remove();
			$('#modal-4').removeClass();
			SoundManager.replayVoice();
			if (closeCallback) closeCallback();
		});
	}

	enabledRenderUpdate() {
		AppManager.onDeviceOrientationControl();
		// AppManager.viewer.isPlay = true;
		// AppManager.viewer.animate.call(AppManager.viewer);
		// AppManager.viewer.panorama.userData.controller.isPlay = true;
		AppManager.setupCurrentLinkMenu();
	}

	disabledRenderUpdate() {
		// AppManager.viewer.isPlay = false;
		// AppManager.viewer.panorama.userData.controller.isPlay = false;
		AppManager.offDeviceOrientationControl();
	}
}

// expose for child frame
window.PopupManager = new PopupManager();

// export default new PopupManager();
export default window.PopupManager;