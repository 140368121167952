import { logger } from "@common";
import $ from "jquery";

const logTag = "[SFCCManager]";
let accessToken;

const apiEnv =
  location.hostname === "vgfs.shiseido.co.jp"
    ? "prod"
    : location.hostname === "gfs-vr.d1fx.com"
    ? "dev"
    : "unavailable";

const apiConf = {
  prod: {
    auth: "Basic NDNkOWFlMjUtMGM2NS00NDkwLWI2MDEtOGRiZGQ1ZDE4MTA0OjBhdzU0Nzht",
    host: "https://brand.shiseido.co.jp",
  },
  dev: {
    auth: "Basic OGU3OTQ3ZjYtNmVjZC00NzA2LWE0OGItNTA4NWE4YTc2Y2Q4OnI5UVhiWjJu",
    host: "https://test.brand.shiseido.co.jp",
  },
};

class SFCCManager {
  constructor() {
    this.productInventory;
  }

  getToken() {
    if (this.isUnavailable()) return null;
    return new Promise((resolve, reject) => {
      $.ajax({
        type: "POST",
        url: "https://account.demandware.com/dw/oauth2/access_token",
        headers: {
          Authorization: apiConf[apiEnv].auth,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        dataType: "json",
        data: "grant_type=client_credentials",
      })
        .done((data) => {
          logger.info(`${logTag} getToken`, data);
          accessToken = data.access_token;
          resolve("access_token_ready");
        })
        .fail((jqXHR, textStatus, errorThrown) => {
          logger.error(`${logTag} getToken`, jqXHR, textStatus, errorThrown);
          reject(null);
        });
    });
  }

  requestAllStocks() {
    if (this.isUnavailable()) return null;
    return new Promise((resolve, reject) => {
      $.ajax({
        type: "GET",
        url: `${apiConf[apiEnv].host}/s/-/dw/data/v20_4/inventory_lists/inventory_shiseido_global_jp/product_inventory_records?start=0&count=99999&select=(data.(product_id,ats))`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        dataType: "json",
      })
        .done((response) => {
          logger.info(`${logTag} requestAllStocks`, response);
          this.productInventory = response.data;
          resolve("product_stock_ready");
        })
        .fail((jqXHR, textStatus, errorThrown) => {
          logger.error(
            `${logTag} requestAllStocks`,
            jqXHR,
            textStatus,
            errorThrown
          );
          reject(null);
        });
    });
  }

  findProductInventory(productId) {
    if (this.isUnavailable()) return null;
    if (!this.productInventory || this.productInventory.length == 0)
      return null;
    const inventory = this.productInventory.find(
      (element) => element.product_id == productId
    );
    return inventory;
  }

  isUnavailable() {
    return apiEnv === "unavailable";
  }
}

export default new SFCCManager();
