import {
	appConfig,
	LanguageSelect,
	SoundManager,
	AnalyticsManager,
	ViewHistoryManager,
	logger,
	PopupManager
} from "@common";
import $ from 'jquery';
import * as PANOLENS from 'panolens';
import * as THREE from 'three';
import querystring from 'querystring';
import Loading from '../components/Loading';
import Animation from '../components/Animation';
import TutorialScreen from '../components/Tutorial';
import PanoramaController from '../panorama/PanoramaController';
import HeaderNavi from "../components/HeaderNavi";
import TWEEN from '@tweenjs/tween.js';

import { RenderPass } from '~/includedAssets/jsm/shaders/RenderPass';
import { ShaderPass } from '~/includedAssets/jsm/shaders/ShaderPass';
import { HorizontalBlurShader } from '~/includedAssets/jsm/shaders/HorizontalBlurShader';
import { TriangleBlurShader } from '~/includedAssets/jsm/shaders/TriangleBlurShader';
import { EffectComposer } from '~/includedAssets/jsm/shaders/EffectComposer';

class AppManager {
	constructor() {
		this.viewer = undefined;
		this.effectBlur = false;
		this.effectHBlurOff = undefined;
		this.effectHBlurOn = undefined;
		this.effectTBlurOff = undefined;
		this.effectTBlurOn = undefined;
		this.hasGyroFuncton = false;
		this.introViewRecord = {};
		this.showFloorAnimation = false;
		this.isMac = navigator.userAgent.toLowerCase().indexOf('macintosh') > -1;
		this.effectDirection = undefined;
	}

	getFovsFromConfig(config) {
		const fovs = config.map(entry => (this.isSmartPhone() ? entry.spFov : entry.fov)).filter((n => typeof n === 'number'));
		if (!fovs || fovs.length === 0) return {};
		const max = fovs.reduce((a, b) => Math.max(a, b));
		const min = fovs.reduce((a, b) => Math.min(a, b));
		return { max: max, min: min };
	}

	initLanguage() {
		this.languageCode = LanguageSelect.detectLanguage();
		LanguageSelect.determineLanguage(this.languageCode);
		//AnalyticsManager.sendLanguage(this.languageCode, 'initialDetect');
	}


	async preinit(panoramaConfig) {
		this.initLanguage();
		this.viewer = new PANOLENS.Viewer({
			controlBar: appConfig.debug.visiblePanoramaId,
			controlButtons: [],
			// viewIndicator: true,
			// indicatorSize: 100,
			// enableReticle: true, // trueにするとレティクルによる視点操作になる
			// autoReticleSelect: false,
			output: appConfig.debug.outputPosition ? 'console' : 'none',
			autoHideInfospot: false,
			// horizontalView: true,
			autoRotateSpeed: appConfig.autoRotateSpeed,
			suppressMomentum: true,
		});
		//    this.viewer.container.style.backgroundColor = "#fff";
		const fovs = this.getFovsFromConfig(panoramaConfig);
		this.viewer.OrbitControls.minFov = appConfig.minFov;
		this.viewer.OrbitControls.maxFov = fovs.max ? fovs.max : appConfig.maxFov;

		this.panoramaConfig = panoramaConfig;

		// 初期パノラマAdd
		const panoId =
			this.hasCounselingParams && ViewHistoryManager.getLastPanorama()
				? ViewHistoryManager.getLastPanorama()
				: appConfig.initialPanoramaId;
		const initialConf = this.getPanoramaConfig(panoId) || this.panoramaConfig[0];

		this.currentPanoramaController = new PanoramaController(this.viewer, initialConf, true);
		this.currentPanoramaController.showAsWelcomeScreen();
		this.viewer.update();
		this.viewer.render();
		this.viewer.clearAllCache();
		this.viewer.unregisterReticleEvent();
		this.viewer.updateCallbacks.forEach((fx) => {
			this.viewer.removeUpdateCallback(fx);
		});
		this.viewer.updateCallbacks = [];

		const effectHBlur = new ShaderPass(HorizontalBlurShader);
		const effectTBlur = new ShaderPass(TriangleBlurShader, 'texture');
		effectHBlur.uniforms.h.value = 0;
		effectTBlur.uniforms.delta.value = new THREE.Vector2(0, 0);
		const renderPass = new RenderPass(this.viewer.scene, this.viewer.camera);
		const composerScene = new EffectComposer(this.viewer.renderer);
		composerScene.addPass(renderPass);
		composerScene.addPass(effectHBlur);
		composerScene.addPass(effectTBlur);

		const animating = () => {
			if (this.effectBlur) {
				composerScene.render();
			}
			requestAnimationFrame(animating);
		};
		animating();

		const fixPicelRatio = window.devicePixelRatio > 2 ? 2 : window.devicePixelRatio;
		this.effectHBlurOn = new TWEEN.Tween(effectHBlur.uniforms.h)
			.to({ value: (1 / window.innerHeight) * fixPicelRatio }, 800);

		this.effectTBlurOn = new TWEEN.Tween(effectTBlur.uniforms.delta)
			.to({ value: new THREE.Vector2(0.01, 0.01).multiplyScalar(fixPicelRatio) }, 1000);

		this.effectHBlurOff = new TWEEN.Tween(effectHBlur.uniforms.h)
			.to({ value: 0 }, 800)
			.onStart(function () {
				if (this.effectHBlurOn) this.effectHBlurOn.stop();
				if (this.effectTBlurOn) this.effectTBlurOn.stop();
			});

		this.effectTBlurOff = new TWEEN.Tween(effectTBlur.uniforms.delta)
			.to({ value: new THREE.Vector2(0, 0) }, 1000);

		this.preLoadImages();
	}

	preLoadImages() {
		let imagesPath = [];
		if (this.isSmartPhone()) {
			imagesPath = [
				"assets/images/contents_menu/1f_digiskin.webp",
				"assets/images/contents_menu/1f_make-base.webp",
				"assets/images/contents_menu/1f_make-point.webp",
				"assets/images/contents_menu/1f_s-connect.webp",
				"assets/images/contents_menu/1f_utm-fountain.webp",
				"assets/images/contents_menu/1f_utm-to-go.webp",
				"assets/images/contents_menu/1f_utm-zone.webp",
				"assets/images/contents_menu/2f_beauty-alive.webp",
				"assets/images/contents_menu/2f_makeup-zone.webp",
				"assets/images/contents_menu/2f_make-your-mark.webp",
				"assets/images/contents_menu/2f_premium-zone.webp",
				"assets/images/contents_menu/2f_skincare-zone.webp",
				"assets/images/contents_menu/2f_wrap-it-up.webp",
				"assets/images/contents_menu/b1f_inner-beauty.webp",
				"assets/images/ad/bc_1.jpg",
				"assets/images/ad/1f_entrance.webp"
			];
		} else {
			imagesPath = [
				"assets/images/buttons/prev_h.svg",
				"assets/images/buttons/prev_h.svg",
				"assets/images/buttons/prev_h.svg",
				"assets/images/buttons/next_h.svg",
				"assets/images/buttons/left_hover.svg",
				"assets/images/buttons/right_hover.svg",
				"assets/images/buttons/btn_left_h.svg",
				"assets/images/buttons/btn_right_h.svg",
				"assets/images/buttons/close_h.svg",
				"assets/images/pc_floor-map/1f_map-bg.jpg",
				"assets/images/pc_floor-map/2f_map-bg.jpg",
				"assets/images/pc_floor-map/b1f_map-bg.jpg",
				"assets/images/ad/bc_1.jpg",
			];
		}

		imagesPath.forEach((path) => {
			const elementTag = `<link rel="preload" href="${path}" as="image">`;
			$("head").append(elementTag);
		});
	}

	init() {
		this.configWidget();
		this.setCurrentVR();
		this.setControlsEvent();
		this.setChangeVRNav();
		this.setAd();
		this.createClickEffect();

		$("#load-contents").addClass(this.getScreenType() !== 'mb' ? "load-pc" : "load-mb");
		$(".controller").addClass(this.getScreenType() !== 'pc' ? "controller-mb" : "controller-pc");
		this.currentPanoramaController.startAsWelcomeScreen(this.hasCounselingParams);
	}


	configWidget() {
		if (!appConfig.debug.visiblePanoramaId) return;
		// panorama name
		this.textWidget = this.viewer.appendControlItem({
			element: document.createElement('span'),
			style: {
				color: '#fff',
				width: 'auto',
				marginRight: '1em',
			},
		});
	}

	setCurrentVR() {
		$('#currentVR-ele div.text').html(this.linkLabel);
	}

	setControlsEvent() {
		if (this.getScreenType() !== 'mb') {
			$('.other-toggle').hide();
		}

		$('.sound-toggle').off("click").on("click", (e) => {
			e.preventDefault();
			$('.sound-toggle').addClass('disabled');
			this.switchVolume();
			AnalyticsManager.sendController('SOUND', SoundManager.enabled);
		});

		$('.gyro-toggle').off("click").on("click", (e) => {
			e.preventDefault();
			$('.gyro-toggle').addClass('disabled');
			this.switchControl();
		});
	}

	setChangeVRNav() {
		$('#changeVR-nav').addClass(this.isSmartPhone() ? "changeVR-mb" : "changeVR-pc");

		$('#changeVR-nav div.next, #changeVR-nav div.prev').off('click').on('click', event => {
			const buttonType = (event.currentTarget.className && event.currentTarget.className === "next") ? "Right" : "Left";
			const lowButtonType = buttonType.toLocaleLowerCase();
			const navi = this.viewer.panorama.userData.controller.config.navi;
			if (!navi || !navi[lowButtonType]) return;
			this.setLoading(true);

			AnalyticsManager.sendClickNavi("Move Button", buttonType);
			this.gotoPanorama(navi[lowButtonType].toString(), undefined, undefined, lowButtonType);
		});
	}

	setAd() {
		const adConf = this.viewer.panorama.userData.controller.config.ad;
		if (!adConf || !adConf.id) return;
		$('#ad-area').addClass(this.isSmartPhone() ? "ad-area-mb" : "ad-area-pc");
		$('#ad-area').attr('data-bname', adConf.description_ja.replace(/(<([^>]+)>)/gi, ''));

		$('#ad-area .close').off('click').on('click', event => {
			event.preventDefault();
			$('#ad-area').fadeOut(() => { $('#ad-area').delay(1000).remove(); });
			AnalyticsManager.sendClickAdEvent("Close", adConf.description_ja.replace(/(<([^>]+)>)/gi, ''), "Banner");
		});
		$('#ad-area .contents').off('click').on('click', event => {
			event.preventDefault();
			const adConf = this.viewer.panorama.userData.controller.config.ad;
			if (!adConf || !adConf.id) return;
			if (this.isSmartPhone()) {
				PopupManager.openMobileAd(adConf);
			} else {
				PopupManager.openPCAd(adConf);
			}

			AnalyticsManager.sendClickAdEvent("Click", adConf.description_ja.replace(/(<([^>]+)>)/gi, ''), "Banner");
		});
	}

	gotoPanorama(panoTo, isSendAnalytics, setTimeoutSec, effectDirection, callback = null) {
		if (isSendAnalytics === undefined) isSendAnalytics = true;
		if (setTimeoutSec === undefined) setTimeoutSec = 500;
		if (effectDirection === undefined) effectDirection = 'right';

		this.effectDirection = effectDirection;

		if (typeof panoTo === 'string') {
			panoTo = this.getPanoramaById(panoTo);
		}
		if (!panoTo) return;
		const currentPanoId = this.viewer.panorama.userData.controller.config.id;
		if (isSendAnalytics) AnalyticsManager.sendPanorama(panoTo.config.analytics);
		if (currentPanoId != panoTo.config.id) {

			const currentCtl = this.viewer.panorama.userData.controller;
			currentCtl.hideObject();

			setTimeout(() => {
				if (!this.isPanoramaAdded(panoTo.panorama)) {
					this.viewer.add(panoTo.panorama);
				}
				if (!appConfig.playIntroOnEnter) {
					// 自動再生のためユーザアクションのタイミングでIntroを流す
					panoTo.handleIntro();
				}
				document.getElementsByTagName("body")[0].classList.add("animation");
				this.viewer.setPanorama(panoTo.panorama);

				if (callback) callback();
			}, setTimeoutSec);
		}
	}


	isPanoramaAdded(pano) {
		const scene = this.viewer.getScene();
		return scene.children.includes(pano);
	}

	setBeforeFloor(floor) {
		this.beforeFloor = floor;
	}

	getBeforeFloor() {
		return this.beforeFloor;
	}

	setCurrentFloor(floor) {
		if (this.currentFloor === floor) return;
		SoundManager.setupBGM(floor);
		this.currentFloor = floor;
	}

	getCurrentFloor() {
		return this.currentFloor;
	}

	showInTextWidget(text) {
		if (this.textWidget) this.textWidget.textContent = text;
	}

	// return this.config.image;
	setPanoramaType(meta) {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;
		logger.info(`User agent : ` + userAgent);
		if ((/iPhone/.test(userAgent)) || (/iPad/.test(userAgent))) {
			if (meta.hasOwnProperty('device4KList')) {
				const screenWidth = window.screen.width;
				const screenHeight = window.screen.height;
				const deviceCheck = meta.device4KList.find(device => (parseInt(device.width) === parseInt(screenWidth) && parseInt(device.height) === parseInt(screenHeight)));
				logger.info(`iOS device. screenWidth : + ${screenWidth}. screenHeight : + ${screenHeight}.`);
				if (deviceCheck) {
					logger.info(`Returned 4k panorama images.`);
					return this.panoramaType = "4k";
				}
			}
			logger.info(`iOS device. Returned 6k panorama images.`);
			return this.panoramaType = "6k";
		}

		if (/android/i.test(userAgent)) {
			logger.info(`Android device. Returned 4k panorama images.`);
			return this.panoramaType = "4k";
		}
		logger.info(`PC device. Returned 8k panorama images.`);
		return this.panoramaType = "8k";
	}

	getPanoramaType() {
		return this.panoramaType;
	}

	getPanoramaById(panoId) {
		let panoramaConfig = this.getPanoramaConfig(panoId);
		return new PanoramaController(this.viewer, panoramaConfig);
	}

	getPanoramaConfig(panoId) {
		return this.panoramaConfig.find(conf => conf.id === panoId);
	}

	toggleFloorNav(bool) {
		if (bool) {
			$('#floor-nav').show();
		} else {
			$('#floor-nav').hide();
		}
	}

	toggleHeaderNavi(bool, fadeAnimation) {
		if (fadeAnimation) {
			if (bool) {
				$('#header-container').fadeIn();
			} else {
				$('#header-container').fadeOut();
			}
		} else {
			if (bool) {
				$('#header-container').show();
			} else {
				$('#header-container').hide();
			}
		}
	}

	toggleControls(bool, fadeAnimation) {
		if (fadeAnimation) {
			if (bool) {
				$('.sound-toggle').delay(500).fadeIn();
				$('.gyro-toggle').delay(500).fadeIn();
				//$('#footer-container').fadeIn();
			} else {
				$('.sound-toggle').fadeOut();
				$('.gyro-toggle').fadeOut();
				//$('#footer-container').fadeOut();
			}
		} else {
			if (bool) {
				$('.sound-toggle').show();
				$('.gyro-toggle').show();
				//$('#footer-container').show();
			} else {
				$('.sound-toggle').hide();
				$('.gyro-toggle').hide();
				//$('#footer-container').hide();
			}
		}
	}

	toggleChangeVRNav(bool, navConf = {}) {
		if (bool) {
			$('#changeVR-nav').fadeIn();
			if (navConf.left === undefined) {
				$('#changeVR-nav div.prev').fadeOut();
			} else {
				if (!this.isSmartPhone()) {
					const leftDetail = this.getPanoramaConfig(navConf.left);
					$('#changeVR-nav div.prev .prev-label').html(this.getlabel(leftDetail));
					$('#changeVR-nav div.prev').delay(500).fadeIn();
				} else {
					$('#changeVR-nav div.prev').delay(500).fadeIn();
				}
			}
			if (navConf.right === undefined) {
				$('#changeVR-nav div.next').fadeOut();
			} else {
				if (!this.isSmartPhone()) {
					const rightDetail = this.getPanoramaConfig(navConf.right);
					$('#changeVR-nav div.next .next-label').html(this.getlabel(rightDetail));
					$('#changeVR-nav div.next').delay(500).fadeIn();
				} else {
					$('#changeVR-nav div.next').delay(500).fadeIn();
				}
			}
		} else {
			$('#changeVR-nav').fadeOut();
			$('#changeVR-nav div.prev .prev-label,#changeVR-nav div.next .next-label').html("");
			$('#changeVR-nav div.prev,#changeVR-nav div.next').fadeIn();
		}
	}

	toggleCurrentVR(bool, labels = {}) {
		if (bool) {
			if (this.isSmartPhone()) {
				$('#currentVR-ele').delay(500).fadeIn();
			}
			if (labels === undefined) {
				$('#currentVR-ele').fadeOut();
			} else {
				$('#currentVR-ele div.text').html(labels);
				if (labels.match(/<br \/>/)) {
					$('#currentVR-ele div.text').addClass('two-line');
				} else {
					$('#currentVR-ele div.text').removeClass('two-line');
				}
			}
		} else {
			$('#currentVR-ele').fadeOut();
		}
	}

	toggleAd(bool, adConf = {}) {
		if (bool) {
			$('#ad-area').delay(500).fadeIn();

			$('#ad-area div.description').html(`${this.getlabel(adConf, 'description')}`);
			$('#ad-area div.banner').html(`<img src="${this.getlabel(adConf, 'image')}" />`);
		} else {
			$('#ad-area').fadeOut();
		}
	}

	toggleLinkMenu(bool) {
		if (this.isSmartPhone()) return;
		if (bool) {
			$('#slideMenu').delay(500).fadeIn();
		} else {
			$('#slideMenu').fadeOut();
		}
	}

	hasViewedIntro(panoId) {
		return this.introViewRecord[panoId] !== undefined;
	}


	markViewedIntro(panoId) {
		this.introViewRecord[panoId] = true;
	}


	onLanguageSelected(lang) {
		if (lang === this.currentLanguage) return false;
		this.languageCode = lang;
		this.viewer && this.viewer.dispatchEventToChildren({ type: 'change-language' });
		AnalyticsManager.sendLanguage(this.labelLang, 'Menu', 'event-gtm1');
		return true;
	}


	createClickEffect() {
		const tag = `<div id="click-effect"></div>`;
		this.clickEffect = $(tag.trim()).appendTo('body');
	}


	playClickEffect(event) {
		const fx = $('<div>', { class: 'fx' }).appendTo(this.clickEffect);
		this.clickEffect.css({
			top: event.pageY,
			left: event.pageX,
		});
		fx.delay(200).queue(function () {
			$(this).remove();
		});
	}

	setMetaConfig(meta) {
		this.metaConfig = meta;
	}

	setLoading(bool, fadeOutTime) {
		const fadeOutTimeVal = fadeOutTime !== undefined ? fadeOutTime : "slow";
		if (this.loading === undefined) this.loading = Loading.add();
		if (bool && !this.showFloorAnimation) this.loading.show();
		else this.loading.hide(fadeOutTimeVal);
	}

	switchVolume() {
		SoundManager.enabled = !SoundManager.enabled;

		if (SoundManager.enabled) {
			SoundManager.paused = false;
			$('.sound-toggle .sound-on').removeClass('text-decoration');
			$('.sound-toggle .sound-off').addClass('text-decoration');
			$('.sound-toggle .sound').children('img').attr('src', "assets/images/controls/sound_on.gif");
		} else {
			$('.sound-toggle .sound-on').addClass('text-decoration');
			$('.sound-toggle .sound-off').removeClass('text-decoration');
			$('.sound-toggle .sound').children('img').attr('src', "assets/images/controls/sound_off.gif");
		}

		if (SoundManager.enabled && !PopupManager.isModalVisible()) {
			SoundManager.replayVoice();
		} else if (SoundManager.enabled && PopupManager.isModalVisible()) {
			let bReplayBGM = true;
			$('[id^="modal-"]:visible').find('video').each((index, element) => {
				let bgm = element.dataset.bgm == null || element.dataset.bgm == "" || element.dataset.bgm == undefined ? 'false' : element.dataset.bgm;
				if (bgm === "false") bReplayBGM = false;
			});
			if (bReplayBGM) SoundManager.replayVoice();
		} else {
			SoundManager.muteVoice();
		}
		document.querySelector('.sound-toggle').classList.remove('disabled');
		if (PopupManager.isModalVisible()) {
			$('[id^="modal-"]:visible').find('video').each((index, element) => {
				if (element.muted === SoundManager.enabled) element.muted = !SoundManager.enabled;
			});
		}
	}

	switchControl(isChange = true, isAnalytics = true) {

		if (isChange) this.viewer.control.changeDeviceOrientation();

		if (this.viewer.control.enabledDeviceOrientation) {
			$('.gyro-toggle .gyro-on').removeClass('text-decoration');
			$('.gyro-toggle .gyro-off').addClass('text-decoration');

		} else {
			$('.gyro-toggle .gyro-on').addClass('text-decoration');
			$('.gyro-toggle .gyro-off').removeClass('text-decoration');
		}

		if (this.getScreenType() === 'mb') {
			$('.panolens-container').off("mousedown touchstart mouseup touchend");
		} else {
			$('.panolens-container').on("mousedown touchstart", (e) => {
				e.preventDefault();
				clearTimeout(this.overlayTime);
				if ($('.overlay').is(':visible')) {
					$('.overlay').animate({ opacity: 0 }, { complete: () => { $('.overlay').hide(); }, duration: 500 });
					this.viewer.panorama.toggleInfospotVisibility(false, 200);
				}
			});

			$('.panolens-container').on("mouseup touchend", (e) => {
				e.preventDefault();
				this.overlayTime = setTimeout(() => {
					$('.overlay').show();
					$('.overlay').animate({ opacity: 1 }, {
						complete: () => {
						}, duration: 500
					});
					this.viewer.panorama.toggleInfospotVisibility(true, 200);

				}, 800);
			});
		}
		$('.gyro-toggle').removeClass('disabled');
		if (isAnalytics) AnalyticsManager.sendController('GYRO', this.viewer.control.enabledDeviceOrientation);
	}

	updateControlsLabel(lang) {
		const gyroLang = this.metaConfig.gyro;
		const soundLang = this.metaConfig.sound;
		const prefix = lang !== 'en' ? '_' + lang : '';
		let gyro = gyroLang.title['label' + prefix];
		let gyroOn = gyroLang.btnOn['label' + prefix];
		let gyroOff = gyroLang.btnOff['label' + prefix];
		let sound = soundLang.title['label' + prefix];
		let soundOn = soundLang.btnOn['label' + prefix];
		let soundOff = soundLang.btnOff['label' + prefix];

		$('.sound-toggle .sound-on').text(soundOn);
		$('.sound-toggle .sound-off').text(soundOff);
		$('.gyro-toggle .gyro-on').text(gyroOn);
		$('.gyro-toggle .gyro-off').text(gyroOff);
		$('.sound-toggle .sound-text').text(sound);
		$('.gyro-toggle .gyro-text').text(gyro);
		HeaderNavi.show(this.currentFloor);

		const currentPano = this.viewer.panorama.userData.controller.config;
		const info = this.getlabel(currentPano, 'infoLabel');

		$('#ad-area .description').html(this.getlabel(currentPano));
		if (this.viewer.panorama.userData.controller.config.ad !== undefined) {
			const adConf = this.viewer.panorama.userData.controller.config.ad;
			$('#ad-area div.description').html(`${this.getlabel(adConf, 'description')}`);
			$('#ad-area div.banner').html(`<img src="${this.getlabel(adConf, 'image')}" />`);
		}

		if (this.getScreenType() === 'mb') return;

		if (this.viewer.panorama.userData.controller.config.navi !== undefined) {
			const navConf = this.viewer.panorama.userData.controller.config.navi;

			const leftDetail = this.getPanoramaConfig(navConf.left);
			const rightDetail = this.getPanoramaConfig(navConf.right);

			$('#changeVR-nav div.prev .prev-label').html(this.getlabel(leftDetail));
			$('#changeVR-nav div.next .next-label').html(this.getlabel(rightDetail));
		}

		$('#slideMenu .info .text .label').html(this.getlabel(currentPano));
		$('#slideMenu .info .text .description').html(info ? info : '');

		$("#slideMenu .item-lists .item").each((index, element) => {
			let panoId = ($(element).data('pano')).toString();
			let config = this.getPanoramaConfig(panoId);
			$(element).find('.title').html(this.getlabel(config));
		});

	}

	onDeviceOrientationControl() {
		this.viewer.control.onDeviceOrientation();
	}

	setHasGyroFuncton(gyro) {
		this.hasGyroFuncton = gyro;
	}

	getHasGyroFuncton() {
		return this.hasGyroFuncton;
	}

	enabledDeviceControl() {
		const st = this.getScreenType();
		if (st === 'pc') {
			let isMac = this.isMac !== undefined ? this.isMac : navigator.userAgent.toLowerCase().indexOf('macintosh') > -1;
			if (isMac) {
				$('.gyro-toggle').addClass('disabled').off().remove();
				this.setHasGyroFuncton(false);
				this.switchControl(false, false);
			} else {
				window.addEventListener("deviceorientation", (event) => {
					this.setHasGyroFuncton(((event.alpha === null && event.beta === null && event.gamma === null)) || isMac ? false : true);
					loadEdit(event, isMac).then(this.switchControl($('.gyro-toggle') ? false : true, false));
				}, false);

				async function loadEdit(event, isMac) {
					if ((event.alpha === null && event.beta === null && event.gamma === null) || isMac) {
						$('.gyro-toggle').addClass('disabled').off().remove();
					}
				}
			}
		} else {
			const userAgent = navigator.userAgent || navigator.vendor || window.opera;
			if (/android/i.test(userAgent)) this.setHasGyroFuncton(true);
			this.switchControl(true, false);
		}
	}

	setupCurrentLinkMenu(updateScroll = true) {
		if (this.isSmartPhone()) return;
		const currentPano = this.viewer.panorama.userData.controller.config;
		const info = this.getlabel(currentPano, 'infoLabel');

		const element = document.querySelector(`#slideMenu .item[data-pano="${currentPano.id}"]`);
		const scrollContainer = document.querySelector('.item-lists');
		const scrollWidth = scrollContainer.scrollWidth - scrollContainer.clientWidth;

		if (updateScroll && element) {
			const haft_client = document.querySelector('#slideMenu').clientWidth / 2;
			const haft_item = element.clientWidth / 2;
			const offsetLeft = element.offsetLeft;
			const scrollLeft = offsetLeft - (haft_client - haft_item);
			scrollContainer.scrollLeft = scrollLeft;

			if (scrollLeft >= scrollWidth) {
				$('#slideMenu .pager .next,#slideMenu .pager .next-pc').fadeOut();
			} else {
				$('#slideMenu .pager .next,#slideMenu .pager .next-pc').fadeIn();
			}
			if (scrollLeft <= 0) {
				$('#slideMenu .pager .prev,#slideMenu .pager .prev-pc').fadeOut();
			} else {
				$('#slideMenu .pager .prev,#slideMenu .pager .prev-pc').fadeIn();
			}
		}

		$(`#slideMenu .item[data-pano="${currentPano.id}"]`).addClass('active');
		$('#slideMenu .info .text .label').html(this.getlabel(currentPano));
		$('#slideMenu .info .text .description').html(info ? info : '');
		$('#slideMenu .info .text').animate({ 'opacity': 1, 'margin-left': '0' }, { complete: () => { }, duration: 1000 });
	}

	initLookAt() {
		const conf = this.viewer.panorama.userData.controller.config;
		const initialLookAt = this.isSmartPhone() && conf.initialLookAtSP !== undefined ? conf.initialLookAtSP : conf.initialLookAt;
		const initPos = new THREE.Vector3().fromArray(initialLookAt);
		this.viewer.tweenControlCenter(initPos, 2000, TWEEN.Easing.Quartic.Out);
	}

	offDeviceOrientationControl() {
		this.viewer.control.OffDeviceOrientation();
	}

	isSmartPhone() {
		return (/iphone|android/).test(navigator.userAgent.toLowerCase());
	}

	getScreenType() {
		const ua = window.navigator.userAgent;
		if (ua.indexOf('Android') > -1) return "mb";
		if (ua.indexOf('iPhone') > -1) return "mb";
		if (ua.indexOf('iPad') > -1) return "ip";
		if (ua.indexOf('Macintosh') > -1) {
			try {
				document.createEvent("TouchEvent");
				return "ip";
			} catch (e) { }
		}
		return "pc";
	}

	setFloorAnimation(floorTo) {
		if (floorTo !== undefined && floorTo !== this.getCurrentFloor()) {
			AnalyticsManager.sendFloor(floorTo);
			this.showFloorAnimation = true;
			Animation.showFloorLogo(floorTo);
		}
	}

	setWelcomeAnimation() {
		this.showFloorAnimation = true;
		if (this.getCookie(`vgfs_accessed`) === "true") this.setCookie(`vgfs_accessed`, true, 30);
		Animation.showWecomeFloor();
	}

	setCookie(key, value, expiry) {
		var expires = new Date();
		expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
		document.cookie = key + '=' + value + ';expires=' + expires.toUTCString() + "; SameSite=none; Secure";
	}

	getCookie(key) {
		var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
		return keyValue ? keyValue[2] : null;
	}

	eraseCookie(key) {
		var expires = new Date();
		expires.setTime(expires.getTime() + (500));
		document.cookie = key + '=-1;expires=' + expires.toUTCString() + "; SameSite=none; Secure";
	}

	addTutorial() {
		if (this.getCookie(`vgfs_accessed`) !== "true") TutorialScreen.add();
	}

	getlabel(obj, name = 'linkLabel') {
		if (obj[name + '_' + this.currentLanguage]) {
			return obj[name + '_' + this.currentLanguage];
		}
		return obj[name];
	}

	getObject(event, mesh, callback = () => { }) {
		var pointer = new THREE.Vector2();
		var rect = this.viewer.renderer.domElement.getBoundingClientRect();
		pointer.x = ((event.clientX - rect.left) / (rect.width - rect.left)) * 2 - 1;
		pointer.y = - ((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
		this.viewer.raycaster.setFromCamera(pointer, this.viewer.camera);
		var intersects = this.viewer.raycaster.intersectObject(mesh, true);
		if (intersects.length > 0) {
			callback();
			return intersects[0].object;
		}
	};

	get isInitialized() {
		return this.viewer !== undefined;
	}


	get currentLanguage() {
		return this.languageCode;
	}


	get renderer() {
		return this.viewer.getRenderer();
	}


	get hasCounselingParams() {
		const params = querystring.parse(location.search.substring(1));
		return (
			params.hasOwnProperty("insidechatlink") ||
			params.hasOwnProperty("insidechatlinktype") ||
			params.hasOwnProperty("insideschedule")
		);
	}

	get linkLabel() {
		if (this.viewer.panorama.userData.controller['linkLabel_' + this.currentLanguage]) {
			return this.viewer.panorama.userData.controller['linkLabel_' + this.currentLanguage];
		}
		return this.viewer.panorama.userData.controller.linkLabel;
	}

	get labelLang() {
		const language = this.metaConfig.language.langBtns;
		if (language['label_' + this.currentLanguage]) {
			return language['label_' + this.currentLanguage];
		}
		return language.label;
	}
}

export default new AppManager();