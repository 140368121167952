import appConfig from "./appConfig";
import PopupManager from "../common/PopupManager";
import $ from 'jquery';
import { assetDir } from "./paths";
import AppManager from "../common/AppManager";

export default function loadExternalConfigs() {
  return new Promise((resolve, reject) => {
    const params = appConfig.panoramaConfigNoCache ? { r: new Date().valueOf() } : {};
    $.when(
      $.getJSON(`${assetDir}/config/panorama.json`, params),
      $.getJSON(`${assetDir}/config/contents.json`, params),
      $.getJSON(`${assetDir}/config/map.json`, params),
      $.getJSON(`${assetDir}/config/meta.json`, params),
    )
      .done((panorama, contents, map, meta) => {
        PopupManager.registerProductViewConfig('contents', contents[0]);
        PopupManager.registerProductViewConfig('map_contents', map[0]);
        resolve(panorama[0]);
        AppManager.setMetaConfig(meta[0]);
        AppManager.setPanoramaType(meta[0]);
      })
      .fail((...results) => {
        console.error('config load error', results);
        reject(results);
      });
  });
}
