import 'ress';
import '../styles/index.scss';

import { AppManager, appConfig, AnalyticsManager, ViewHistoryManager, SoundManager, SFCCManager } from '@common';
import loadExternalConfigs from './config/loadExternalConfigs';
import WelcomeScreen from './components/WelcomeScreen';
import qrCodeScreen from './components/QrCodeScreen';

const initApp = async () => {
	const panoramaJson = await loadExternalConfigs();

	let url = new URL(window.location.href);
	let params = url.searchParams;
	const isProd = process.env.NODE_ENV === 'production';
	const isDebug = params.get('mode') && params.get('mode').toLocaleLowerCase() === 'debug_pc';
	if (AppManager.isSmartPhone() || (!isProd && isDebug)) {
		if (!SFCCManager.isUnavailable()) {
			await SFCCManager.getToken();
			await SFCCManager.requestAllStocks();
		}

		AppManager.initLanguage();

		if (appConfig.welcomeScreenEnabled) {
			let welcome = WelcomeScreen.add();
			ViewHistoryManager.clearStorage();
			AnalyticsManager.sendWelcome();
			AppManager.setLoading(false, 2000);

			await welcome.waitForTap();
		}
		await AppManager.preinit(panoramaJson);
		SoundManager.init();
		AppManager.init();
		if (appConfig.welcomeScreenEnabled) {
			AppManager.setWelcomeAnimation();
		} else {
			AppManager.enabledDeviceControl();
			setTimeout(() => document.getElementById("loading-container").classList.add("bl-opacity"), 3000);
		}
	} else {
		AppManager.initLanguage();
		AppManager.setLoading(false, 2000);
		AnalyticsManager.sendWelcomePC();
		qrCodeScreen.add();
	}
};
initApp();