import { PopupManager, SoundManager } from '@common';
import * as THREE from 'three';
import BaseInteractiveSpot from './BaseInteractiveSpot';

export default class InfoWallScene extends BaseInteractiveSpot {
  constructor(panorama, videoConfig) {
    super(panorama);
    this.videoConfig = videoConfig;
    const muted = this.videoConfig.hasOwnProperty('muted') ? this.videoConfig.muted : true;

    let videoElement = document.createElement('video');
    let sourceMedia = document.createElement("source");
    videoElement.setAttribute('crossOrigin', 'anonymous');
    videoElement.setAttribute('webkit-playsinline', '');
    videoElement.setAttribute('playsinline', '');
    videoElement.autoplay = true;
    videoElement.controls = true;
    videoElement.muted = SoundManager.enabled ? muted : true;
    videoElement.loop = this.videoConfig.hasOwnProperty('loop') ? this.videoConfig.loop : true;

    sourceMedia.type = "video/mp4";
    sourceMedia.src = PopupManager.getLocalizedContent(videoConfig);
    videoElement.appendChild(sourceMedia);

    videoElement.play();

    const texture = new THREE.VideoTexture(videoElement);
    let movie = new THREE.Mesh(
      new THREE.BoxGeometry(this.videoConfig.area.width, this.videoConfig.area.height, 0),
      new THREE.MeshBasicMaterial({ transparent: true, map: texture })
    );
    movie.position.fromArray(this.videoConfig.positionAt).multiply(new THREE.Vector3(-1, 1, 1));
    movie.video = videoElement;
    movie.muted = muted;
    this.videoConfig.hasOwnProperty('rotate') ? movie.rotateY(THREE.Math.degToRad(parseInt(this.videoConfig.rotate))) : '';

    this.panorama.add(movie);

    window.document.addEventListener('visibilitychange', function () {
      if (window.document.visibilityState === 'visible') {
        if (!PopupManager.isModalVisible()) videoElement.play();
      } else {
        videoElement.pause();
      }
    }, false);

    return movie;
  }
}