import { appConfig, AppManager, CacheManager, logger, PopupManager, AnalyticsManager, ViewHistoryManager, SoundManager } from '@common';
import $ from 'jquery';
import * as PANOLENS from 'panolens';
import * as THREE from 'three';
import InfoMoreViewSpot from './InfoMoreViewSpot';
import InfoLinkSpot from './InfoLinkSpot';
import HeaderNavi from '../components/HeaderNavi';
import InfoWallScene from "./InfoWallScene";
import LinksMenu from '../components/LinksMenu';


export default class PanoramaController {
  constructor(viewer, config, asWelcomeScreen = false) {
    this.viewer = viewer;
    this.config = config;
    this.asWelcomeScreen = asWelcomeScreen;

    if (AppManager.viewer.panorama) {
        this.panorama = new PANOLENS.ImagePanorama(this.panoramaImage, AppManager.viewer.panorama.geometry);
    } else {
        this.panorama = new PANOLENS.ImagePanorama(this.panoramaImage);
    }
    this.panorama.userData.controller = this;
    this.panorama.name = config.id;

    // configure events
    this.panorama.addEventListener('hover', this.onHover.bind(this));

    this.panorama.addEventListener('progress', this.onProgress.bind(this));
    // this.panorama.addEventListener('load', this.onLoad.bind(this));
    this.panorama.addEventListener('enter', this.onEnter.bind(this));
    this.panorama.addEventListener('enter-fade-start', this.onEnterFadeStart.bind(this));
    this.panorama.addEventListener('enter-fade-complete', this.onEnterFadeComplete.bind(this));
    this.panorama.addEventListener('leave', this.onLeave.bind(this));
    this.panorama.addEventListener('leave-complete', this.onLeaveComplete.bind(this));
  }


  load() {
    const deferred = new $.Deferred();
    const onLoad = (event) => {
      logger.info(`${this.panoramaId} | onLoad`, event);
      this.panorama.removeEventListener('load', onLoad);
      deferred.resolve();
    };
    this.panorama.addEventListener('load', onLoad);
    this.panorama.load();
    return deferred.promise();
  }


  showAsWelcomeScreen() {
    this.viewer.clearAllCache();
    this.viewer.render();
    this.viewer.add(this.panorama);
  }


  startAsWelcomeScreen(reloadedForCounseling = false) {
    if (AppManager.getCookie(`vgfs_accessed`) === "true") this.asWelcomeScreen = false;
    if (!this.asWelcomeScreen) AnalyticsManager.sendPanorama(AppManager.viewer.panorama.userData.controller.config.analytics);

    // リロード回帰後のイントロは再生しない
    this.suppressIntroByReload = reloadedForCounseling;
    AppManager.effectBlur = false;
  }


  start() {
    this.viewer.clearAllCache();
    this.viewer.render();

    if (appConfig.debug.visiblePanoramaId) AppManager.showInTextWidget(this.panoramaId);
    AppManager.setCurrentFloor(this.config.floor);
    if (appConfig.autoRotateEnabled) {
      setTimeout(() => {
        this.viewer.enableAutoRate();
      }, 3000);
    }
    ViewHistoryManager.savePanorama(this.panoramaId);
  }


  onProgress(event) {
    const p = Math.floor(event.progress.loaded / event.progress.total * 100);
    logger.info(`${this.panoramaId} | onProgress`, `${p}%`, event);
  }


  onLoad(event) {
    logger.info(`${this.panoramaId} | onLoad`, event);
  }


  onEnter(event) {
    AppManager.effectBlur = true;
    if (AppManager.effectHBlurOn) AppManager.effectHBlurOn.start();
    if (AppManager.effectTBlurOn) AppManager.effectTBlurOn.start();
    logger.info(`${this.panoramaId} | onEnter`, event);
    
    // 初期視点位置
    if (!appConfig.debug.measureMode) {
        this.setupMoreView();
        this.setupHeaderNavi();
        this.setupLinking();
        // this.setupAnimation();
    }
  }


  onEnterFadeStart(event) {

    logger.info(`${this.panoramaId} | onEnterFadeStart`, event);

    this.setupWallScrene();
    if (this.viewer.skipLoading) {
      this.viewer.skipLoading = false;
    } else {
      AppManager.setLoading(true);
    }

    this.start();

    const fov = this.config.fov === undefined || this.config.spFov === undefined ? undefined : parseInt(AppManager.isSmartPhone() ? (this.config.spFov !== undefined ? this.config.spFov : this.config.fov) : this.config.fov);
    const initialLookAt = AppManager.isSmartPhone() && this.config.initialLookAtSP !== undefined ? this.config.initialLookAtSP : this.config.initialLookAt;
    const initPos = new THREE.Vector3().fromArray(initialLookAt);
    this.viewer.tweenControlLeftOnly(initPos, fov, 5, AppManager.effectDirection, 2000, TWEEN.Easing.Quartic.Out);

    CacheManager.check(this.panoramaId, 'onEnter');
  }


  onLeaveComplete(event) {
    logger.info(`${this.panoramaId} | onLeaveComplete`, event);
    logger.info("disposed geometory in Before onLeaveComplete : ", AppManager.viewer.getRenderer().info.memory);
    
    this.hideWallScrene();
    
    this.panorama.material.map.dispose();
    this.panorama.geometry.dispose();
    this.panorama.material.dispose();
    this.viewer.remove(this.panorama);
    this.viewer.render();
    THREE.Cache.remove(this.panorama.src);
    this.viewer.clearAllCache();
    this.panorama = null;
    logger.info("disposed geometory in After onLeaveComplete : ", AppManager.viewer.getRenderer().info.memory);

    logger.debug("## current panorama in scene", this.viewer.getScene().children, this.viewer.getScene().children.map(p => p.src));
    AppManager.effectBlur = false;
  }



  onEnterFadeComplete(event) {
    logger.info(`${this.panoramaId} | onEnterFadeComplete`, event);
    AppManager.toggleChangeVRNav(this.config.navi !== undefined, this.config.navi);
    AppManager.toggleCurrentVR(this.config.linkLabel !== undefined, this.linkLabel);
    AppManager.toggleAd(this.config.ad !== undefined, this.config.ad);
    AppManager.toggleControls(true, true);
    AppManager.toggleLinkMenu(true);
    AppManager.setLoading(false);
    if (AppManager.getScreenType() !== 'mb') new LinksMenu(AppManager.panoramaConfig);
  }


  onLeave(event) {
    logger.info(`${this.panoramaId} | onLeave`, event);
    AppManager.setBeforeFloor(this.panorama.userData.controller.config.floor);

    if (this.moreViewSpotHolder) {
      this.moreViewSpotHolder.forEach(spot => {
        if (spot.buttonMesh.hide) spot.buttonMesh.dispose();
      });
      this.moreViewSpotHolder = undefined;
    }
    if (this.linkHolder) {
      this.linkHolder.forEach(link => {
        if (link.buttonMesh.material.map.dispose) link.buttonMesh.material.map.dispose();
        if (link.buttonMesh.geometry.dispose) link.buttonMesh.geometry.dispose();
        if (link.buttonMesh.material.dispose) link.buttonMesh.material.dispose();
      });
      this.linkHolder = undefined;
    }
    if (AppManager.effectHBlurOff) AppManager.effectHBlurOff.start();
    if (AppManager.effectTBlurOff) AppManager.effectTBlurOff.start();
    this.panorama.reset();
  }


  hideObject() {
    logger.info(`${this.panoramaId} | hideObject`);
    this.hideMoreView();
    //this.hideWallScrene();
    this.hideLinking();
    
    AppManager.toggleCurrentVR(false);
    AppManager.toggleAd(false);
    $('#changeVR-nav div.prev, #changeVR-nav div.next').fadeOut();
    if ($('#slideMenu .container.hide').length > 0) {
        AppManager.toggleLinkMenu(false);
    }
    AppManager.toggleControls(false);
  }


  setupLinking() {
    if (AppManager.getScreenType() === 'mb') { return; }

    if (this.linkHolder === undefined) {
      this.linkHolder = [];
      this.config.links.forEach(linkConf => {
        const panoCtl = this.panorama.userData.controller;
        const panoCtlTo = AppManager.getPanoramaConfig(linkConf.id);
        if (panoCtlTo) {
          this.linkHolder.push(new InfoLinkSpot(panoCtl, panoCtlTo, linkConf));
        }
      });
    } else {
      this.linkHolder.forEach(link => {
        link.show();
      });
    }
  }

  setupMoreView() {
    if (!this.hasMoreView) return;
    if (this.moreViewSpotHolder === undefined) {
      this.moreViewSpotHolder = [];
      this.config.moreView.forEach(moreViewConf => {
        this.moreViewSpotHolder.push(new InfoMoreViewSpot(this.panorama, moreViewConf, this.viewer));
      });
    } else {
      this.moreViewSpotHolder.forEach(moreViewSpot => {
        if (moreViewSpot.buttonMesh.show) moreViewSpot.buttonMesh.show(500);
      });
    }
  }


  hideMoreView() {
    if (!this.hasMoreView) return;
    if (this.moreViewSpotHolder === undefined) return;
    this.moreViewSpotHolder.forEach(spot => { if (spot.buttonMesh.hide) {
        spot.buttonMesh.hide();
        spot.buttonMesh.dispose();
     }});
  }

  hideLinking() {
    if (!this.hasLink) return;
    if (this.linkHolder === undefined) return;
    this.linkHolder.forEach(link => { link.buttonMesh.hide(); });
  }


  setupFov() {
    if (this.config.fov === undefined) return;
    const fov = this.config.spFov !== undefined ? this.config.spFov : this.config.fov;
    this.viewer.setCameraFov(parseInt(AppManager.isSmartPhone() ? fov : this.config.fov));
  }

  setupHeaderNavi() {
    if (this.config.showHeaderNavi === undefined || this.config.showHeaderNavi === false) {
      HeaderNavi.hide();
    } else {
      HeaderNavi.show(this.config.floor);
    }
  }

  setupWallScrene() {
    if (!this.hasWallVideo) return;
    if (this.videoWallHolder === undefined) {
      this.videoWallHolder = [];
      this.config.wallScene.forEach(videoConf => {
        this.videoWallHolder.push(new InfoWallScene(this.panorama, videoConf));
      });
    } else {
      this.videoWallHolder.forEach(videoWall => {
        videoWall.video.play();
      });
    }
  }

  hideWallScrene() {
    if (this.videoWallHolder === undefined) return;
    this.videoWallHolder.forEach(videoWall => {
      videoWall.video.pause();
      videoWall.video = undefined;
      videoWall.material.map.dispose();
      videoWall.geometry.dispose();
      videoWall.material.dispose();
    });
    this.videoWallHolder = undefined;
  }


  onHover(event) {
    if (this.interactiveHolder === undefined) return;

    const intersectHit = event.intersects.find(ist => {
      return this.interactiveHolder.findIndex(element =>
        element.hitArea && ist.object === element.hitArea) >= 0;
    });

    if ((this.selectedInteractive && event.intersects.length > 0 && intersectHit && this.selectedInteractive !== intersectHit.object)
      || (this.selectedInteractive && event.intersects.length == 0)) {
      AppManager.viewer.getContainer().style.cursor = 'default';
      this.selectedInteractive.userData.owner.onHoverEnd(event);
      this.selectedInteractive = undefined;
    }

    if (intersectHit) {
      this.selectedInteractive = intersectHit.object;
      AppManager.viewer.getContainer().style.cursor = 'pointer';
      this.selectedInteractive.userData.owner.onHoverStart(event);
    }
  }


  debugLog() {
    logger.info(`Spot | ${this.panoramaId} | ${event.type} |`, event);
  }

  get panoramaId() {
    return this.config.id;
  }

  get panoramaImage() {
    if (AppManager.getPanoramaType() === '6k') {
      return this.config.image6k;
    } else if (AppManager.getPanoramaType() === '4k') {
      return this.config.image4k;
    } else {
      return this.config.image;
    }
  }

  get linkLabel() {
    if (this.config['linkLabel_' + AppManager.currentLanguage]) {
      return this.config['linkLabel_' + AppManager.currentLanguage];
    }
    return this.config.linkLabel;
  }


  get hasMenu() {
    return this.config.hasOwnProperty('menu') && this.config['menu'].length > 0;
  }


  get hasCaptions() {
    return this.config.hasOwnProperty('captions') && this.config['captions'].length > 0;
  }

  get hasMoreView() {
    return this.config.hasOwnProperty('moreView') && this.config['moreView'].length > 0;
  }

  get hasLink() {
    return this.config.hasOwnProperty('links') && this.config['links'].length > 0;
  }

  get hasProductView() {
    return this.config.hasOwnProperty('productView') && this.config['productView'].length > 0;
  }


  get hasVideo() {
    return this.config.hasOwnProperty('video') && this.config['video'].length > 0;
  }

  get hasWallVideo() {
    return this.config.hasOwnProperty('wallScene') && this.config['wallScene'].length > 0;
  }

}
