import querystring from "querystring";
import Cookies from 'js-cookie';
import logger from "../utils/logger";
import { appConfig } from "@common";

const logTag = "[ViewHistoryManager]";
const maxHistory = 30;

class ViewHistoryManager {
  constructor() {
    this.lastPanorama = null;
    this.lastVideo = null;
    this.visitedPanoramas = [];
  }

  saveVideo(videoId) {
    logger.info(`${logTag} saveVideo`, videoId);
    this.lastVideo = videoId;
  }

  savePanorama(panoramaId) {
    const param = "p" + panoramaId;
    this.lastPanorama = param;
    if (this.visitedPanoramas.length < maxHistory) {
      this.visitedPanoramas.push(param);
    }
    logger.info(
      `${logTag} savePanorama`,
      panoramaId,
      this.visitedPanoramas.join("_")
    );
  }

  // 【パラメータ例】
  // https://brand.shiseido.co.jp/ultimune-defense-refresh-mist-4514254981344.html
  // ?utm_campaign=VGFS&utm_medium=referral&utm_source=VP20&utm_content=[動画ファイル名]&utm_term=p01_p06_p01_p16_p14_p17_p20
  buildParams() {
    const params = {
      utm_campaign: appConfig.utm_campaign,
      utm_medium: "referral",
      utm_source: this.lastPanorama,
      utm_content: this.lastVideo,
      utm_term: this.visitedPanoramas.join("_"),
    };
    const qs = querystring.stringify(params);
    logger.info(`${logTag} buildParams`, qs);
    return "?" + qs;
  }

  saveInStorage() {
    logger.info(
      `${logTag} saveInStorage`,
      this.visitedPanoramas.join("_")
    );
    Cookies.set('vgfs_lastpanorama', this.visitedPanoramas[this.visitedPanoramas.length - 1]);
    Cookies.set('vgfs_history', this.visitedPanoramas.join("_"));
  }

  getLastPanorama() {
    const rawData = Cookies.get('vgfs_lastpanorama');
    if (rawData && rawData.length > 0) return Cookies.get('vgfs_lastpanorama').slice(1);
    return null;
  }

  parseFromStorage() {
    const rawData = Cookies.get('vgfs_history');
    if (!rawData) return;
    this.visitedPanoramas = rawData.split("_");
    this.clearStorage();
  }

  clearStorage() {
    Cookies.remove('vgfs_lastpanorama');
    Cookies.remove('vgfs_history');
  }
}

export default new ViewHistoryManager();
